import React, {useEffect, useState} from 'react';
import './OtherProfile.css';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import { MobileView, BrowserView } from 'react-device-detect';

import SalesTable from './userTables/userSales/Table';
import RatingsTable from './userTables/userRatings/Table';
import UserInfo from './UserInfo';
import CHCheckbox from '../../components/CHComponents/CHCheckbox';
import CHSelect from '../../components/CHComponents/CHSelect';

const OtherProfile = props => {
	const intl = useIntl();
	const { user } = props;
	const [selectedTable, setSelectedTable] = useState('sales');
	const [table, setTable] = useState(<SalesTable isOwnProfile={false} userId={user.id}/>);


	const [value, setValue] = useState({id: 'sales', label:intl.formatMessage({id: 'general.sales', defaultMessage: 'Sales'}), value: 'sales'});
	const selectOptions = [
		{id: 'sales', label:intl.formatMessage({id: 'general.sales', defaultMessage: 'Sales'}), value: 'sales'},
		{id: 'ratings', label:intl.formatMessage({id: 'general.ratingsComments', defaultMessage: 'Ratings/Comments'}), value: 'ratings'}
	];
	const setSelectedOption = (option)=> {
	setValue(option)
	setSelectedTable(option.value)
	}


	/* ---- RENDER ---- */

	const renderTable = () => {
		if(selectedTable === 'sales') {
			setTable(<SalesTable isOwnProfile={false} userId={user.id}/>);
		}else if(selectedTable === 'ratings') {
			setTable(<RatingsTable/>);
		}
	};

	useEffect(renderTable, [selectedTable, user]);


	/* ---- DOM ---- */

	return (
		<div>
			<UserInfo user={user} />
			<div className="otherProfile-tableButtons-div">
				<BrowserView>
					<CHCheckbox
						className="otherProfile-tableCheckbox"
						boxClassName="otherProfile-tableCheckbox-box"
						CHStyle="box"
						id="salesCheckbox"
						value="salesCheckbox"
						label={intl.formatMessage({id: 'general.sales', defaultMessage: 'Sales'})}
						checked={selectedTable === 'sales'}
						onChange={() => setSelectedTable('sales')}
					/>
					<CHCheckbox
						className="otherProfile-tableCheckbox"
						boxClassName="otherProfile-tableCheckbox-box"
						CHStyle="box"
						id="ratingsCheckbox"
						value="ratingsCheckbox"
						label={intl.formatMessage({id: 'general.ratingsComments', defaultMessage: 'Ratings/Comments'})}
						checked={selectedTable === 'ratings'}
						onChange={() => setSelectedTable('ratings')}
					/>
				</BrowserView>
				<MobileView>
					<CHSelect
						className="otherProfile-CHSelect"
						id="otherProfile-CHSelect"
						value={value} 
						options={selectOptions}
						onChange={option => setSelectedOption(option) } 
					/>
				</MobileView>
			</div>
			<div className="otherProfile-tableArea-div">
				{table}
			</div>
		</div>
	);
};

OtherProfile.propTypes = {
	user: PropTypes.shape({
		id: PropTypes.number.isRequired,
		username: PropTypes.string,
		password: PropTypes.string,
		name: PropTypes.string,
		surname: PropTypes.string,
		email: PropTypes.string,
		country: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		state: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		city: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		userStatus: PropTypes.string,
		userShipping: PropTypes.string,
		userDelivery: PropTypes.string,
		reputation: PropTypes.number,
		avatarId: PropTypes.string,
		avatarType: PropTypes.string,
		observation: PropTypes.string
	}).isRequired
};

export default OtherProfile;
