import React, {useState, useEffect} from 'react';
import './SaleEditionModal.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import CHModal from '../../../../components/CHComponents/CHModal';
import CHModalHeader from '../../../../components/CHComponents/CHModalHeader';
import CHModalBody from '../../../../components/CHComponents/CHModalBody';
import CHModalFooter from '../../../../components/CHComponents/CHModalFooter';
import CHInput from '../../../../components/CHComponents/CHInput';
import CHError from '../../../../components/CHComponents/CHError';
import CHCheckbox from '../../../../components/CHComponents/CHCheckbox';
import CHTextarea from '../../../../components/CHComponents/CHTextarea';
import {getCardTypeById, getCardConditionById} from '../../../../utils/enumsService';
import { updateCardSale } from '../../../../api/mtgSalesServiceApi';
import { CARD_TRADEABLE, CARD_NONTRADEABLE } from '../../../../utils/constants';

const defaultMsg = {
	invalidPrice: 'Greater than 0.1',
	invalidQuantity: 'Greater than 0',
	invalidObservation: 'Max of 150 characters'
};

const SaleEditionModal = props => {
	const intl = useIntl();
	const {isOpen, onClose, onAccept, cardSale} = props;
	const [showActionError, setShowActionError] = useState(false);
	const [actionError, setActionError] = useState(null);
	const [editedCardSale, setEditedCardSale] = useState({
		cardName: '',
		cardSet: '',
		cardType: 0,
		cardLanguage: 0,
		cardCondition: 0,
		quantity: 1,
		price: 0,
		observation: '',
		cardSaleStatus: 0
	});
	const [errors, setErrors] = useState({
		quantity: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidQuantity', defaultMessage: defaultMsg.invalidQuantity})},
		price: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidPrice', defaultMessage: defaultMsg.invalidPrice})},
		observation: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidObservation', defaultMessage: defaultMsg.invalidObservation})}
	});

	useEffect(() => {
		if(cardSale) {
			setEditedCardSale({...cardSale});
		}
	}, [cardSale]);

	const changeObservation = (value) => {
		setEditedCardSale({...editedCardSale, observation: value});
		setErrors({
			...errors,
			observation: {...errors.observation, isInvalid: value.length > 150}
		});
	};

	const onSave = () => {
		setShowActionError(false);
		updateCardSale(editedCardSale)
			.then(savedSale => onAccept())
			.catch((error) => {
				setActionError(error);
				setShowActionError(true);
			});
	};

	const closeModal = () => {
		setEditedCardSale({...cardSale});
		setShowActionError(false);
		onClose();
	};

	return (
		<CHModal isOpen={isOpen} toggle={closeModal}>
			<CHModalHeader
				tittle={intl.formatMessage({id: 'tittle.editSale', defaultMessage: 'Edit sale'})}
			/>
			<CHModalBody className="UserSalesTEditionModal-body">
				<div className="UserSalesTEditionModal-info-div">
					<label className="UserSalesTEditionModal-info-label">
						{intl.formatMessage({id: 'game.card', defaultMessage: 'Card'})}:
					</label>{editedCardSale.cardName}<br/>
					<label className="UserSalesTEditionModal-info-label">
						{intl.formatMessage({id: 'game.set', defaultMessage: 'Set'})}:
					</label>{editedCardSale.cardSet}<br/>
					<label className="UserSalesTEditionModal-info-label">
						{intl.formatMessage({id: 'game.type', defaultMessage: 'Type'})}:
					</label>{getCardTypeById(editedCardSale.cardType).label}<br/>
					<label className="UserSalesTEditionModal-info-label">
						{intl.formatMessage({id: 'game.condition', defaultMessage: 'Condition'})}:
					</label>{getCardConditionById(editedCardSale.cardCondition).label}<br/>
				</div>
				<div className="UserSalesTEditionModal-inputs-div">
					<div className="UserSalesTEditionModal-quantity-div">
						<label className="UserSalesTEditionModal-editField-label">
							{intl.formatMessage({id: 'general.quantity', defaultMessage: 'Quantity'})}
						</label>
						<CHInput
							id="saleEditionModal-input-quantity"
							className="UserSalesTEditionModal-editField-input"
							type="int"
							value={editedCardSale.quantity}
							onChange={value => setEditedCardSale({...editedCardSale, quantity: value})}
							min={1}
							error={errors.quantity}
						/>
					</div>
					<div className="UserSalesTEditionModal-price-div">
						<label className="UserSalesTEditionModal-editField-label">
							{intl.formatMessage({id: 'general.price', defaultMessage: 'Price'}) + ' (U$S)' } 
						</label>
						<CHInput
							id="saleEditionModal-input-price"
							className="UserSalesTEditionModal-editField-input"
							type="float"
							value={editedCardSale.price}
							onChange={value => setEditedCardSale({...editedCardSale, price: value})}
							min={0.1}
							error={errors.price}
						/>
					</div>
				</div>
				<CHCheckbox
					className="UserSalesTEditionModal-editField-checkbox"
					id="checkboxIsTradeable"
					checked={editedCardSale.cardTradeable === CARD_TRADEABLE}
					onChange={event => setEditedCardSale({
						...editedCardSale,
						cardTradeable: event.target.checked ? CARD_TRADEABLE : CARD_NONTRADEABLE}
					)}
					label={intl.formatMessage({id: 'general.acceptsTrade', defaultMessage: 'Accepts trade'})}
				/>
				<CHTextarea
					id="saleEditionModal-textArea-observation"
					className="UserSalesTEditionModal-editField-textarea"
					placeholder={intl.formatMessage({id: 'input.observation', defaultMessage: 'Observation...'})}
					value={editedCardSale.observation}
					onChange={value => changeObservation(value)}
					error={errors.observation}
				/>
				<CHError
					id="saleEditionModal-error"
					isShown={showActionError}
					error={actionError}
				/>
			</CHModalBody>
			<CHModalFooter
				type="save"
				acceptLabel={intl.formatMessage({id: 'general.save', defaultMessage: 'Save'})}
				onAccept={onSave}
				acceptDisabled={
					editedCardSale.quantity <= 0 ||
					editedCardSale.price <= 0 ||
					editedCardSale.observation.length > 150
				}
			/>
		</CHModal>
	);
};

SaleEditionModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onAccept: PropTypes.func.isRequired,
	cardSale: PropTypes.shape({
		id: PropTypes.number,
		userId: PropTypes.number,
		cardId: PropTypes.string,
		cardName: PropTypes.string,
		cardSet: PropTypes.string,
		cardType: PropTypes.number,
		cardLanguage: PropTypes.number,
		cardCondition: PropTypes.number,
		quantity: PropTypes.number,
		price: PropTypes.number,
		cardTradeable: PropTypes.number,
		observation: PropTypes.string,
		cardSaleStatus: PropTypes.number
	})
};

SaleEditionModal.defaultProps = {
	cardSale: null
};

export default SaleEditionModal;
