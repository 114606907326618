import React, { useState } from 'react';
import './ChangePasswordModal.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import CHModal from '../../components/CHComponents/CHModal';
import CHModalHeader from '../../components/CHComponents/CHModalHeader';
import CHModalBody from '../../components/CHComponents/CHModalBody';
import CHModalFooter from '../../components/CHComponents/CHModalFooter';
import CHInput from '../../components/CHComponents/CHInput';
import CHError from '../../components/CHComponents/CHError';
import { editUserPassword } from '../../api/identityServiceApi';

const ChangePasswordModal = props => {
	const intl = useIntl();
	const { isOpen, userId, onAccept, onClose } = props;
	const [password, setPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmationNewPassword, setConfirmationNewPassword] = useState('');
	const [showActionError, setShowActionError] = useState(false);
	const [actionError, setActionError] = useState(null);

	const resetValues = () => {
		setPassword('');
		setNewPassword('');
		setConfirmationNewPassword('');
		setShowActionError(false);
	};

	const closeModal = () => {
		resetValues();
		onClose();
	};

	const onSave = () => {
		setShowActionError(false);
		editUserPassword(userId, password, newPassword)
			.then(() => {
				resetValues();
				onAccept();
			})
			.catch((error) => {
				setActionError(error);
				setShowActionError(true);
			});
	};

	return (
		<CHModal isOpen={isOpen} toggle={closeModal}>
			<CHModalHeader tittle={intl.formatMessage({id: 'general.editPassword', defaultMessage: 'Edit Password'})}/>
			<CHModalBody>
				<CHInput
					className="changePasswordModal-password"
					id="password"
					type="password"
					placeholder={intl.formatMessage({id: 'general.password', defaultMessage: 'Password'})}
					value={password}
					onChange={value => setPassword(value)}
				/>
				<CHInput
					className="changePasswordModal-newPassword"
					id="new-password"
					type="password"
					placeholder={intl.formatMessage({id: 'general.newPassword', defaultMessage: 'New password'})}
					value={newPassword}
					onChange={value => setNewPassword(value)}
				/>
				<CHInput
					className="changePasswordModal-confirmationPassword"
					id="confirmation-new-password"
					type="password"
					placeholder={intl.formatMessage({id: 'general.confirmNewPassword', defaultMessage: 'Confirm new password'})}
					value={confirmationNewPassword}
					onChange={value => setConfirmationNewPassword(value)}
				/>

				<CHError
					id="changePasswordModal-error"
					isShown={showActionError}
					error={actionError}
				/>
			</CHModalBody>
			<CHModalFooter
				type="save"
				acceptLabel={intl.formatMessage({id: 'general.save', defaultMessage: 'Save'})}
				onAccept={onSave}
			/>
		</CHModal>
	);
};


ChangePasswordModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	userId: PropTypes.number.isRequired,
	onAccept: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

export default ChangePasswordModal;
