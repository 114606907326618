import { getCardById } from '../../api/scryfallApi';
import { getSaleHistoriesUser } from '../../api/mtgSalesServiceApi';
import { getUserId } from '../../utils/localStorageManager';
import { MTG_CODE, SALEHISTORY_IN_CART } from '../../utils/constants';

const LOGIN_USER = 'LOGIN_USER';
const LOGOUT_USER = 'LOGOUT_USER';
const SET_SESSION_THEME = 'SET_SESSION_THEME';
const FETCH_AVATAR = 'FETCH_AVATAR';
const ADD_AVATAR_URL = 'ADD_AVATAR_URL';
const FETCH_CART = 'FETCH_CART';
const ADD_CART = 'ADD_CART';
const CLEAR_CART = 'CLEAR_CART';

const loginUser = () => ({
	type: LOGIN_USER
});

const logoutUser = () => ({
	type: LOGOUT_USER
});

// theme: light | dark
const setSessionTheme = (theme) => ({
	type: SET_SESSION_THEME,
	payload: theme
});

const addAvatarUrl = url => ({
	type: ADD_AVATAR_URL,
	payload: url
});

const fetchAvatar = (avatarId, avatarType) => {
	return dipatch => {
		if(avatarType === MTG_CODE) {
			return getCardById(avatarId)
				.then(print => dipatch(addAvatarUrl(print.image_uris ? print.image_uris.art_crop : print.card_faces[0].image_uris.art_crop)));
		}
	};
};

const addCart = (saleHistories) => ({
	type: ADD_CART,
	payload: saleHistories
});

const fetchCart = () => {
	return dispatch => {
		return getSaleHistoriesUser(getUserId(), [SALEHISTORY_IN_CART], false)
			.then(saleHistories => dispatch(addCart(saleHistories)));
	};
};

const clearCart = () => ({
	type: CLEAR_CART
});

export {
	LOGIN_USER, loginUser,
	LOGOUT_USER, logoutUser,
	SET_SESSION_THEME, setSessionTheme,
	FETCH_AVATAR, fetchAvatar,
	ADD_AVATAR_URL, addAvatarUrl,
	ADD_CART, addCart,
	FETCH_CART, fetchCart,
	CLEAR_CART, clearCart
};
