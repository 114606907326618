import React, {useState} from 'react';
import '../../assets/styles/ThemeVariables.css';
import './chTest.css';
import {useIntl} from 'react-intl';
import {MobileView, BrowserView} from 'react-device-detect';

import Icon from '../../components/Icon';
import CHButton from '../../components/CHComponents/CHButton';
import CHButtonDropdown from '../../components/CHComponents/CHButtonDropdown';
import CHSelect from '../../components/CHComponents/CHSelect';
import CHCheckbox from '../../components/CHComponents/CHCheckbox';
import CHInput from '../../components/CHComponents/CHInput';


const Home = props => {
	const intl = useIntl();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [isDropdownCheckboxOpen, setIsDropdownCheckboxOpen] = useState(false);
	const [isDropdownSelectOpen, setIsDropdownSelectOpen] = useState(false);
	const [isCHCheckboxChecked, setIsCHCheckboxChecked] = useState(false);
	const [selectValue, setSelectValue] = useState(null);
	const dropdownMenuContent = [
		{id: 1, label: 'Item 1', onClick: () => console.log('item 1')},
		{id: 2, label: 'Item 2', onClick: () => console.log('item 2')},
		{id: 3, label: 'Item 3', onClick: () => console.log('item 3')}
	];
	const dropdownMenuCheckboxContent = [
		{id: 1, label: 'Item 1', value: 1, checked: true},
		{id: 2, label: 'Item 2', value: 2, checked: false},
		{id: 3, label: 'Item 3', value: 3, checked: true}
	];
	const selectOptions = [
		{id: 1, label:'CHSelect', value: 1},
		{id: 2, label:'Option 2', value: 2},
		{id: 3, label:'Option 3', value: 3}
	];

	return (
		<div className="chTest-div">
			<h1>CardHub</h1>
			<label>
				{intl.formatMessage({
					id: 'home.example',
					defaultMessage: 'Mensaje por defecto'
				})}
			</label>
			<ul>
				<li>Fruta</li>
				<li>Verdura</li>
				<li>Aceituna</li>
			</ul>
			<ul>
				<li><strong>Ejemplo de negrita</strong></li>
				<li>Ejemplo de icono: </li>
				<Icon name="B" type="manaSymbol" width="20" height="20"/>
				<Icon name="G" type="manaSymbol" width="30" height="30"/>
				<Icon name="R" type="manaSymbol" width="30" height="30"/>
				<Icon name="U" type="manaSymbol" width="30" height="30"/>
				<Icon name="W" type="manaSymbol" width="30" height="30"/>
				<Icon name="C" type="manaSymbol" width="30" height="30"/>
				<br/>
				<Icon name="0" type="manaSymbol" width="30" height="30"/>
				<Icon name="1" type="manaSymbol" width="30" height="30"/>
				<Icon name="2" type="manaSymbol" width="30" height="30"/>
				<Icon name="3" type="manaSymbol" width="30" height="30"/>
				<Icon name="4" type="manaSymbol" width="30" height="30"/>
				<Icon name="5" type="manaSymbol" width="30" height="30"/>
				<Icon name="6" type="manaSymbol" width="30" height="30"/>
				<Icon name="7" type="manaSymbol" width="30" height="30"/>
				<Icon name="8" type="manaSymbol" width="30" height="30"/>
				<Icon name="9" type="manaSymbol" width="30" height="30"/>
				<Icon name="10" type="manaSymbol" width="30" height="30"/>
			</ul>
			<CHButton
				className="chTest-CHButton"
				label="CHButton"
				onClick={() => console.log('CHButton click')}
				disabled
			/>
			<CHButtonDropdown
				buttonClassName="chTest-CHButtonDropdown"
				isOpen={isDropdownOpen}
				toggle={() => setIsDropdownOpen(!isDropdownOpen)}
				label="CHButtonDropdown"
				type="menu"
				content={dropdownMenuContent}
				disabled
			/>
			<CHButtonDropdown
				buttonClassName="chTest-CHButtonDropdown"
				isOpen={isDropdownCheckboxOpen}
				toggle={() => setIsDropdownCheckboxOpen(!isDropdownCheckboxOpen)}
				label="CHButtonDropdown"
				type="checkbox"
				content={dropdownMenuCheckboxContent}
				onChange={event => console.log(event.target.value)}
				disabled
			/>
			<CHButtonDropdown
				buttonClassName="chTest-CHButtonDropdown"
				isOpen={isDropdownSelectOpen}
				toggle={() => setIsDropdownSelectOpen(!isDropdownSelectOpen)}
				label="CHButtonDropdown"
				type="select"
				content={selectOptions}
				onChange={event => console.log(event.target.value)}
			/>
			<CHSelect
				className="chTest-CHSelect"
				options={selectOptions}
				value={selectValue}
				onChange={option => setSelectValue(option)}
			/>
			<CHInput
				className="chTest-CHInput"
				type="text"
				value={'CHInput'}
				onChange={value => console.log(value)}
				disabled
			/>
			<CHCheckbox
				className="chTest-CHCheckbox"
				id={"TO1"}
				disabled
				CHStyle="box"
				label="Checkbox box"
				value={1}
				checked={isCHCheckboxChecked}
				onChange={event => {
					console.log(event.target.checked);
					setIsCHCheckboxChecked(!isCHCheckboxChecked);
				}}
			/>

			<MobileView>
				<h3>ESTAMOS EN CELULAR</h3>
			</MobileView>
			<BrowserView>
				<h3 className="prueba">ESTAMOS EN BROWSER</h3>
			</BrowserView>

		</div>
	);
};

export default Home;
