import React, { useState, useEffect } from 'react';
import './SaleHistoryStatusColumn.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { getNextSaleHistoryStatuses } from '../../utils/enumsService';
import CHSelect from '../CHComponents/CHSelect';

const SaleHistoryStatusCell = props => {
	const { tdClassName, row, args } = props;
	const intl = useIntl();
	const [options, setOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);

	const getOptions = () => {
		let mounted = true;
		const { actualStatus, isSeller, buyerRating, sellerRating } = row.customData;
		const didRate = isSeller ? buyerRating > 0 : sellerRating > 0;

		const newOptions = getNextSaleHistoryStatuses(actualStatus, isSeller, didRate).map(option => ({
			id: option,
			value: option,
			label: intl.formatMessage({id: option, defaultMessage: option})
		}));
		newOptions.unshift({
			id: actualStatus,
			value: actualStatus,
			label: intl.formatMessage({id: actualStatus, defaultMessage: actualStatus})
		});
		if(mounted) {
			setOptions(newOptions);
			setSelectedOption(newOptions[0]);
		}

		return () => {
			mounted = false;
		};
	};

	useEffect(getOptions, []);

	const onOptionSelected = (option) => {
		const {
			actualStatus, isSeller, buyerRating, sellerRating, username,
			buyerComment, sellerComment
		} = row.customData;
		setSelectedOption(option);
		args.onStatusChange({
			id: row.id,
			status: actualStatus,
			newStatus: option.value,
			rating: isSeller ? buyerRating : sellerRating,
			comment: isSeller ? buyerComment : sellerComment,
			username
		});
	};

	return (
		<td className={`saleHistoryStatusCell-td ${tdClassName ? tdClassName : ''}`} key={`${row.id}-saleHistoryStatus`}>
			<CHSelect
				className={`saleHistoryStatusCell-select`}
				id={`${row.id}-saleHistoryStatus-select`}
				options={options}
				value={selectedOption}
				onChange={onOptionSelected}
				disabled={options.length === 1}
				menuFixed
			/>
		</td>
	);
};

SaleHistoryStatusCell.propTypes = {
	tdClassName: PropTypes.string.isRequired,
	/* rows.customData:
	*   isSeller: boolean
	*   actualStatus: string (SaleHistoryStatus)
	*   buyerRating: number
	*   sellerRating: number
	*   buyerComment: string
	*   sellerComment: string
	*   username: string
	*/
	row: PropTypes.object.isRequired,
	args: PropTypes.shape({
		/* recieves object:
		* id: number
		* status: string
		* newStatus: string
		* rating: number
		* username: string
		*/
		onStatusChange: PropTypes.func.isRequired
	}).isRequired
};

export {
	SaleHistoryStatusCell
};