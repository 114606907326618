/*
* IdentityService API.
*/

import { getData, postData, putData } from './apiGatewayService';


/* ---- USER ---- */

/* returns:
	"id": number,
	"username": string,
	"city":{
		"id": number,
		"name": string
	},
	"state":{
		"id": number,
		"name": string
	},
	"country":{
		"id": number,
		"name": string
	},
	"userStatus": string,
	"userShipping": string,
	"userDelivery": string,
	"reputation": number,
	"avatarId": string,
	"avatarType": string,
	"observation": string
*/
const getUserByUsername = async (username, cancelToken) => {
	return getData('identity/users', {username}, cancelToken);
};

/* returns:
	"id": number,
	"username": string,
	"password": null,
	"name": string,
	"surname": string,
	"email": string,
	"city":{
		"id": number,
		"name": string
	},
	"state":{
		"id": number,
		"name": string
	},
	"country":{
		"id": number,
		"name": string
	},
	"userStatus": string,
	"userShipping": string,
	"userDelivery": string,
	"reputation": number,
	"avatarId": string,
	"avatarType": string,
	"observation": string
*/
const getFullUserByUsername = async (username, cancelToken) => {
	return getData(`identity/users/${username}/full`, null, cancelToken);
};

/* recieves user:
    "username": string,
    "password": string,
    "name": string,
    "surname": string,
    "email": string,
    "cityid": number,
	"stateid": number,
    "countryid": number,
    "userDelivery": string,
    "UserShipping": string,
	"avatarId": string,
	"avatarType": string,
	"observation": string
*/
const createUser = async (user) => {
	return postData('identity/users', null, user);
};

/* recieves:
	"id": number,
	"username": string,
	"password": null,
	"name": string,
	"surname": string,
	"email": string,
	"city":{
		"id": number,
		"name": string
	},
	"state":{
		"id": number,
		"name": string
	},
	"country":{
		"id": number,
		"name": string
	},
	"userStatus": string,
	"userShipping": string,
	"userDelivery": string,
	"reputation": number,
	"avatarId": string,
	"avatarType": string,
	"observation": string
*/
const editUser = async (user) => {
	const editedUser = {
		id: user.id,
		name: user.name,
		surname: user.surname,
		cityid: user.city.id,
		stateid: user.state.id,
		countryid: user.country.id,
		userShipping: user.userShipping,
		userDelivery: user.userDelivery,
		userStatus: user.userStatus,
		avatarId: user.avatarId,
		avatarType: user.avatarType,
		observation: user.observation
	};
	return putData('identity/users', null, editedUser);
};

/* recieves:
	userId: number
	password: string
	newPassword: string
*/
const editUserPassword = async (userId, oldPassword, newPassword) => {
	return putData(`identity/users/${userId}/password`, null, {oldPassword, newPassword});
};

/* recieves:
	userId: number
	password: string
*/
const deleteUser = (userId, password) => {
	return putData(`identity/users/${userId}/delete`, null, {password});
};

/*
recieves:
	username: string
	password: string
	remember: boolean
returns userInfo:
	id: number
	username: string
	userRole: string
	token: string
	expirationDate: string
*/
const login = async (username, password, remember = false) => {
	return postData('identity/login', null, {username, password, remember});
};

export {
	getUserByUsername, getFullUserByUsername,
	createUser, editUser, editUserPassword, deleteUser,
	login
};
