import React, { useState,useEffect } from 'react';
import '../../assets/styles/ThemeVariables.css';
import './GamePage.css';
import {Link, useLocation, useHistory} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import CHTable from '../../components/CHComponents/CHTable';
import CHSelect from '../../components/CHComponents/CHSelect';
import cardhubLogo from '../../assets/icons/cardhub-logo.svg';
import Iframe from 'react-iframe';


import { updateUrlwHistory } from '../../utils/urlManager';

const calendar ="https://calendar.google.com/calendar/u/0/embed?wkst=1&bgcolor=%2378705d&ctz=America/Argentina/Salta&src=dGNnY2FyZGh1YkBnbWFpbC5jb20&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%2333B679&showNav=1&showTitle=0&showDate=1&showPrint=0&showCalendars=1&showTz=0"
const view = isMobile?'mobile':'browser';
const themeSelector = store => store.session.theme;

const GamePage = props => {
	const location = useLocation();
	const history = useHistory();
	const intl = useIntl();
	const theme = useSelector(themeSelector);
	const [printAdsRows, setPrintAdsRows] = useState([
		{
			id:"ads-1",
			link: `/donate`,
			cells:[
				{content:("Ahora CardHub tiene algo parecido a una homePage" )}
			]
		},
		{
			id:"ads-2",
			cells:[
				{content:("El idioma fiambres es furor en el mundo" )}
			]
		},
		{
			id:"ads-3",
			cells:[
				{content:("Confirmado, el azul es el mejor color de magic" )}
			]
		},
		{
			id:"ads-4",
			cells:[
				{content: (<img className="gamePage-tableicon" src={cardhubLogo} alt="Not found"/>)}
			]
		}
	]);

	const [cardSet, setCardSet] = useState(null);

	const adsColumns =  [
		 {id: "gamePage-ads-cell", label: intl.formatMessage({id: 'general.ads', defaultMessage: 'Advertisements'}) },
	 ];

	return (
		<div className={`${view}-gamePage-div`}>
			<div className={`${view}-gamePage-image-div`}>
				<img
					id="gamePage-img"
					alt=""
					className={`gamePage-Image`}
					src={ require('../../assets/images/mtgHome.jpg')}
				/>
			</div>
			<div className={`${view}-gamePage-menu`}>
					<Link to={'/mtg/sealedSearch'} className={`${view}-gamePage-link`}>
						{intl.formatMessage({id: 'game.searchSealed', defaultMessage: 'Sealed'})}
					</Link>
					<Link to={'/mtg/scrapSearch'} className={`${view}-gamePage-link`}>
						{intl.formatMessage({id: 'game.searchScrap', defaultMessage: 'Scrap'})}
					</Link>
					<Link to={'/mtg/accesorySearch'} className={`${view}-gamePage-link`}>
						{intl.formatMessage({id: 'general.accesories', defaultMessage: 'Accesories'})}
					</Link>
					<div className={`${view}-gamePage-setSelect-div`}>
						<CHSelect
							className={`${view}-gamePage-setSelect`}
							id="gamePage-setSelect"
							value={cardSet}
							options={[{
								id: "temporal",
								label: "temporal",
								value: "temporal"
							}]}
							placeholder={intl.formatMessage({id: 'select.setSearch', defaultMessage: 'Set...'})}
							onChange={option => setCardSet(option)}
							isClearable
						/>
					</div>
			</div>
			<div  className={`${view}-gamePage-ads`}>
				<CHTable
					CHStyle="products"
					columns={adsColumns}
					rows={printAdsRows}
					linkedRows={true}
				/>
			</div>
			<div  className={`${view}-gamePage-ads`}>
				<h2>{ intl.formatMessage({id: 'general.events', defaultMessage: 'Events'}) }</h2>
				<div className={`${view}-gamePage-events`}>
						<Iframe url={calendar}
							id="gamePage-calendarEvents"
							className={`${view}-gamePage-calendarEvents`}
							scrolling="no"
						/>					
				</div>
			</div>
		</div>
	);
};

export default GamePage;