import React, { useState, useEffect } from 'react';
import '../../assets/styles/ThemeVariables.css';
import './CardSearch.css';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { renderManaSymbols } from '../../utils/stringParser';
import { getCardTypeByCode, getLanguageByCode } from '../../utils/enumsService';
import { getUrlGame, getUrlParams } from '../../utils/urlManager';
import { fetchPrints } from '../../redux/actions/cardSearchActions';
import CHTable from '../../components/CHComponents/CHTable';
import CHSpinner from '../../components/CHComponents/CHSpinner';

const printsSelector = store => store.cardSearch.prints;
const isFetchingSelector = store => store.cardSearch.fetching;
const cardNameSelector = store => store.cardSearch.cardName;

const view = isMobile?'mobile':'browser';


const CardSearch = props => {
	const intl = useIntl();
	const location = useLocation();
	const dispatch = useDispatch();
	const isFetching = useSelector(isFetchingSelector);
	const cardName = useSelector(cardNameSelector);
	const prints = useSelector(printsSelector);
	const columns = isMobile
		? [
		   {id: 0, label: intl.formatMessage({id: 'game.card', defaultMessage: 'Card'})},
		   {id: 1, label: intl.formatMessage({id: 'general.information', defaultMessage: 'Information'})},
		   {id: 2, label: intl.formatMessage({id: 'general.priceAndStock', defaultMessage: 'Price and stock'})},
	   	]
   		: [
		   {id: 0, label: intl.formatMessage({id: 'game.card', defaultMessage: 'Card'})},
		   {id: 1, label: intl.formatMessage({id: 'general.information', defaultMessage: 'Information'})},
		   {id: 2, label: intl.formatMessage({id: 'general.description', defaultMessage: 'Description'})},
		   {id: 3, label: intl.formatMessage({id: 'general.stock', defaultMessage: 'Stock'})},
		   {id: 4, label: intl.formatMessage({id: 'general.price', defaultMessage: 'Price'})}
	   	];
	const [game, setGame] = useState('');
	const [printRows, setPrintRows] = useState([]);
	const [message, setMessage] = useState('');
	
	/* ---- LIFE CYCLE ---- */

	const updateGame = () => {
		const game = getUrlGame(location);
		setGame(game);
	};

	useEffect(updateGame, [location.pathname]);

	const updateCard = () => {
		const actualGame = game ? game : getUrlGame(location);
		const query = getUrlParams(location.search).q;
		if(!isFetching && (query !== cardName || prints.length === 0)) {
			dispatch(fetchPrints(actualGame, query, null, true));
		}
	};

	useEffect(updateCard, [location.search]);

	const buildRows = () => {
		if (prints.length === 0 && !isFetching) {
			setPrintRows([]);
			setMessage(intl.formatMessage({
				id: 'message.cardsNotFoundWithThatName',
				defaultMessage: 'Cards not found with that name'
			}));
		}else {
			let newPrintRows = prints.reduce((result, print) => {
				if(print.promo){
					addCardInfo(result, print, "promo");
					return result;
				}
				if(print.nonfoil){
					addCardInfo(result, print, "nonfoil");
				}
				if(print.foil){
					addCardInfo(result, print, "foil");
				}
				return result;
			}, []);
			setPrintRows(newPrintRows);
			setMessage('');
		}
	};

	useEffect(buildRows, [prints]);

	/* ---- TABLE BUILDER ---- */

	const addCardInfo = (array, card, type) => {
		const actualGame = game ? game : getUrlGame(location);
		array.push({
			id: card.id + type,
			link: `/${actualGame}/cardSales/${card.id}?type=${getCardTypeByCode(type).id}`,
			cells: card.card_faces ? getDoubleCardInfo(card, type) : getCardInfo(card, type)
		});
	};
	
	const getCardInfo = (card, type) => {
		return isMobile?
			[
				{content: (
					<img className="mobile-cardSearch-cardImage"
						src={card.image_uris.small}
						alt="Not found"
						onError={e => {e.target.onerror = null; e.target.src = require('../../assets/images/mtg-card-not-found.jpg')}}
					/>
				),
				className: 'mobile-cardSearch-cardImage-td'
			},
				{
					content:   cardMobileInfoFormatter(
						card.name,
						card.set_name,
						type,
						card.lang,
						card.id,
						card.rarity,
						card.mana_cost,
						card.type_line
					) ,
					className: 'cardSearch-td-text'
				},
				{content: ("min - max - "),
				className: 'cardSearch-td-text'}
			] :
			[
				{content: (
					<img
						src={card.image_uris.small}
						alt="Not found"
						onError={e => {e.target.onerror = null; e.target.src = require('../../assets/images/mtg-card-not-found.jpg')}}
					/>
				)},
				{
					content: cardInfoFormatter(
						card.name,
						card.set_name,
						type,
						card.lang
					),
					className: 'cardSearch-td-text'
				},
				{
					content: cardDescFormatter(
						card.id,
						card.rarity,
						card.mana_cost,
						card.type_line
					),
					className: 'cardSearch-td-text'
				},
				{content: "-"},
				{content: "min - max"}
			];
	};
	
	const getDoubleCardInfo = (card, type) => {
		const isSplit = card.image_uris;
		const typeLines = card.type_line.split(" // ");
		return isMobile? [
			{content: (
				<img
					src={isSplit ? card.image_uris.small : card.card_faces[0].image_uris.small}
					alt="Not found"
					onError={e => {e.target.onerror = null; e.target.src = require('../../assets/images/mtg-card-not-found.jpg')}}
				/>
			)},
			{
				content:   cardMobileInfoFormatter(
					card.name,
					card.set_name,
					type,
					card.lang,
					card.id,
					card.rarity,
					`${card.card_faces[0].mana_cost} // ${card.card_faces[1].mana_cost}`,
					`${typeLines[0]} //\n${typeLines[1]}`
				) ,
				className: 'cardSearch-td-text'
			},
			{content: ("min - max and - ")}
		] : [
			{content: (
				<img
					src={isSplit ? card.image_uris.small : card.card_faces[0].image_uris.small}
					alt="Not found"
					onError={e => {e.target.onerror = null; e.target.src = require('../../assets/images/mtg-card-not-found.jpg')}}
				/>
			)},
			{content: cardInfoFormatter(
				card.name,
				card.set_name,
				type,
				card.lang
			)},
			{content: cardDescFormatter(
				card.id,
				card.rarity,
				`${card.card_faces[0].mana_cost} // ${card.card_faces[1].mana_cost}`,
				`${typeLines[0]} //\n${typeLines[1]}`
			)},
			{content: "-"},
			{content: "min - max"}
		];
	};
	
	const cardInfoFormatter = (name, set, type, lang) => {
		const uniqueLang = lang !== "en";
		const languageLabel = getLanguageByCode(lang).id;
		return (
			<p className="cardSearch-cardInfo">
				<strong className="cardSearch-strong">
					{intl.formatMessage({id: 'general.name', defaultMessage: 'Name'})}:
				</strong>{name}<br/>
				<strong className="cardSearch-strong">
					{intl.formatMessage({id: 'game.set', defaultMessage: 'Set'})}:
				</strong>{set}<br/>
				{uniqueLang && (
					<strong className="cardSearch-strong">
						{intl.formatMessage({id: 'general.language', defaultMessage: 'Language'})}:
					</strong>
				)}
				{uniqueLang && `${
					intl.formatMessage({id: languageLabel, defaultMessage: languageLabel})
				}`}
				{uniqueLang && (<br/>)}
				{type === "nonfoil" ? `` : (<strong>{`(${type.toUpperCase()})`}</strong>)}
			</p>
		);
	};
	
	const cardDescFormatter = (id, rarity, manaCost, typeLine) => {
		const mana = renderManaSymbols(id, manaCost, '15', '15', 'cardSearch-row-mana-icon');
		return (
			<p>
				<strong className="cardSearch-strong">
					{intl.formatMessage({id: 'game.rarity', defaultMessage: 'Rarity'})}:
				</strong>{rarity.charAt(0).toUpperCase() + rarity.substring(1)}<br/>
				<strong className="cardSearch-strong">
					{intl.formatMessage({id: 'mtg.mana', defaultMessage: 'Mana'})}:
				</strong>{mana}<br/>
				<strong className="cardSearch-strong">
					{intl.formatMessage({id: 'game.type', defaultMessage: 'Type'})}:
				</strong>{typeLine}<br/>
			</p>
		);
	};

	const cardMobileInfoFormatter = (name, set, type, lang,id, rarity, manaCost, typeLine) => {
		const uniqueLang = lang !== "en";
		const languageLabel = getLanguageByCode(lang).id;
		const mana = renderManaSymbols(id, manaCost, '15', '15', 'cardSearch-row-mana-icon');
		return (
			<p className="cardSearch-cardInfo">
				<strong className="cardSearch-strong">
					{intl.formatMessage({id: 'general.name', defaultMessage: 'Name'})}:
				</strong>{name}<br/>
				<strong className="cardSearch-strong">
					{intl.formatMessage({id: 'game.set', defaultMessage: 'Set'})}:
				</strong>{set}<br/>
				{uniqueLang && (
					<strong className="cardSearch-strong">
						{intl.formatMessage({id: 'general.language', defaultMessage: 'Language'})}:
					</strong>
				)}
				{uniqueLang && `${
					intl.formatMessage({id: languageLabel, defaultMessage: languageLabel})
				}`}
				{uniqueLang && (<br/>)}
				<strong className="cardSearch-strong">
					{intl.formatMessage({id: 'game.rarity', defaultMessage: 'Rarity'})}:
				</strong>{rarity.charAt(0).toUpperCase() + rarity.substring(1)}<br/>
				{type === "nonfoil" ? `` : (<strong>{`(${type.toUpperCase()})`}<br/></strong>)}
				<strong className="cardSearch-strong">
					{intl.formatMessage({id: 'mtg.mana', defaultMessage: 'Mana'})}:
				</strong>{mana}<br/>
				<strong className="cardSearch-strong">
					{intl.formatMessage({id: 'game.type', defaultMessage: 'Type'})}:
				</strong>{typeLine}<br/>
			</p>
		);
	};

	
	return (
		<div  className={`${view}-cardSearch-div`} >
			<CHTable
				CHStyle="products"
				columns={columns}
				rows={printRows}
				linkedRows={true}
			/>
			{isFetching
				? <CHSpinner id="CardSearch-spinner" isActive={isFetching} />
				: <h3 className="cardSearch-h3">{message}</h3>
			}
		</div>
	);
};

CardSearch.propTypes = {
};

export default CardSearch;