import React, {useEffect, useState, useRef} from 'react';
import './Table.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { isMobile } from 'react-device-detect';

import CardSearcher from '../../../../components/CardSearcher';
import CHTable from '../../../../components/CHComponents/CHTable';
import SaleEditionModal from './SaleEditionModal';
import { SaleEditionCell } from '../../../../components/customColumns/SaleEditionColumn';
import SaleDeleteModal from './SaleDeleteModal';
import CHButton from '../../../../components/CHComponents/CHButton';
import { AcceptsTradeCellContent } from '../../../../components/customColumns/AcceptsTradeColumn';
import { ObservationCellContent } from '../../../../components/customColumns/ObservationColumn';
import SaleCreationModal from './SaleCreationModal';
import {
	getCardTypesOptions, getLanguagesOptions,
	getCardConditionsOptions, getCardSaleStatusOptions,
	getCardTypeById, getCardConditionById
} from '../../../../utils/enumsService';
import { getUserCardSales, updateCardSale } from '../../../../api/mtgSalesServiceApi';
import { onCheckboxFilter, filterRows, addFilter, clearFilters } from '../../../../utils/TableFilterService';
import { changeSorting, sortRows } from "../../../../utils/TableSortService";
import { floatToString } from '../../../../utils/stringParser';
import CHSpinner from '../../../../components/CHComponents/CHSpinner';
import { CARD_SALESTATUS_PAUSED, CARD_SALESTATUS_CLOSED } from '../../../../utils/constants';

const sortByContent = [3];

const Table = props => {
	const intl = useIntl();
	const [isFetchingSales, setIsFetchingSales] = useState(false);
	const [sales, setSales] = useState([]);
	const [rows, setRows] = useState([]);
	const [filteredRows, setFilteredRows] = useState([]);
	const [filters, setFilters] = useState({});
	const filtersRef = useRef({});
	filtersRef.current = filters;
	const [sorting, setSorting] = useState({by: 0, asc: true});
	const { isOwnProfile, userId } = props;
	const [isEditionModalOpen, setIsEditionModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);
	const [saleSelected, setSaleSelected] = useState(null);

	/* ---- COLUMNS ---- */

	const onCheckboxFilterChange = (event, columnId, columnLabel) => {
		const tableFilter = onCheckboxFilter({...filtersRef.current}, [...columns], event, columnId, columnLabel);
		setColumns(tableFilter.columns);
		setFilters(tableFilter.filters);
	};

	const [columns, setColumns] = useState([
		{id: 0, label: intl.formatMessage({id: 'general.name', defaultMessage: 'Name'}), sortable: true},
		{id: 1, label: intl.formatMessage({id: 'game.set', defaultMessage: 'Set'}), sortable: true},
		{id: 2, label: intl.formatMessage({id: 'game.type', defaultMessage: 'Type'}), sortable: true, filter: {
			type: 'checkbox',
			maxMenuHeight: 'small',
			options: getCardTypesOptions().map(cardType => ({...cardType, checked: false})),
			onChange: onCheckboxFilterChange
		}},
		{id: 3, label: intl.formatMessage({id: 'general.language', defaultMessage: 'Language'}), sortable: true, filter: {
			type: 'checkbox',
			maxMenuHeight: 'small',
			options: getLanguagesOptions().map(language => ({
				...language,
				label: intl.formatMessage({id: language.label, defaultMessage: language.label}),
				checked: false
			})),
			onChange: onCheckboxFilterChange
		}},
		{id: 4, label: intl.formatMessage({id: 'game.condition', defaultMessage: 'Condition'}), sortable: true, filter: {
			type: 'checkbox',
			maxMenuHeight: 'small',
			options: getCardConditionsOptions().map(condition => ({...condition, checked: false})),
			onChange: onCheckboxFilterChange
		}},
		{id: 5, label: intl.formatMessage({id: 'general.acceptsTrade', defaultMessage: 'Accepts trade'})},
		{id: 6, label: intl.formatMessage({id: 'general.quantity', defaultMessage: 'Quantity'})},
		{id: 7, label: intl.formatMessage({id: 'general.price', defaultMessage: 'Price'}) + ' (U$S)'},
		{id: 8, label: intl.formatMessage({id: 'general.observation', defaultMessage: 'Observation'})},
		{id: 9, label: intl.formatMessage({id: 'general.status', defaultMessage: 'Status'}), filter: {
			type: 'checkbox',
			maxMenuHeight: 'small',
			options: getCardSaleStatusOptions().map(status => ({
				...status,
				label: intl.formatMessage({id: status.label, defaultMessage: status.label}),
				checked: false
			})),
			onChange: onCheckboxFilterChange
		}},
		{id: 10, label: intl.formatMessage({id: 'general.edit', defaultMessage: 'Edit'})}
	]);

	/* ---- LIFE CYCLE ---- */

	const getSales = () => {
		let mounted = true;
		const cancelSource = axios.CancelToken.source();
		if(userId > 0) {
			if(mounted) setIsFetchingSales(true);
			getUserCardSales(userId, cancelSource.token)
				.then(sales => {
					if(mounted) {
						const saleRows = sales.map(sale => {
							const row = {
								id: sale.id,
								customData: {
									cardSaleStatus: sale.cardSaleStatus
								},
								cells: [
									{content: sale.cardName, value: sale.cardName},
									{content: sale.cardSet, value: sale.cardSet},
									{content: getCardTypeById(sale.cardType).label, value: getCardTypeById(sale.cardType).value},
									{
										content: intl.formatMessage({id: sale.cardLanguage, defaultMessage: sale.cardLanguage}),
										value: sale.cardLanguage
									},
									{content: getCardConditionById(sale.cardCondition).label, value: getCardConditionById(sale.cardCondition).value},
									{content: <AcceptsTradeCellContent acceptsTrade={sale.cardTradeable} />},
									{content: sale.quantity},
									{content: floatToString(sale.price)},
									{content: <ObservationCellContent id={`${sale.id}-observation`} observation={sale.observation} />}
								]
							};
							isOwnProfile && row.cells.push({
								content: intl.formatMessage({id: sale.cardSaleStatus, defaultMessage: sale.cardSaleStatus}),
								value: sale.cardSaleStatus
							});
							return row;
						});
						setIsFetchingSales(false);
						setSales(sales);
						const sortedRows = sortRows([...saleRows], sorting.by, sorting.asc ? 'asc' : 'desc');
						setRows(sortedRows);
						setFilteredRows(sortedRows);
					}
				})
				.catch(error => mounted && setIsFetchingSales(false));
		}

		return () => {
			mounted = false;
			cancelSource.cancel('Cancelling on unmount');
		};
	};

	useEffect(getSales, [userId]);

	const onfiltersChange = () => {
		const newFilteredRows = filterRows([...rows], {...filters});
		setFilteredRows(newFilteredRows);
	};

	useEffect(onfiltersChange, [filters]);

	const onSorting = () => {
		const sortedRows = sortRows([...filteredRows], sorting.by, sorting.asc ? 'asc' : 'desc', sortByContent);
		setFilteredRows(sortedRows);
	};

	useEffect(onSorting, [sorting]);

	/* ---- SEARCH ---- */

	const onCardSearch = (cardName) => {
		let newFilters;
		if(cardName.length === 0) {
			newFilters = clearFilters({...filters}, 'cardName');
		}else {
			newFilters = addFilter({...filters}, 0, 'cardName', cardName);
		}
		setFilters(newFilters);
	};

	/* ---- SORT ---- */

	const onSort = (columnId) => {
		const newSorting = changeSorting({...sorting}, columnId);
		setSorting(newSorting);
	};

	/* ---- CREATE ---- */

	const onSaleCreated = (saleId) => {
		getSales();
		setIsCreationModalOpen(false);
	};

	/* ---- CHANGE STATUS ---- */

	const onChangeStatus = (saleId) => {
		const cardSale = sales.find(sale => sale.id === saleId);
		updateCardSale({
			...cardSale,
			cardSaleStatus: cardSale.cardSaleStatus === CARD_SALESTATUS_CLOSED
				? CARD_SALESTATUS_PAUSED : CARD_SALESTATUS_CLOSED
		}).then(() => getSales());
	};

	/* ---- EDIT ---- */

	const onEdit = (saleId) => {
		const cardSale = sales.find(sale => sale.id === saleId);
		setSaleSelected(cardSale);
		setIsEditionModalOpen(true);
	};

	const onSaleEdited = () => {
		getSales();
		setIsEditionModalOpen(false);
	};

	/* ---- DELETE ---- */

	const onDelete = (saleId) => {
		const cardSale = sales.find(sale => sale.id === saleId);
		setSaleSelected(cardSale);
		setIsDeleteModalOpen(true);
	};

	const onSaleDeleted = () => {
		getSales();
		setIsDeleteModalOpen(false);
	};

	return (
		<div className="UserSalesTable-div">
			<div className={`UserSalesTable-actions-div${isOwnProfile ? '' : '-otherProfile'}`}>
				<div className="UserSalesTable-filters-div">
					<CardSearcher
						className={(isMobile?"mobile-":"browser-")+"userSalesTable-filters-cardSearcher"}
						onCardSelected={onCardSearch}
						isClearable
					/>
				</div>
				{isOwnProfile &&
					<div className="UserSalesTable-add-div">
						<CHButton
							className="UserSalesTable-add-addButton"
							label={intl.formatMessage({id: 'general.add', defaultMessage: 'Add'})}
							onClick={() => setIsCreationModalOpen(true)}
						/>
					</div>
				}
			</div>
			<CHTable tableClassName="UserSalesTable-userSales"
				columns={isOwnProfile ? columns : columns.slice(0, 9)}
				rows={filteredRows}
				onSort={onSort}
				sortedBy={sorting.by}
				sortDirection={sorting.asc ? 'asc' : 'desc'}
				customColumns={isOwnProfile
					? [{cellComponent: SaleEditionCell, args: {onChangeStatus, onEdit, onDelete}}]
					: []
				}
			/>
			<CHSpinner id="UserSalesTable-spinner" isActive={isFetchingSales} />

			<SaleCreationModal
				isOpen={isCreationModalOpen}
				onClose={() => setIsCreationModalOpen(false)}
				onAccept={(saleId) => onSaleCreated(saleId)}
				userId={userId}
			/>
			<SaleEditionModal
				isOpen={isEditionModalOpen}
				onClose={() => setIsEditionModalOpen(false)}
				onAccept={onSaleEdited}
				cardSale={saleSelected}
			/>
			<SaleDeleteModal
				isOpen={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
				onAccept={onSaleDeleted}
				cardSale={saleSelected}
			/>
		</div>
	);
};

Table.propTypes = {
	isOwnProfile: PropTypes.bool.isRequired,
	userId: PropTypes.number.isRequired
};

export default Table;