/*
* ApiGatewayService API.
*/

import { getToken } from '../utils/localStorageManager';

const localConfig = require('../resources/localConfig.json');
const {host, port} = localConfig.apiGateway;
const axios = require('axios');
const apiGateway = axios.create({
	baseURL: `https://${host}:${port}/api/`,
	timeout: 10000
});

/* ---- UTILS ---- */

const buildQueryParams = (params) => {
	const keys = Object.keys(params);
	let query = '';
	keys.forEach((key, index) => {
		if(params[key] instanceof Array) {
			params[key].forEach((value, i) => {
				if(index === 0 && i === 0) {
					query += `?${key}=${params[key][i]}`;
				}else {
					query += `&${key}=${params[key][i]}`;
				}
			});
		}else if(index === 0) {
			query += `?${key}=${params[key]}`;
		}else {
			query += `&${key}=${params[key]}`;
		}
	});
	return query;
};

const getRequestPath = (path, params) => {
	let requestPath = path;
	if(params) {
		requestPath += buildQueryParams(params);
	}
	return requestPath;
};

const getConfig = (cancelToken) => {
	return {
		cancelToken,
		headers: {
			Authorization: `Bearer ${getToken()}`
		}
	};
};

/* --- INTERCEPTORS --- */

/*
* error:
* 	status: HTTP code
* 	data: message
* 	...
*/
apiGateway.interceptors.response.use(
	response => response,
	error => Promise.reject(error.response)
);

/* ---- REST METHODS ---- */

/*
* path: string
* 	has to start without '/', ex.: 'identity/user'
*
* params: {key: any}
*
* body: {key: any}
*
* cancelToken: axios.CancelToken.source().token
*
*/

const getData = async (path, params = null, cancelToken = null) => {
	const response = await apiGateway.get(getRequestPath(path, params), getConfig(cancelToken));
	return response.data;
};

const postData = async (path, params = null, body = null, cancelToken = null) => {
	const response = await apiGateway.post(getRequestPath(path, params), body, getConfig(cancelToken));
	return response.data;
};

const putData = async (path, params = null, body = null, cancelToken = null) => {
	const response = await apiGateway.put(getRequestPath(path, params), body, getConfig(cancelToken));
	return response.data;
};

const deleteData = async (path, params = null, cancelToken = null) => {
	const response = await apiGateway.delete(getRequestPath(path, params), getConfig(cancelToken));
	return response.data;
};

export { getData, postData, putData, deleteData };
