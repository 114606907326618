import React, {useState} from 'react';
import './WishDeleteModal.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import CHModal from '../../../../components/CHComponents/CHModal';
import CHModalBody from '../../../../components/CHComponents/CHModalBody';
import CHModalFooter from '../../../../components/CHComponents/CHModalFooter';
import CHModalHeader from '../../../../components/CHComponents/CHModalHeader';
import CHError from '../../../../components/CHComponents/CHError';
import { getCardConditionById, getCardTypeById } from '../../../../utils/enumsService';
import {deleteCardWish} from '../../../../api/mtgSalesServiceApi';

const WishDeleteModal = props => {
	const intl = useIntl();
	const {wish, isOpen, onClose, onDelete} = props;
	const [showActionError, setShowActionError] = useState(false);
	const [actionError, setActionError] = useState(null);
	const allM = intl.formatMessage({id: 'general.allM', defaultMessage: 'All'});
	const allF = intl.formatMessage({id: 'general.allF', defaultMessage: 'All'});

	const getLanguage = () => intl.formatMessage({id: wish.cardLanguage, defaultMessage: wish.cardLanguage});

	const onAccept = () => {
		setShowActionError(false);
		deleteCardWish(wish.id)
			.then(response => onDelete())
			.catch((error) => {
				setActionError(error);
				setShowActionError(true);
			});
	};

	const closeModal = () => {
		setShowActionError(false);
		onClose();
	};

	return (
		<CHModal isOpen={isOpen} toggle={closeModal}>
			<CHModalHeader tittle={intl.formatMessage({id: 'general.delete', defaultMessage: 'Delete'})} />
			<CHModalBody>
				<p>
					{intl.formatMessage({
						id: 'message.deleteWish',
						defaultMessage: 'Are you sure you want to delete this element from the wishlist?'
					})}
				</p>
				<div className="UserWishlist-deleteModal-info-div">
					<label className="UserWishlist-deleteModal-info-label">
						{intl.formatMessage({id: 'game.card', defaultMessage: 'Card'})}:
					</label>{wish && wish.cardName}<br/>
					<label className="UserWishlist-deleteModal-info-label">
						{intl.formatMessage({id: 'game.set', defaultMessage: 'Set'})}:
					</label>{wish ? wish.cardSet : allF}<br/>
					<label className="UserWishlist-deleteModal-info-label">
						{intl.formatMessage({id: 'game.type', defaultMessage: 'Type'})}:
					</label>{getCardTypeById(wish ? wish.cardType : allM).label}<br/>
					<label className="UserWishlist-deleteModal-info-label">
						{intl.formatMessage({id: 'game.condition', defaultMessage: 'Condition'})}:
					</label>{getCardConditionById(wish ? wish.cardCondition : allF).label}<br/>
					<label className="UserWishlist-deleteModal-info-label">
						{intl.formatMessage({id: 'general.language', defaultMessage: 'Language'})}:
					</label>{wish ? getLanguage() : allM}<br/>
				</div>
				<CHError
					id="UserWishlist-deleteModal-error"
					isShown={showActionError}
					error={actionError}
				/>
			</CHModalBody>
			<CHModalFooter
				type="save"
				acceptLabel={intl.formatMessage({id: 'general.delete', defaultMessage: 'Delete'})}
				onAccept={onAccept}
			/>
		</CHModal>
	);
};

WishDeleteModal.propTypes = {
	wish: PropTypes.shape({
		id: PropTypes.number.isRequired,
		userId: PropTypes.number.isRequired,
		cardName: PropTypes.string.isRequired,
		cardSet: PropTypes.string,
		cardType: PropTypes.number,
		cardLanguage: PropTypes.number,
		cardCondition: PropTypes.number,
		country: PropTypes.string,
		state: PropTypes.string,
		city: PropTypes.string
	}),
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};

WishDeleteModal.defaultProps = {
	wish: null
};

export default WishDeleteModal;