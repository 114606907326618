import React, { useState, useEffect } from 'react';
import './Layout.css';
import { Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
// TODO addLocalData
import messages_es from '../../resources/i18n/es';
import messages_en from '../../resources/i18n/en';
import messages_pt from '../../resources/i18n/pt';
import messages_fi from '../../resources/i18n/fi';
import flagBrasil from '../../assets/icons/flagBrasil.svg';
import flagEngland from '../../assets/icons/flagEngland.svg';
import flagSpain from '../../assets/icons/flagSpain.svg';
import flagFiambres from '../../assets/icons/flagFiambres.svg';

import TopBar from '../topBar/TopBar';
import Footer from '../footer/Footer';
import GamePage from '../gamePage/GamePage';
import CardSearch from '../cardSearch/CardSearch';
import Home from '../home/Home';
import CardSales from '../cardSales/CardSales';
import RegisterPage from '../registerPage/RegisterPage'; 
import chTest from '../chTest/chTest'; 
import UserProfile from '../userProfile/UserProfile';
import UserEdition from '../userEdition/UserEdition';
import Cart from '../cart/Cart';
import { setTheme, getTheme, setLanguage, getLanguage } from '../../utils/localStorageManager';
import { isMobile } from 'react-device-detect';
import store from '../../redux/store';

const view = isMobile?'mobile':'browser';

const messages = {
	es: messages_es,
	en: messages_en,
	pt: messages_pt,
	fi: messages_fi
};
const fiambreOption = {id: 'fi', value: 'fi', content: <img className={isMobile?"mobile-layout-languageFlag-img":"browser-layout-languageFlag-img"} src={flagFiambres} alt=""/>};
const localeOptions = [
	{id: 'es', value: 'es', content: <img className={isMobile?"mobile-layout-languageFlag-img":"browser-layout-languageFlag-img"} src={flagSpain} alt="" />},
	{id: 'pt', value: 'pt', content: <img className={isMobile?"mobile-layout-languageFlag-img":"browser-layout-languageFlag-img"} src={flagBrasil} alt=""/>},
	{id: 'en', value: 'en', content: <img className={isMobile?"mobile-layout-languageFlag-img":"browser-layout-languageFlag-img"} src={flagEngland} alt=""/>}
];

const Layout = props => {
	const [addFiambre, setAddFiambre] = useState(false);
	const [locale, setLocale] = useState(localeOptions[0]);

	/* ---- THEME ---- */
	
	const setLightTheme = () => {
		setTheme('light');
		setTransition();
		document.documentElement.setAttribute('data-theme', 'light');
	};
	
	const setDarkTheme = () => {
		setTheme('dark');
		setTransition();
		document.documentElement.setAttribute('data-theme', 'dark');
	};

	const setTransition = () => {
		document.documentElement.classList.add('theme-transition');
		window.setTimeout(() => {
			document.documentElement.classList.remove('theme-transition')
		}, 300);
	};
	
	const setInitTheme = () => {
		const theme = getTheme();
		if(theme === 'light') {
			setLightTheme();
		}else {
			setDarkTheme();
		}
	};

	useEffect(setInitTheme, []);
	
	const switchTheme = (isDark) => {
		if(isDark) {
			setLightTheme();
		}else {
			setDarkTheme();
		}
	};


	/* ---- LANGUAGE ---- */

	const initLanguage = () => {
		const randN = Math.floor(Math.random() * 10000);
		const language = getLanguage();
		const browserLanguage = navigator.language && navigator.language.split(/[-_]/)[0];
		if(randN === 0) {
			setLocale(fiambreOption);
			setAddFiambre(true);
		}else if(language && messages[language]) {
			setLocale(localeOptions.find(option => option.id === language));
		}else if(browserLanguage && messages[browserLanguage]) {
			setLocale(localeOptions.find(option => option.id === browserLanguage));
			setLanguage(browserLanguage);
		}
	};

	useEffect(initLanguage, []);

	const changeLanguage = (localeOption) => {
		setLocale(localeOption);
		setLanguage(localeOption.id);
	};

	return (
		<Provider store={store}>
			<IntlProvider key={locale.id} locale={locale.id} messages={messages[locale.id]}>
				<div className={`${view}-default-style`}>
					<TopBar
						switchTheme={switchTheme}
						localeOptions={addFiambre ? localeOptions.concat([fiambreOption]) : localeOptions}
						changeLanguage={(option) => changeLanguage(option)}
						selectedLanguage={locale}
					/>
					<Route exact path='/' component={Home} />
					<Route path='/(mtg|ygo|pkm)\/cardSales/:cardId' component={CardSales} />
					<Route exact path='/mtg' component={GamePage}/>
					<Route path='/(mtg|ygo|pkm)\/cardSearch/' component={CardSearch}/>
					<Route path='/registerPage' component={RegisterPage} />
					<Route path='/chTest' component={chTest} />
					<Route path='/(mtg|ygo|pkm)\/users/:username' component={UserProfile} />
					<Route path='/(mtg|ygo|pkm)\/userEdition/:username' component={UserEdition} />
					<Route path='/cart' component={Cart} />
					<Footer/>
				</div>
			</IntlProvider>
		</Provider>
	);
};

export default Layout;
