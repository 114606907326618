/*
* For parsing, manipulating, comparing, and validating strings.
*/

import React from 'react';
import Icon from '../components/Icon';
const encodeUrl = require('encodeurl');


// Public - Accepts names in percent coding.
const areCardNamesEqual = (nameOne, nameTwo) => {
	if(isDouble(nameOne)){
		const faces = nameOne.split(" // ");
		return (areCardNamesEqual(faces[0], nameTwo) || areCardNamesEqual(faces[1], nameTwo));
	}else if(isDouble(nameTwo)){
		const faces = nameTwo.split(" // ");
		return (areCardNamesEqual(nameOne, faces[0]) || areCardNamesEqual(nameOne, faces[1]));
	}
	
	const oneParsed = parseCardName(nameOne);
	const twoParsed = parseCardName(nameTwo);
	return oneParsed === twoParsed;
};

const isDouble = (name) => {
	const faces = name.split(" // ");
	return faces.length === 2;
};

const parseCardName = (name) => {
	return encodeUrl(
		name
		.toLowerCase()
		.split("'").join("")
	);
};

// Public
const renderManaSymbols = (cardId, manaString, width, height, className) => {
	if(isDouble(manaString)){
		const manaCosts = manaString.split(" // ");
		const costOne = renderManaSymbols(`1${cardId}`, manaCosts[0], width, height, className);
		const costTwo = renderManaSymbols(`2${cardId}`, manaCosts[1], width, height, className);
		if(manaCosts[1].length === 0){
			return costOne;
		}
		return costOne.concat([" // "]).concat(costTwo);
	}
	const manaSymbols = manaString.substring(1, manaString.length-1).split("}{");
	let renders = [];
	manaSymbols.forEach((symbol, index) => {
		const render = (<Icon name={symbol} type="manaSymbol" width={width} height={height} className={className} key={index + cardId}/>);
		if(render){
			renders.push(render);
		}
	});
	return renders;
};

const renderOracleText = (oracleText, iconClassName) => {
	const segments = oracleText.split('{');
	return segments.map((segment, index) => {
		const subSegments = segment.split('}');
		if(subSegments.length === 1) {
			return subSegments[0];
		}
		return [
			renderManaSymbols(index, `{${subSegments[0]}}`, 15, 15, iconClassName)[0],
			subSegments[1]
		];
	});
};

const floatToString = (value) => {
	const strParts = value.toString().split('.');
	return strParts.length === 2
		? `${strParts[0]}.${strParts[1].length === 2 ? strParts[1] : `${strParts[1]}0`}`
		: `${strParts[0]}.00`;
};

export { areCardNamesEqual, renderManaSymbols, renderOracleText, floatToString };

