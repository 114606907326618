import React from 'react';
import './CHAlert.css';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import CHErrorMessage from "./CHErrorMessage";

const CHAlert = props => {
	const { isOpen, label, position, className, CHStyle, isError, error, altErrorMessage } = props;

	const getStyles = () => {
		let styles = 'CHAlert-base';
		if(isError) {
			styles += ' CHAlert-style-warning';
		}else {
			styles += CHStyle ? ` CHAlert-style-${CHStyle}` : ' CHAlert-style-brand';
		}
		styles += position ? ` CHAlert-position-${position}` : ' CHAlert-position-bottom-right';
		if(className) {
			styles += ` ${className}`;
		}
		return styles;
	};

	return (
		<Alert
			className={getStyles()}
			isOpen={isOpen}
		>
			{isError ? <CHErrorMessage error={error} altMessage={altErrorMessage} /> : label}
		</Alert>
	);
};

CHAlert.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	label: PropTypes.string.isRequired,
	position: PropTypes.oneOf(['top-left', 'top-right', 'bottom-left', 'bottom-right']),
	isError: PropTypes.bool,
	// axios error: {status: number, data: string | any, ...}
	error: PropTypes.any,
	altErrorMessage: PropTypes.string,
	className: PropTypes.string,
	CHStyle: PropTypes.oneOf(['brand', 'warning', 'accept'])
};

/* recieves:
* onClose: () => setIsOpen(false)
* duration: int (seconds)
*/
const showAlert = (onClose, duration = 3) => {
	return new Promise(resolve => {
		setTimeout(onClose, duration * 1000);
	});
};

export {
	CHAlert,
	showAlert
};