import React, { useState, Fragment, forwardRef } from 'react';
import './CHInput.css';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import {isMobile} from 'react-device-detect';


const CHInput = forwardRef((props, ref) => {
	const {
		id, type,
		value, min, max, onChange,
		className, CHStyle, disabled, placeholder,
		error, onKeyPress
	} = props;
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	const getStyles = () => {
		let styles =  'CHInput-base';
		styles += CHStyle ? ` CHInput-${CHStyle}` : ' CHInput-brand';
		if(className) {
			styles += ` ${className}`;
		}
		if(error && error.isInvalid) {
			styles += ` CHInput-error`;
		}
		if(disabled) {
			styles += ` CHInput-disabled`;
		}
		if(isMobile) {
			styles += ` CHInput-mobile`;
		}
		return styles;
	};

	const onInputChange = (event) => {
		if(type === 'int') {
			onChange(parseInt(event.target.value, 10));
		}else if(type === 'float') {
			const number = event.target.value.split('.');
			if(number.length === 2) {
				onChange(parseFloat(`${number[0]}.${number[1].substring(0,2)}`));
			}else {
				onChange(parseFloat(event.target.value));
			}
		}else {
			onChange(event.target.value);
		}
	};

	const toggleTooltip = () => {
		setIsTooltipOpen(
			!isTooltipOpen
			&& error.isInvalid
			&& error.message
			&& error.message.length > 0
		);
	};

	return (
		<Fragment>
			<input
				ref={ref || null}
				id={id}
				disabled={disabled}
				className={getStyles()}
				type={['int', 'float'].includes(type) ? 'number' : type}
				value={value}
				min={min}
				max={max}
				onChange={event => onInputChange(event)}
				placeholder={placeholder}
				onKeyPress={(event) => onKeyPress && onKeyPress(event.key)}
			/>
			{error && (
				<Tooltip
					innerClassName="CHInput-error-tooltip"
					hideArrow
					placement="top"
					isOpen={isTooltipOpen}
					target={id}
					toggle={toggleTooltip}
				>
					{error ? error.message : ''}
				</Tooltip>
			)}
		</Fragment>
	);
});

CHInput.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	type: PropTypes.oneOf(['text', 'int', 'float', 'email', 'password']).isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	// onChange(value: number/string)
	onChange: PropTypes.func,
	min: PropTypes.number,
	max: PropTypes.number,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	CHStyle: PropTypes.oneOf(['brand','error']),
	className: PropTypes.string,
	error: PropTypes.shape({
		isInvalid: PropTypes.bool.isRequired,
		message: PropTypes.string
	}),
	ref: PropTypes.any,
	// onKeyPress(key)
	onKeyPress: PropTypes.func
};

export default CHInput;
