import React, { useState } from 'react';
import './DeleteAccountModal.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import CHModal from '../../components/CHComponents/CHModal';
import CHModalHeader from '../../components/CHComponents/CHModalHeader';
import CHModalBody from '../../components/CHComponents/CHModalBody';
import CHModalFooter from '../../components/CHComponents/CHModalFooter';
import CHInput from '../../components/CHComponents/CHInput';
import CHError from '../../components/CHComponents/CHError';
import { deleteUser } from '../../api/identityServiceApi';

const defaultWarningMsg = 'WARINING: Are you sure you want to delete your account? Have in mind that you won\'t be able '
+ 'to use neither the same username nor the same email again. This action can\'t be undone.';
const DeleteAccountModal = props => {
	const intl = useIntl();
	const { userId, isOpen, onAccept, onClose } = props;
	const [password, setPassword] = useState('');
	const [showActionError, setShowActionError] = useState(false);
	const [actionError, setActionError] = useState(null);

	const resetValues = () => {
		setPassword('');
		setShowActionError(false);
	};

	const closeModal = () => {
		resetValues();
		onClose();
	};

	const onSave = () => {
		setShowActionError(false);
		deleteUser(userId, password)
			.then(() => {
				resetValues();
				onAccept();
			})
			.catch((error) => {
				setActionError(error);
				setShowActionError(true);
			});
	};

	return (
		<CHModal isOpen={isOpen} toggle={closeModal}>
			<CHModalHeader tittle={intl.formatMessage({id: 'general.deleteAccount', defaultMessage: 'Delete Account'})}/>
			<CHModalBody>
				<p>
					{intl.formatMessage({id: 'message.deleteAccount', defaultMessage: defaultWarningMsg})}
				</p>
				<CHInput
					className="deleteAccountModal-password"
					id="password"
					type="password"
					placeholder={intl.formatMessage({id: 'general.password', defaultMessage: 'Password'})}
					value={password}
					onChange={value => setPassword(value)}
				/>

				<CHError
					id="deleteAccountModal-error"
					isShown={showActionError}
					error={actionError}
				/>
			</CHModalBody>
			<CHModalFooter
				type="save"
				acceptStyle="warning"
				acceptLabel={intl.formatMessage({id: 'general.delete', defaultMessage: 'Delete'})}
				onAccept={onSave}
			/>
		</CHModal>
	);
};

DeleteAccountModal.propTypes = {
	userId: PropTypes.number.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onAccept: PropTypes.func.isRequired
};

export default DeleteAccountModal;