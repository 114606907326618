import React, { useState, useEffect } from 'react';
import './SaleHistoryStatusEditor.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { updateSaleHistories } from '../../../../api/mtgSalesServiceApi';
import CHButton from '../../../../components/CHComponents/CHButton';
import CHModal from '../../../../components/CHComponents/CHModal';
import CHModalBody from '../../../../components/CHComponents/CHModalBody';
import CHModalFooter from '../../../../components/CHComponents/CHModalFooter';
import { SALEHISTORY_FINISHEDF, SALEHISTORY_PENDING_CLOSURE } from '../../../../utils/constants';
import CHTextarea from '../../../../components/CHComponents/CHTextarea';
import CHCheckbox from '../../../../components/CHComponents/CHCheckbox';
import Icon from '../../../../components/Icon';
import CHModalHeader from '../../../../components/CHComponents/CHModalHeader';
import CHError from '../../../../components/CHComponents/CHError';

/* ---- EDITOR SECTION ----*/

const SaleHistoryStatusEditor = props => {
	const intl = useIntl();
	const { saleHistories, isSeller, onSaleHistoryStatusSave, className } = props;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isFlowModalOpen, setIsFlowModalOpen] = useState(false);

	const onStatusSave = () => {
		setIsModalOpen(false);
		onSaleHistoryStatusSave();
	};

	return (
		<div className={`saleHistoryStatusEditor-div ${className ? className : ''}`}>
			<CHButton
				className={`saleHistoryStatusEditor-flow-btn`}
				id="saleHistoryStatusEditor-btn"
				label={(
					<Icon
						type="other"
						name="branch"
						width={25}
						height={25}
					/>
				)}
				onClick={() => setIsFlowModalOpen(true)}
			/>
			<CHButton
				id="saleHistoryStatusEditor-btn"
				label={intl.formatMessage({id: 'general.confirmChanges', defaultMessage: 'Confirm changes'})}
				CHStyle="accept"
				onClick={() => setIsModalOpen(true)}
				disabled={saleHistories.length === 0}
			/>
			<SaleHistoryStatusEditorModal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onAccept={onStatusSave}
				saleHistories={saleHistories}
				isSeller={isSeller}
			/>
			<SaleHistoryStatusFlowModal
				isOpen={isFlowModalOpen}
				onClose={() => setIsFlowModalOpen(false)}
			/>
		</div>
	);
};

SaleHistoryStatusEditor.propTypes = {
	saleHistories: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number.isRequired,
		status: PropTypes.string.isRequired,
		newStatus: PropTypes.string.isRequired,
		rating: PropTypes.number.isRequired,
		comment: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired
	})).isRequired,
	isSeller: PropTypes.bool.isRequired,
	onSaleHistoryStatusSave: PropTypes.func.isRequired,
	className: PropTypes.string
};

/* ---- EDITOR MODAL ----*/

const defaultMsg = {
	invalidRating: 'User rating missing',
	invalidRatings: 'Users ratings missing'
};

const SaleHistoryStatusEditorModal = props => {
	const intl = useIntl();
	const { isOpen, onClose, onAccept, saleHistories, isSeller } = props;
	const [newSaleHistories, setNewSaleHistories] = useState([]);
	const [showError, setShowError] = useState(false);
	const [error, setError] = useState(null);
	const [errorAltMsg, setErrorAltMsg] = useState(null);

	/* ---- LIFE CYCLE ---- */

	const init = () => {
		const newNewSaleHistories = saleHistories.map(saleHistory => ({
			...saleHistory,
			ratingError: {
				isInvalid: false,
				message: intl.formatMessage({id: "error.missingUserRating", defaultMessage: defaultMsg.invalidRating})
			}
		}));
		setNewSaleHistories(newNewSaleHistories);
	};

	useEffect(init, [saleHistories]);

	/* ---- ON ACTION ---- */

	const onSave = () => {
		setShowError(false);
		setErrorAltMsg(null);
		setError(null);
		const toSave = [];
		const newNewSaleHistories = [];
		let invalid = false;

		newSaleHistories.forEach(saleHistory => {
			if(
				[SALEHISTORY_PENDING_CLOSURE, SALEHISTORY_FINISHEDF].includes(saleHistory.newStatus)
				&& saleHistory.rating === 0
			) {
				invalid = true;
				newNewSaleHistories.push({
					...saleHistory,
					ratingError: {...saleHistory.ratingError, isInvalid: true}
				});
			}else {
				newNewSaleHistories.push({
					...saleHistory,
					ratingError: {...saleHistory.ratingError, isInvalid: false}
				});
				toSave.push({
					id: saleHistory.id,
					saleHistoryStatus: saleHistory.newStatus,
					...(isSeller ? {buyerRating: saleHistory.rating} : {sellerRating: saleHistory.rating}),
					...(isSeller ? {buyerComment: saleHistory.comment} : {sellerComment: saleHistory.comment})
				});
			}
		});

		if(invalid) {
			setNewSaleHistories(newNewSaleHistories);
			setShowError(true);
			setErrorAltMsg(intl.formatMessage({id: 'error.missingUsersRatings', defaultMessage: defaultMsg.invalidRating}));
		}else {
			updateSaleHistories(toSave)
				.then(result => {
					setShowError(false);
					onAccept();
				})
				.catch(error => {
					setShowError(true);
					setError(error);
				});
		}
	};

	const onRatingChange = (username, rating) => {
		const changedSaleHistories = [...newSaleHistories];
		changedSaleHistories.forEach(saleHistory => {
			if(saleHistory.username === username) {
				saleHistory.rating = rating;
			}
		});
		setNewSaleHistories(changedSaleHistories);
	};

	const onCommentChange = (username, newComment) => {
		const changedSaleHistories = [...newSaleHistories];
		changedSaleHistories.forEach(saleHistory => {
			if(saleHistory.username === username) {
				saleHistory.comment = newComment;
			}
		});
		setNewSaleHistories(changedSaleHistories);
	};

	const closeModal = () => {
		setNewSaleHistories(saleHistories);
		onClose();
	};

	/* ---- RENDER ---- */

	const stamp = (
		<Icon
			type="file"
			name="stamp"
			width={20}
			height={20}
		/>
	);

	const renderSaleHistory = (saleHistory) => (
		<div className={`saleHistoryStatusEditorModal-saleHistory-section`}>
			<div className={`saleHistoryStatusEditorModal-saleHistory-left`}>
				<label className={`saleHistoryStatusEditorModal-label`}>{`#${saleHistory.id}`}</label>
			</div>
			<div className={`saleHistoryStatusEditorModal-saleHistory-right`}>
				<label className={`saleHistoryStatusEditorModal-label`}>{
					intl.formatMessage({id: saleHistory.status, defaultMessage: saleHistory.status})
					+ ' -> '
					+ intl.formatMessage({id: saleHistory.newStatus, defaultMessage: saleHistory.newStatus})
 				}</label>
			</div>
			{[SALEHISTORY_PENDING_CLOSURE, SALEHISTORY_FINISHEDF].includes(saleHistory.newStatus) && (
				<div className={`saleHistoryStatusEditorModal-saleHistory-rating`}>
					<label className={`saleHistoryStatusEditorModal-label`}>
						{intl.formatMessage(
							{id: "general.rateUserUsername", defaultMessage: `Rate user: ${saleHistory.username}`},
							{username: saleHistory.username}
						)}
					</label>
					<CHCheckbox
						id={`saleHistoryStatusEditorModal-saleHistory-${saleHistory.id}-rate1`}
						className={`saleHistoryStatusEditorModal-saleHistory-rate-checkbox`}
						boxClassName={`saleHistoryStatusEditorModal-saleHistory-rate-checkbox-box`}
						CHStyle="box"
						checked={saleHistory.rating >= 1}
						onChange={() => onRatingChange(saleHistory.username, 1)}
						label={stamp}
					/>
					<CHCheckbox
						id={`saleHistoryStatusEditorModal-saleHistory-${saleHistory.id}-rate2`}
						className={`saleHistoryStatusEditorModal-saleHistory-rate-checkbox`}
						boxClassName={`saleHistoryStatusEditorModal-saleHistory-rate-checkbox-box`}
						CHStyle="box"
						checked={saleHistory.rating >= 2}
						onChange={() => onRatingChange(saleHistory.username, 2)}
						label={stamp}
					/>
					<CHCheckbox
						id={`saleHistoryStatusEditorModal-saleHistory-${saleHistory.id}-rate3`}
						className={`saleHistoryStatusEditorModal-saleHistory-rate-checkbox`}
						boxClassName={`saleHistoryStatusEditorModal-saleHistory-rate-checkbox-box`}
						CHStyle="box"
						checked={saleHistory.rating >= 3}
						onChange={() => onRatingChange(saleHistory.username, 3)}
						label={stamp}
					/>
					<CHCheckbox
						id={`saleHistoryStatusEditorModal-saleHistory-${saleHistory.id}-rate4`}
						className={`saleHistoryStatusEditorModal-saleHistory-rate-checkbox`}
						boxClassName={`saleHistoryStatusEditorModal-saleHistory-rate-checkbox-box`}
						CHStyle="box"
						checked={saleHistory.rating >= 4}
						onChange={() => onRatingChange(saleHistory.username, 4)}
						label={stamp}
					/>
					<CHCheckbox
						id={`saleHistoryStatusEditorModal-saleHistory-${saleHistory.id}-rate5`}
						className={`saleHistoryStatusEditorModal-saleHistory-rate-checkbox`}
						boxClassName={`saleHistoryStatusEditorModal-saleHistory-rate-checkbox-box`}
						CHStyle="box"
						checked={saleHistory.rating >= 5}
						onChange={() => onRatingChange(saleHistory.username, 5)}
						label={stamp}
					/>
					<CHTextarea
						className={`saleHistoryStatusEditorModal-comment`}
						id={`saleHistoryStatusEditorModal-${saleHistory.id}-comment`}
						placeholder={intl.formatMessage({id: 'input.comment', defaultMessage: 'Comment...'})}
						value={saleHistory.comment}
						onChange={(value) => onCommentChange(saleHistory.username, value)}
						error={saleHistory.ratingError}
					/>
				</div>
			)}
		</div>
	);

	return (
		<CHModal isOpen={isOpen} toggle={closeModal}>
			<CHModalHeader tittle={isSeller
				? intl.formatMessage({id: "general.sales", defaultMessage: "Sales"})
				: intl.formatMessage({id: "general.purchases", defaultMessage: "Compras"})
			}
			/>
			<CHModalBody>
				{newSaleHistories.map(saleHistory => renderSaleHistory(saleHistory))}
				<CHError
					id="saleHistoryStatusEditorModal-error-msg"
					isShown={showError}
					altMessage={errorAltMsg}
					error={error}
				/>
			</CHModalBody>
			<CHModalFooter
				type="save"
				acceptLabel={intl.formatMessage({id: 'general.accept', defaultMessage: 'Accept'})}
				onAccept={onSave}
			/>
		</CHModal>
	);
};

SaleHistoryStatusEditorModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onAccept: PropTypes.func.isRequired,
	saleHistories: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number.isRequired,
		status: PropTypes.string.isRequired,
		newStatus: PropTypes.string.isRequired,
		rating: PropTypes.number.isRequired,
		comment: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired
	})).isRequired,
	isSeller: PropTypes.bool.isRequired
};

const SaleHistoryStatusFlowModal = props => {
	const intl = useIntl();
	const { isOpen, onClose } = props;

	const close = () => {
		onClose();
	};

	return (
		<CHModal isOpen={isOpen} toggle={close}>
			<CHModalHeader tittle={`Flujo de compra`}/>
			<CHModalBody></CHModalBody>
		</CHModal>
	);
};

SaleHistoryStatusFlowModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};

export {
	SaleHistoryStatusEditor,
	SaleHistoryStatusEditorModal,
	SaleHistoryStatusFlowModal
};