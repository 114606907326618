/*
* Manage url strings and browser's history/state.
*/



const updateUrlwHistory = (location, history, url) => {
	if(location.pathname + location.search !== url){
		history.push(url);
	}
};

// query: location.search
const getUrlParams = (query) => {
	let params = {};
	let paramArr = query.substring(1).split("&");
	for(let i = 0; i < paramArr.length; i++){
		let param = paramArr[i].split("=");
		params[param[0]] = param[1];
	}
	return params;
};

const getUrlGame = (location) => {
	return location.pathname.split('/')[1];
};

export {
	updateUrlwHistory,
	getUrlParams,
	getUrlGame
};