/*
* Manage browser's local storage.
*/
import moment from 'moment';


/* ---- USER ---- */

/* recieves userInfo:
	id: number
	username: string
	userRole: string
	token: string
	expirationDate: string
*/
const setUser = (userInfo) => {
	window.localStorage.setItem('user', JSON.stringify(userInfo));
};

const getUsername = () => {
	const user = JSON.parse(window.localStorage.getItem('user'));
	return user ? user.username : null;
};

const getUserId = () => {
	const user = JSON.parse(window.localStorage.getItem('user'));
	return user ? user.id : null;
};

const getToken = () => {
	const user = JSON.parse(window.localStorage.getItem('user'));
	return user ? user.token : '';
};

const isTokenExpired = () => {
	const user = JSON.parse(window.localStorage.getItem('user'));
	const expirationDate = moment(user.expirationDate);
	const actualDate = moment();
	return expirationDate.isBefore(actualDate);
};

const clearUser = () => {
	window.localStorage.removeItem('user');
};

const isLoggedIn = () => {
	return window.localStorage.getItem('user');
};


/* ---- THEME ---- */

const setTheme = (theme) => {
	window.localStorage.setItem('theme', theme);
};

const getTheme = () => {
	return window.localStorage.getItem('theme');
};


/* ---- LANGUAGE ---- */

const getLanguage = () => {
	return window.localStorage.getItem('language');
};

const setLanguage = (language) => {
	window.localStorage.setItem('language', language);
};

export {
	setUser, getUsername, getUserId, clearUser, isLoggedIn, getToken, isTokenExpired,
	setTheme, getTheme,
	setLanguage, getLanguage
};
