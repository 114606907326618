import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/styles/ThemeVariables.css';
import './RegisterPage-Browser.css';
import './RegisterPage-Mobile.css';
import {isMobile} from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom'

import CHInput from '../../components/CHComponents/CHInput';
import CHButton from '../../components/CHComponents/CHButton';
import CHCheckbox from '../../components/CHComponents/CHCheckbox';
import CHTextarea from '../../components/CHComponents/CHTextarea';
import CHSelect from '../../components/CHComponents/CHSelect';
import CHError from '../../components/CHComponents/CHError';
import { getUserShippingOptions } from '../../utils/enumsService';
import { createUser } from '../../api/identityServiceApi';
import { NO_SHIPPING, SHIPPING_LOCAL, DELIVERY, NO_DELIVERY, MTG_CODE } from '../../utils/constants';
import { updateUrlwHistory } from '../../utils/urlManager';

const view = isMobile?'mobile':'browser';

const defaultMsg = {
	invalidUsername: '4-32 characters long. Without special characters nor spaces.',
	invalidPassword: '8-32 characters long. At least one number, one upper and one lower case. Without special characters.',
	invalidConfirmationPassword: 'The passwords don\'t match',
	invalidName: '3-32 characters long',
	invalidSurname: '3-32 characters long',
	invalidEmail: 'Invalid email',
	invalidLocation: 'You have to choose your location',
	invalidObservation: 'Max of 150 characters'
};

const RegisterPage = props => {
	const location = useLocation();
	const history = useHistory();
	const intl = useIntl();
	const [user, setUser] = useState({
		username: '',
		password: '',
		name: '',
		surname: '',
		email: '',
		cityid: null,
		stateid: null,
		countryid: null,
		userShipping: NO_SHIPPING,
		userDelivery: DELIVERY,
		avatarId: '0b6a0d46-d32d-4eb3-963b-2a952b6fdc83',
		avatarType: MTG_CODE,
		observation: ''
	});
	const [confirmationPassword, setConfirmationPassword] = useState('');
	const [acceptsTermsAndConditions, setAcceptsTermsAndConditions] = useState(false);
	const [showActionError, setShowActionError] = useState(false);
	const [actionError, setActionError] = useState(null);
	const [errors, setErrors] = useState({
		username: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidUsername', defaultMessage: defaultMsg.invalidUsername})},
		password: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidPassword', defaultMessage: defaultMsg.invalidPassword})},
		confirmationPassword: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidConfirmationPassword', defaultMessage: defaultMsg.invalidConfirmationPassword})},
		name: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidName', defaultMessage: defaultMsg.invalidName})},
		surname: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidSurname', defaultMessage: defaultMsg.invalidSurname})},
		email: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidEmail', defaultMessage: defaultMsg.invalidEmail})},
		location: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidLocation', defaultMessage: defaultMsg.invalidLocation})},
		observation: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidObservation', defaultMessage: defaultMsg.invalidObservation})}
	});

	const validateInput = (input, isValid, newErrors) => {
		newErrors[input].isInvalid = !isValid;
		return isValid;
	};

	const validateUser = () => {
		const newErrors = {...errors};
		let isValid = true;
		isValid = validateInput(
			'username',
			user.username.length >= 4
			&& user.username.length <= 32
			&& !user.username.includes(' ')
			&& user.username.match(/[^\x00-\x7F]/g) == null //non ASCII
			&& user.username.match(/[!-\/:-@[-`{-~]/g) == null, //special characters
			newErrors) && isValid;
		isValid = validateInput(
			'password',
			user.password.length >= 8
			&& user.password.length <= 32
			&& user.password === confirmationPassword
			&& user.password !== user.password.toUpperCase()
			&& user.password !== user.password.toLowerCase()
			&& user.password.match(/\d+/g) != null //has number
			&& user.password.match(/[^\x00-\x7F]/g) == null,
			newErrors) && isValid;
		isValid = validateInput(
			'confirmationPassword',
			confirmationPassword === user.password,
			newErrors) && isValid;
		isValid = validateInput(
			'name',
			user.name.length >= 3
			&& user.name.length <= 32,
			newErrors) && isValid;
		isValid = validateInput(
			'surname',
			user.surname.length >= 3
			&& user.surname.length <= 32,
			newErrors) && isValid;
		isValid = validateInput(
			'email',
			user.email.includes('@'),
			newErrors) && isValid;
		isValid = validateInput(
			'location',
			user.countryid !== null
			&& user.stateid !== null
			&& user.cityid !== null,
			newErrors) && isValid;
		setErrors(newErrors);
		return isValid;
	};

	const onSave = () => {
		setShowActionError(false);
		if(validateUser()) {
			createUser({
				...user,
				userShipping: user.userShipping.id,
				countryid: user.countryid.id,
				stateid: user.stateid.id,
				cityid: user.cityid.id
			})
				.then(response => {
					updateUrlwHistory(location, history, '/')
				})
				.catch(error => {
					setActionError(error);
					setShowActionError(true);
				});
		}else {
			setShowActionError(true);
		}
	};

	const changeLocation = (option, type) => {
		if(type === 'country') {
			setUser({...user, countryid: option, stateid: null, cityid: null});
		}else if(type === 'state') {
			setUser({...user, stateid: option, cityid: null});
		}else {
			setUser({...user, cityid: option});
		}
	};

	const changeShipping = (value) => {
		const newUser = {...user};
		newUser.userShipping = value;
		if(value.id === NO_SHIPPING.id && user.userDelivery === NO_DELIVERY) {
			newUser.userDelivery = DELIVERY;
		}
		setUser(newUser);
	};

	const changeDelivery = (value) => {
		const newUser = {...user};
		newUser.userDelivery = value;
		if(value === NO_DELIVERY && user.userShipping.id === NO_SHIPPING.id) {
			newUser.userShipping = SHIPPING_LOCAL;
		}
		setUser(newUser);
	};

	const changeObservation = (value) => {
		setUser({...user, observation: value});
		setErrors({
			...errors,
			observation: {...errors.observation, isInvalid: value.length > 150}
		});
	};

	const styles = {
		width: "100%"
	  };

    return (
        <div className={`${view}-registerPage-div`}>
				<header>
					<h2 className={`${view}-registerPage-h2`}>
						{intl.formatMessage({id: 'tittle.registerPage', defaultMessage: 'Register to CardHub'})}
					</h2>
				</header>
				<div className={`${view}-registerPage-wrap`}>
					<div className={`${view}-registerPage-inputHalfSection-div`}>
						<CHInput
							id="registerPage-username"
							className={`${view}-registerPage-input`}
							placeholder={intl.formatMessage({id: 'general.username', defaultMessage: 'Username'})}
							type="text"
							value={user.username}
							onChange={value => setUser({...user, username: value})}
							error={errors.username}
						/>
						<CHInput
							id="registerPage-password"
							className={`${view}-registerPage-input`}
							placeholder={intl.formatMessage({id: 'general.password', defaultMessage: 'Password'})}
							type="password"
							value={user.password}
							onChange={value => setUser({...user, password: value})}
							error={errors.password}
						/>
						<CHInput
							id="registerPage-confirmationPassword"
							className={`${view}-registerPage-input`}
							placeholder={intl.formatMessage({id: 'general.confirmPassword', defaultMessage: 'Confirm password'})}
							type="password"
							value={confirmationPassword}
							onChange={value => setConfirmationPassword(value)}
							error={errors.confirmationPassword}
						/>
					</div>

					<div className={`${view}-registerPage-inputHalfSection-div`}>
						<CHInput
							id="registerPage-name"
							className={`${view}-registerPage-input`}
							placeholder={intl.formatMessage({id: 'general.name', defaultMessage: 'Name'})}
							type="text"
							value={user.name}
							onChange={value => setUser({...user, name: value})}
							error={errors.name}
						/>
						<CHInput
							id="registerPage-surname"
							className={`${view}-registerPage-input`}
							placeholder={intl.formatMessage({id: 'general.surname', defaultMessage: 'Surname'})}
							type="text"
							value={user.surname}
							onChange={value => setUser({...user, surname: value})}
							error={errors.surname}
						/>
						<CHInput
							id="registerPage-email"
							className={`${view}-registerPage-input`}
							placeholder={intl.formatMessage({id: 'general.emailAddress', defaultMessage: 'Email'})}
							type="text"
							value={user.email}
							onChange={value => setUser({...user, email: value})}
							error={errors.email}
						/>
					</div>

					<div className={`${view}-registerPage-location-div registerPage-registerGroup`}>
						{errors.location.isInvalid && (
							<label className={`${view}-registerPage-error-label`}>
								{intl.formatMessage({id: 'message.invalidLocation', defaultMessage: defaultMsg.invalidLocation})}
							</label>
						)}
						<CHSelect
							id="registerPage-country"
							type="countries"
							className={`${view}-registerPage-country registerPage-registerGroup`}
							placeholder={intl.formatMessage({id: 'general.country', defaultMessage: 'Country'})}
							value={user.countryid}
							onChange={option => changeLocation(option, 'country')}
							error={{isInvalid: errors.location.isInvalid}}
						/>
						<CHSelect
							id="registerPage-state"
							type="states"
							className={`${view}-registerPage-state registerPage-registerGroup`}
							placeholder={intl.formatMessage({id: 'general.state', defaultMessage: 'Province/State'})}
							countryId={user.countryid ? user.countryid.id : 0}
							value={user.stateid}
							onChange={option => changeLocation(option, 'state')}
							error={{isInvalid: errors.location.isInvalid}}
						/>
						<CHSelect
							id="registerPage-city"
							type="cities"
							className={`${view}-registerPage-city registerPage-registerGroup`}
							placeholder={intl.formatMessage({id: 'general.city', defaultMessage: 'City'})}
							stateId={user.stateid ? user.stateid.id : 0}
							value={user.cityid}
							onChange={option => changeLocation(option, 'city')}
							error={{isInvalid: errors.location.isInvalid}}
						/>
					</div>

					<div className={`${view}-registerPage-deliveryOptions-div`}>
						<h3 className={`${view}-registerPage-h3`}>
							{intl.formatMessage({id: 'general.shippingOptions', defaultMessage: 'Delivery options'})}
						</h3>
						{getUserShippingOptions().map(option => (
							<CHCheckbox
								className={`${view}-registerPage-deliveryOptions-checkbox`}
								id={option.id}
								CHStyle="box"
								label={intl.formatMessage({id: option.label, defaultMessage: option.label})}
								checked={user.userShipping.id === option.id || (user.userShipping.value > option.value && option.value !== 0)}
								onChange={() => changeShipping({id: option.id, value: option.value})}
							/>
						))}

						<h3 className={`${view}-registerPage-h3`}>
							{intl.formatMessage({id: 'general.personalDelivery', defaultMessage: 'Personal delivery'})}
						</h3>
						<CHCheckbox
							className={`${view}-registerPage-deliveryOptions-checkbox`}
							id={"personalNo"}
							CHStyle="box"
							label={intl.formatMessage({id: 'general.no', defaultMessage: 'NO'}).toUpperCase()}
							checked={user.userDelivery === NO_DELIVERY}
							onChange={() => changeDelivery(NO_DELIVERY)}
						/>
						<CHCheckbox
							className={`${view}-registerPage-deliveryOptions-checkbox`}
							id={"personalYes"}
							CHStyle="box"
							label={intl.formatMessage({id: 'general.yes', defaultMessage: 'YES'}).toUpperCase()}
							checked={user.userDelivery === DELIVERY}
							onChange={() => changeDelivery(DELIVERY)}
						/>

						<CHTextarea
							id="registerPage-observation"
							className={`${view}-registerPage-delivaryOptions-textarea`}
							placeholder={intl.formatMessage({id: 'input.observation', defaultMessage: 'Observation...'})}
							value={user.observation}
							onChange={value => changeObservation(value)}
							error={errors.observation}
						/>
					</div>

					<div className={`${view}-registerPage-termsAndConditions-div`}>
						<h3 className={`${view}-registerPage-h3`} >
							{intl.formatMessage({id: 'message.doYouAcceptTheTermsAndConditions', defaultMessage: 'Do you accept the terms and conditions? Did you read them? Really?'})}
						</h3>
						<div className={`${view}-registerPage-termsAndConditions-input-div`}>
							{intl.formatMessage({id: 'general.termsAndConditions', defaultMessage: 'Terms and conditions'})}
							<CHCheckbox
								className={`${view}-registerPage-termsAndConditions-checkbox`}
								id={"Acept"}
								CHStyle="box"
								label={intl.formatMessage({id: 'general.iAccept', defaultMessage: 'I agree'})}
								checked={acceptsTermsAndConditions}
								onChange={() => setAcceptsTermsAndConditions(!acceptsTermsAndConditions)}
							/>
						</div>
					</div>
					<CHError
						id="registerPage-error"
						isShown={showActionError}
						error={actionError}
					/>
					<CHButton
						className={`${view}-registerPage-submitButtom`}
						label={intl.formatMessage({id: 'general.send', defaultMessage: 'Send'})}
						CHStyle="accept"
						onClick={() => onSave()}
						disabled={!acceptsTermsAndConditions && errors.observation.isInvalid}
					/>
				</div>
        </div>
    );
};

export default RegisterPage;
