import React, { Fragment, useRef, useState } from 'react';
import './ObservationColumn.css';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

import Icon from '../Icon';

const ObservationCellContent = props => {
	const {observation, userObservation} = props;
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const spanRef = useRef(null);

	return (
		<Fragment>
			<span ref={spanRef} className="observationColumn-span">
				<Icon
					type="other"
					name="chatBubble"
					width="20"
					height="20"
				/>
			</span>
			<Tooltip
				placement="top"
				isOpen={isTooltipOpen}
				target={spanRef}
				toggle={() => setIsTooltipOpen(!isTooltipOpen)}
			>
				{observation}
				{userObservation && (
					<Fragment>
						<br/>
						---
						<br/>
						{userObservation}
					</Fragment>
				)}
			</Tooltip>
		</Fragment>
	);
};

ObservationCellContent.propTypes = {
	observation: PropTypes.string,
	userObservation: PropTypes.string
};

export {
	ObservationCellContent
};
