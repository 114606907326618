import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DEFAULT_MSG_ACTIONERROR } from '../../utils/constants';
import { logoutUser } from '../../redux/actions/sessionActions';
import { clearUser } from '../../utils/localStorageManager';

const CHErrorMessage = props => {
	const { error, altMessage } = props;
	const intl = useIntl();
	const dispatch = useDispatch();
	const history = useHistory();
	const [message, setMessage] = useState('');

	const processError = () => {
		const code = error ? error.status : 0;
		const errorMessage = error ? error.data : '';
		if([400, 500].includes(code)) {
			setMessage(intl.formatMessage({id: 'message.actionError', defaultMessage: DEFAULT_MSG_ACTIONERROR}));
		}else if(code === 401) {
			setMessage('Invalid token');
			clearUser();
			dispatch(logoutUser());
			history.replace('/');
		}else if(code === 403) {
			setMessage(intl.formatMessage({id: 'error.forbiddenAction', defaultMessage: 'You don\'t have permission to do this action'}));
		}else if(code === 406) {
			setMessage(intl.formatMessage({id: errorMessage, defaultMessage: DEFAULT_MSG_ACTIONERROR}));
		}else {
			setMessage(altMessage
				? altMessage
				: intl.formatMessage({id: 'message.actionError', defaultMessage: DEFAULT_MSG_ACTIONERROR})
			);
		}
	};

	useEffect(processError, [error, altMessage]);

	return (
		<Fragment>
			{message}
		</Fragment>
	);
};

CHErrorMessage.propTypes = {
	// axios error: {status: number, data: string | any, ...}
	error: PropTypes.any,
	altMessage: PropTypes.string
};

export default CHErrorMessage;
