import React, {useState, useEffect} from 'react';
import './CardSearcher.css';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

import Select from 'react-select';

const axios = require('axios');
const scryfall = require('../api/scryfallApi');

const CardSearcher = (props) => {
	const intl = useIntl();
	const {className, onCardSelected, inputValue, onInputChange, isClearable} = props;
	const [query, setQuery] = useState('');
	const [cancelSource, setCancelSource] = useState(axios.CancelToken.source());
	const [options, setOptions] = useState([]);

	const init = () => {
		inputValue && setQuery(inputValue);
	};

	useEffect(init, []);

	function handleInputChange(event) {
		onInputChange && onInputChange(event);
		const newCancelSource = axios.CancelToken.source();
		cancelSource.cancel();
		setCancelSource(newCancelSource);
		setQuery(event);
		setOptions([{value: event, label: event}].concat(options));
		scryfall.autocomplete(event, newCancelSource.token)
			.then(response => setOptions([{value: event, label: event}].concat(response)))
			.catch(() => {});
	}
	
	function handleSelect(event) {
		setOptions([]);
		const selectedValue = event ? event.value : '';
		setQuery(selectedValue);
		onCardSelected(selectedValue);
	}
	
	return (
		<Select
			className={'cardSearcher-autocomplete' + (className ? ` ${className}` : '')}
			closeMenuOnSelect={false}
			noOptionsMessage={() => null}
			isClearable={isClearable}
			placeholder={intl.formatMessage({id: 'select.card', defaultMessage: 'Search card...'})}
			inputValue={query}
			options={options}
			onInputChange={handleInputChange}
			onChange={handleSelect}
		/>
	);
};

CardSearcher.propTypes = {
	// onCardSelected(value)
	onCardSelected: PropTypes.func.isRequired,
	inputValue: PropTypes.string,
	onInputChange: PropTypes.func,
	isClearable: PropTypes.bool,
	className: PropTypes.string
};

export default CardSearcher;
