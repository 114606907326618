import React, {useState} from 'react';
import './SaleDeleteModal.css';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import { isMobile } from 'react-device-detect';

import CHModal from '../../../../components/CHComponents/CHModal';
import CHModalHeader from '../../../../components/CHComponents/CHModalHeader';
import CHModalBody from '../../../../components/CHComponents/CHModalBody';
import CHModalFooter from '../../../../components/CHComponents/CHModalFooter';
import CHError from '../../../../components/CHComponents/CHError';
import { getCardTypeById, getCardConditionById } from '../../../../utils/enumsService';
import { deleteCardSale } from '../../../../api/mtgSalesServiceApi';

const SaleDeleteModal = props => {
	const intl = useIntl();
	const {isOpen, onClose, onAccept, cardSale} = props;
	const [showActionError, setShowActionError] = useState(false);
	const [actionError, setActionError] = useState(null);

	const onDelete = () => {
		setShowActionError(false);
		deleteCardSale(cardSale.id)
			.then(() => {
				setShowActionError(false);
				onAccept();
			})
			.catch((error) => {
				setActionError(error);
				setShowActionError(true);
			});
	};

	const closeModal = () => {
		setShowActionError(false);
		onClose();
	};

	return (
		<CHModal
			isOpen={isOpen}
			toggle={closeModal}
		>
			<CHModalHeader
				tittle={intl.formatMessage({id: 'tittle.deleteSale', defaultMessage: 'Delete sale'})}
			/>
			<CHModalBody>
				<p className={(isMobile?"mobile-":"browser-")+"UserSalesTDeleteModal-title"}>
					{intl.formatMessage({
							id: 'message.deleteSale',
							defaultMessage: 'Are you sure you want to delete this sale'
						}
					)}
				</p>
				<div className={(isMobile?"mobile-":"browser-")+"UserSalesTDeleteModal-info-div"}>
					<label className="UserSalesTDeleteModal-info-label">
						{intl.formatMessage({id: 'game.card', defaultMessage: 'Card'})}:
					</label>{cardSale && cardSale.cardName}<br/>
					<label className="UserSalesTDeleteModal-info-label">
						{intl.formatMessage({id: 'game.set', defaultMessage: 'Set'})}:
					</label>{cardSale && cardSale.cardSet}<br/>
					<label className="UserSalesTDeleteModal-info-label">
						{intl.formatMessage({id: 'game.type', defaultMessage: 'Type'})}:
					</label>{getCardTypeById(cardSale && cardSale.cardType).label}<br/>
					<label className="UserSalesTDeleteModal-info-label">
						{intl.formatMessage({id: 'game.condition', defaultMessage: 'Condition'})}:
					</label>{getCardConditionById(cardSale && cardSale.cardCondition).label}<br/>
				</div>
				<CHError
					id="UserSalesTDeleteModal-error"
					isShown={showActionError}
					error={actionError}
				/>
			</CHModalBody>
			<CHModalFooter
				type="save"
				acceptLabel={intl.formatMessage({id: 'general.delete', defaultMessage: 'Delete'})}
				onAccept={onDelete}
				acceptStyle="warning"
			/>
		</CHModal>
	);
};

SaleDeleteModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onAccept: PropTypes.func.isRequired,
	cardSale: PropTypes.shape({
		id: PropTypes.number,
		user: PropTypes.number,
		cardId: PropTypes.string,
		cardName: PropTypes.string,
		cardSet: PropTypes.string,
		cardType: PropTypes.number,
		cardLanguage: PropTypes.number,
		cardCondition: PropTypes.number,
		quantity: PropTypes.number,
		price: PropTypes.number,
		cardTradeable: PropTypes.number,
		observation: PropTypes.string,
		cardSaleStatus: PropTypes.number
	})
};

SaleDeleteModal.defaultProps = {
	cardSale: null
};

export default SaleDeleteModal;