import React, { Fragment } from 'react';
import './CHButtonDropdown.css';
import PropTypes from 'prop-types';
import {ButtonDropdown, DropdownToggle, DropdownMenu} from 'reactstrap';

import CHCheckbox from './CHCheckbox';
import CHSelect from './CHSelect';
import CHButton from './CHButton';
import Icon from '../Icon';
import { isMobile } from 'react-device-detect';

const view= isMobile? "mobile":"browser";

const CHButtonDropdown = props => {
	const {id, isOpen, toggle, label, type, countryId, stateId, disabled, dropdownMenuRight,
		content, onChange, onClick, selectValue, selectAllOption, selectIsMulti, selectIsClearable,
		CHStyle, className, buttonClassName, dropdownClassName, maxMenuHeight
	} = props;

	const getButtonStyles = () => {
		let styles = 'CHButtonDropdown-button-base shadow-none';
		styles += CHStyle ? ` CHButtonDropdown-button-${CHStyle}` : ' CHButtonDropdown-button-brand';
		if(buttonClassName) {
			styles += ` ${buttonClassName}`;
		}
		if(type === 'split') {
			styles += ' CHButtonDropdown-split-toggle';
		}
		if(disabled) {
			styles += ` CHButtonDropdown-disabled`;
		}
		return styles;
	};

	const getDropdownStyles = () => {
		let styles = 'CHButtonDropdown-dropdown-base';
		styles += CHStyle ? ` CHButtonDropdown-dropdown-${CHStyle}` : ' CHButtonDropdown-dropdown-brand';
		if(dropdownClassName) {
			styles += ` ${dropdownClassName}`;
		}
		if(maxMenuHeight) {
			styles += ` ${view}-dropdownMenu-${maxMenuHeight}`;
		}
		return styles;
	};

	const renderMenu = () => {
		let dropdownMenu;
		if(type === 'menu' || type === 'split') {
			const menuItemStyles = 'CHButtonDropdown-menuItem-base' + (CHStyle ? ` CHButtonDropdown-menuItem-${CHStyle}` : ' CHButtonDropdown-menuItem-brand');
			dropdownMenu = content.map(item => (
				<div key={item.id} className={menuItemStyles} onClick={item.onClick}>
					{item.label}
				</div>
			));
		}else if(type === 'checkbox') {
			const checkboxItemStyles = 'CHButtonDropdown-checkboxItem-base' + (CHStyle ? ` CHButtonDropdown-checkboxItem-${CHStyle}` : ' CHButtonDropdown-checkboxItem-brand');
			dropdownMenu = content.map(item => (
				<div key={item.id} className={checkboxItemStyles}>
					<CHCheckbox
						className="CHButtonDropdown-checkbox-base"
						id={item.id}
						value={item.value}
						label={item.label}
						checked={item.checked}
						onChange={onChange}
					/>
				</div>
			));
		}else if(type === 'select') {
			dropdownMenu = (
				<CHSelect
					id={`${id}-select`}
					options={content}
					onChange={onChange}
					value={selectValue}
					allOption={selectAllOption}
					isMulti={selectIsMulti}
					isClearable={selectIsClearable}
					className="CHButtonDropdown-select-base"
				/>
			);
		}else if(['countries', 'states', 'cities'].includes(type)) {
			dropdownMenu = (
				<CHSelect
					id={`${id}-select`}
					type={type}
					countryId={countryId}
					stateId={stateId}
					onChange={onChange}
					value={selectValue}
					allOption={selectAllOption}
					className="CHButtonDropdown-select-base"
					maxMenuHeight= 'small'
				/>
			);
		}else {
			dropdownMenu = content;
		}
		return dropdownMenu;
	};

	return (
		<ButtonDropdown
			id={id}
			className={`ButtonDropdown ${className ? className : ''}`}
			isOpen={isOpen}
			toggle={toggle}
		>
			{type === 'split' ?
				(
					<Fragment>
						<CHButton
							id={`${id}-button`}
							className="CHButtonDropdown-split-button"
							CHStyle={CHStyle}
							label={label}
							onClick={onClick}
						/>
						<DropdownToggle className={getButtonStyles()}>
							<Icon type="other" name="desc" width="24" height="24"/>
						</DropdownToggle>
					</Fragment>
				) : (
					<DropdownToggle className={getButtonStyles()}>
						{label}
					</DropdownToggle>
				)
			}
			<DropdownMenu className={getDropdownStyles()} right={dropdownMenuRight}>
				{renderMenu()}
			</DropdownMenu>
		</ButtonDropdown>
	);
};

CHButtonDropdown.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	label: PropTypes.node.isRequired,
	type: PropTypes.oneOf(['menu', 'checkbox', 'select', 'countries', 'states', 'cities', 'split']),
	// true: left direction, false: right direction
	dropdownMenuRight: PropTypes.bool,
	countryId: PropTypes.number,
	stateId: PropTypes.number,
	// type = menu/checkbox, onChange(event)
	// type = select/location, onChange({id, value, label})
	onChange: PropTypes.func,
	// type = split, onClick()
	onClick: PropTypes.func,
	selectValue: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		label: PropTypes.node
	}),
	selectAllOption: PropTypes.oneOf(['M', 'F', 'any']),
	selectIsMulti: PropTypes.bool,
	selectIsClearable: PropTypes.bool,
	CHStyle: PropTypes.oneOf(['brand', 'transparent']),
	className: PropTypes.string,
	maxMenuHeight: PropTypes.oneOf(['small', 'medium', 'large']),
	buttonClassName: PropTypes.string,
	dropdownClassName: PropTypes.string,
	content: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.oneOfType([
			// type = menu
			PropTypes.shape({
				id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				label: PropTypes.node,
				onClick: PropTypes.func
			}),
			// type = checkbox
			PropTypes.shape({
				id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				label: PropTypes.node,
				checked: PropTypes.bool
			}),
			// type = select
			PropTypes.shape({
				id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				label: PropTypes.node
			})
		])),
		// any type
		PropTypes.node
	])
};

export default CHButtonDropdown;