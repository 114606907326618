import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import { CARD_TRADEABLE, CARD_NONTRADEABLE } from '../../utils/constants';

const AcceptsTradeCellContent = props => {
	const {acceptsTrade} = props;

	return (
		<Icon
			type="other"
			name={acceptsTrade === CARD_NONTRADEABLE ? 'cross' : 'checkMark'}
			width="20"
			height="20"
		/>
	);
};

AcceptsTradeCellContent.propTypes = {
	acceptsTrade: PropTypes.oneOf([CARD_TRADEABLE, CARD_NONTRADEABLE]).isRequired
};

export {
	AcceptsTradeCellContent
};