
const ALL_OPTION = {
	id: 'general_all',
	label: 'All',
	value: null
};
const ALL = 'general_all';
const ALL_M = 'general.allM';
const ALL_F = 'general.allF';

const NO_SHIPPING = {id: 'general_noShipping', value: 0};
const SHIPPING_LOCAL = {id: 'general_local', value: 1};
const DELIVERY = 'general_delivery';
const NO_DELIVERY = 'general_noDelivery';

const CARD_TRADEABLE = 'general_tradeable';
const CARD_NONTRADEABLE = 'general_nonTradeable';

const CARD_SALESTATUS_CLOSED = 'general_openF';
const CARD_SALESTATUS_PAUSED = 'general_pausedF';

const USERSTATUS_ACTIVE = 'active';
const USERSTATUS_SUSPENDED = 'suspended';

const CARD_TYPE_PROMO = 'promo';
const CARD_TYPE_FOIL = 'foil';
const CARD_TYPE_NONFOIL = 'nonFoil';

const SALEHISTORY_IN_CART = 'general_inCart';
const SALEHISTORY_PENDING = 'general_pending';
const SALEHISTORY_CONFIRMEDF = 'general_confirmedF';
const SALEHISTORY_PENDING_CLOSURE = 'general_pendingClosure';
const SALEHISTORY_FINISHEDF = 'general_finishedF';
const SALEHISTORY_CANCELEDF = 'general_canceledF';


// As in back
const MTG_CODE = 'MTG';

// As in url
const GAME_MTG = 'mtg';
const GAME_PKM = 'pkm';
const GAME_YGO = 'ygo';

const DEFAULT_MSG_ACTIONERROR = 'An error has ocurred while doing the action';

export {
	ALL_OPTION, ALL, ALL_F, ALL_M,
	NO_SHIPPING, SHIPPING_LOCAL,
	NO_DELIVERY, DELIVERY,
	CARD_TRADEABLE, CARD_NONTRADEABLE,
	CARD_SALESTATUS_CLOSED, CARD_SALESTATUS_PAUSED,
	USERSTATUS_ACTIVE, USERSTATUS_SUSPENDED,
	CARD_TYPE_FOIL, CARD_TYPE_NONFOIL, CARD_TYPE_PROMO,
	SALEHISTORY_IN_CART, SALEHISTORY_PENDING, SALEHISTORY_CONFIRMEDF,
	SALEHISTORY_PENDING_CLOSURE, SALEHISTORY_FINISHEDF, SALEHISTORY_CANCELEDF,
	MTG_CODE,
	GAME_MTG, GAME_PKM, GAME_YGO,
	DEFAULT_MSG_ACTIONERROR
};
