/*
* filters and columns should always be copy of original state
*/

import { DELIVERY, NO_DELIVERY } from './constants';

/*
* filters: {
* 	filterName/columnLabel: {
* 		columnId: number,
* 		optionsSelected: [any]
* 	}
* }
* columnId: number
* filterName: string
* optionValue: any
* isMultiSelect: bool
*/
const addFilter = (filters, columnId, filterName, optionValue, isMultiSelect = false) => {
	if(isMultiSelect) {
		filters[filterName] = {
			columnId,
			optionsSelected: optionValue
		};
	}else if(!filters[filterName]) {
		filters[filterName] = {
			columnId,
			optionsSelected: [optionValue]
		};
	}else {
		filters[filterName].optionsSelected.push(optionValue);
	}
	return filters;
};

/*
* filters: {
* 	filterName/columnLabel: {
* 		columnId: number,
* 		optionsSelected: [any]
* 	}
* }
* filterName: string
* optionValue: any
*/
const removeFilter = (filters, filterName, optionValue) => {
	const index = filters[filterName].optionsSelected.indexOf(optionValue);
	filters[filterName].optionsSelected.splice(index, 1);
	if(filters[filterName].optionsSelected.length === 0) {
		delete filters[filterName];
	}
	return filters;
};

/*
* filters: {
* 	filterName/columnLabel: {
* 		columnId: number,
* 		optionsSelected: [any]
* 	}
* }
* filterName: string
*/
const clearFilters = (filters, filterName) => {
	if(filters[filterName]) {
		delete filters[filterName];
	}
	return filters;
};

/*
* filters: {
* 	filterName/columnLabel: {
* 		columnId: number,
* 		optionsSelected: [any]
* 	}
* }
* columns: Columns
* event: htmlEvent
* columnId: number
* columnLabel: string
*/
const onCheckboxFilter = (filters, columns, event, columnId, columnLabel) => {
	let newFilters = {};
	const optionIndex = columns[columnId].filter.options.findIndex(o =>
		o.value === event.target.value || o.value.toString() === event.target.value);
	columns[columnId].filter.options[optionIndex].checked = event.target.checked;
	if(event.target.checked) {
		newFilters = addFilter(filters, columnId, columnLabel, event.target.value);
	}else {
		newFilters = removeFilter(filters, columnLabel, event.target.value);
	}
	return {
		filters: newFilters,
		columns
	};
};


/*
* filters: {
* 	filterName/columnLabel: {
* 		columnId: number,
* 		optionsSelected: [any]
* 	}
* }
* columns: Columns
* locationColumns: {country: columnId, state: columnId, city: columnId}
* columnId: number
* columnLabel: string
*/
const onLocationFilter = (filters, columns, locationColumns, locationOption, columnId, columnLabel) => {
	let newFilters;
	const locationId = locationOption ? locationOption.id : -1;
	newFilters = clearFilters(filters, columnLabel);
	if(columnId === locationColumns.country) {
		columns[locationColumns.country].filter.selectValue = locationOption;
		columns[locationColumns.state].filter.countryId = locationId;
		columns[locationColumns.state].filter.selectValue = null;
		columns[locationColumns.city].filter.stateId = 0;
		columns[locationColumns.city].filter.selectValue = null;
		newFilters = clearFilters(newFilters, columns[locationColumns.state].label);
		newFilters = clearFilters(newFilters, columns[locationColumns.city].label);
	}else if(columnId === locationColumns.state) {
		columns[locationColumns.state].filter.selectValue = locationOption;
		columns[locationColumns.city].filter.stateId = locationId;
		columns[locationColumns.city].filter.selectValue = null;
		newFilters = clearFilters(newFilters, columns[locationColumns.city].label);
	}else {
		columns[locationColumns.city].filter.selectValue = locationOption;
	}
	if(locationId !== 0) {
		newFilters = addFilter(newFilters, columnId, columnLabel, locationOption.id);
	}
	return {
		columns,
		filters: newFilters
	};
};

/*
* filters: {
* 	filterName/columnLabel: {
* 		columnId: number,
* 		optionsSelected: [any]
* 	}
* }
* columns: Columns
* selectedOptions: [{id: string, value: string/number, label: string}]
* deliveryMethods: [{id: string, value: string/number, label: string}] //getDeliveryMethods()
* columnId: number
* columnLabel: string
*/
const onDeliveryMethodFilter = (filters, columns, selectedOptions, deliveryMethods, columnId, columnLabel) => {
	let newFilters;
	const selected = [];
	if(selectedOptions) {
		deliveryMethods.forEach(deliveryMethod => {
			const isSelected = selectedOptions.find(o =>
				o.id === deliveryMethod.id
				|| (deliveryMethod.id !== DELIVERY && deliveryMethod.value < o.value)
			);
			if(isSelected) {
				selected.push(deliveryMethod);
			}
		});
	}
	newFilters = addFilter(filters, columnId, columnLabel, selected, true);
	columns[columnId].filter.selectValue = selected;
	return {
		columns,
		filters: newFilters
	};
};

// TODO remover logica una vez confirmado que no se va a usar
const validateDeliveryMethods = (cellValues, deliveryFilters) => {
	if(!deliveryFilters || deliveryFilters.length === 0) {
		return true;
	}
	for(let filterMethod of deliveryFilters) {
		for(let value of cellValues) {
			if(
				value.id === filterMethod.id
				||
				(
					(value.id !== DELIVERY && value.id !== NO_DELIVERY)
					&& value.value >= filterMethod.value
				)
			) {
				return true;
			}
		}
	}
	return false;
};

/*
* rows: Rows
* filters: {
* 	filterName/columnLabel: {
* 		columnId: number,
* 		optionsSelected: [any]
* 	}
* }
* multiSelectColumns: [number]
* deliveryMethodsColumn: number
*/
const filterRows = (rows, filters, multiValueColumns = [], deliveryMethodsColumn = -1) => {
	const filtersKeys = Object.keys(filters);
	return rows.reduce((result, row) => {
		for(let filterKey of filtersKeys) {
			if(filters[filterKey].columnId === deliveryMethodsColumn) {
				if(!validateDeliveryMethods(row.cells[filters[filterKey].columnId].value, filters[filterKey].optionsSelected)) {
					return result;
				}
			}else if(multiValueColumns.includes(filters[filterKey].columnId)) {
				let hasAnyValue = false;
				for(let value of filters[filterKey].optionsSelected) {
					if (row.cells[filters[filterKey].columnId].value.includes(value)) {
						hasAnyValue = true;
					}
				}
				if(!hasAnyValue) {
					return result;
				}
			}else if(!filters[filterKey].optionsSelected.includes(row.cells[filters[filterKey].columnId].value)) {
				return result;
			}
		}
		result.push(row);
		return result;
	}, []);
};

export {
	addFilter,
	removeFilter,
	clearFilters,
	onCheckboxFilter,
	onLocationFilter,
	onDeliveryMethodFilter,
	filterRows
};
