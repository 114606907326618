import React, { useState, useEffect, Fragment } from 'react';
import './CHSelect.css';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import { Tooltip } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import { getCountries, getStates, getCities } from '../../api/locationsServiceApi';
import { ALL_OPTION, ALL } from '../../utils/constants';
import {isMobile} from 'react-device-detect';

const browserMenuHeights = {
	small: 108,
	medium: 216,
	large: 324
};
const mobileMenuHeights = {
	small: 60,
	medium: 108,
	large: 216
};

const viewMenuHeights = isMobile ? mobileMenuHeights : browserMenuHeights;

const CHSelect = props => {
	const {id, options, onChange, value, placeholder,
		CHStyle, className, disabled, isClearable, isMulti, error,
		type, countryId, stateId, allOption,maxMenuHeight, menuFixed} = props;
	const intl = useIntl();
	const [optionsToRender, setOptionsToRender] = useState([]);
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	const getAllOption = (anyType = 'M', id = ALL) => ({
		...ALL_OPTION,
		id: id,
		label: intl.formatMessage({id: `general.all${allOption === 'any' ? anyType : allOption}`, defaultMessage: 'All'})
	});

	const updateOptions = () => {
		let mounted = true;
		const cancelSource = axios.CancelToken.source();
		if(type === 'countries') {
			getCountries(cancelSource.token)
				.then(countries => {
					if(mounted) {
						const countriesOptions = countries.map(country => ({id: country.id,	value: country.id, label: country.name}));
						allOption && countriesOptions.unshift(getAllOption('M', 0));
						setOptionsToRender(countriesOptions);
					}
				})
				.catch(error => {
					if(mounted && allOption) {
						setOptionsToRender([getAllOption('M', 0)]);
					}
				});
		}else if(type === 'states') {
			if(countryId && countryId > 0) {
				getStates(countryId, cancelSource.token)
					.then(states => {
						if (mounted) {
							const statesOptions = states.map(state => ({id: state.id, value: state.id, label: state.name}));
							allOption && statesOptions.unshift(getAllOption('F', 0));
							setOptionsToRender(statesOptions);
						}
					})
					.catch(error => {
						if(mounted && allOption) {
							setOptionsToRender([getAllOption('F', 0)]);
						}
					});
			}else {
				if(mounted && allOption) {
					setOptionsToRender([getAllOption('F', 0)]);
				}
			}
		}else if(type === 'cities') {
			if(stateId && stateId > 0) {
				getCities(stateId, cancelSource.token)
					.then(cities => {
						if(mounted) {
							const citiesOptions = cities.map(city => ({id: city.id, value: city.id, label: city.name}));
							allOption && citiesOptions.unshift(getAllOption('F', 0));
							setOptionsToRender(citiesOptions);
						}
					})
					.catch(error => {
						if(mounted && allOption) {
							setOptionsToRender([getAllOption('F', 0)]);
						}
					});
			}else {
				if(mounted && allOption) {
					setOptionsToRender([getAllOption('F', 0)]);
				}
			}
		}else {
			const newOptions = [...options];
			allOption && newOptions.unshift(getAllOption());
			setOptionsToRender(newOptions);
		}

		return () => {
			mounted = false;
			cancelSource.cancel('Cancelling on unmount');
		};
	};

	useEffect(updateOptions, [options, countryId, stateId]);

	const getSelectStyles = () => {
		let styles = 'CHSelect-select-base';
		styles += CHStyle ? ` CHSelect-select-${CHStyle}` : ' CHSelect-select-brand';
		if(className) {
			styles += ` ${className}`;
		}
		if(error && error.isInvalid) {
			styles += ' CHInput-error';
		}
		if(disabled) {
			styles += ` CHSelect-disabled`;
		}
		if(isMobile) {
			styles += ` CHSelect-mobile`;
		}
		return styles;
	};

	const toggleTooltip = () => {
		setIsTooltipOpen(
			!isTooltipOpen
			&& error.isInvalid
			&& error.message
			&& error.message.length > 0
		);
	};

	return (
		<Fragment>
			<Select
				id={id}
				isMulti={isMulti}
				className={getSelectStyles()}
				isClearable={isClearable}
				noOptionsMessage={() => null}
				maxMenuHeight={ maxMenuHeight  ? viewMenuHeights[maxMenuHeight] : viewMenuHeights.medium}
				placeholder={placeholder ? placeholder : 'Seleccionar...'}
				options={optionsToRender}
				value={value ? value : (allOption ? optionsToRender[0] : null)}
				onChange={onChange}
				isDisabled={disabled}
				menuPosition={menuFixed ? 'fixed' : 'absolute'}
			/>
			{error && (
				<Tooltip
					innerClassName="CHSelect-error-tooltip"
					hideArrow
					placement="top"
					isOpen={isTooltipOpen}
					target={id}
					toggle={toggleTooltip}
				>
					{error ? error.message : ''}
				</Tooltip>
			)}
		</Fragment>
	);
};

CHSelect.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	options: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // accepts null
		label: PropTypes.node.isRequired
	})),
	// onChange(option: {id, value, label})
	// isMulti = onChange(options: [{id, value, label}])
	onChange: PropTypes.func.isRequired,
	allOption: PropTypes.oneOf(['M', 'F', 'any']),
	value: PropTypes.oneOfType([
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // accepts null
			label: PropTypes.node.isRequired
		}),
		PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			label: PropTypes.node.isRequired
		}))
	]),
	type: PropTypes.oneOf(['countries', 'states', 'cities']),
	countryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	stateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	isClearable: PropTypes.bool,
	isMulti: PropTypes.bool,
	maxMenuHeight: PropTypes.oneOf(['small', 'medium', 'large']),
	menuFixed: PropTypes.bool,
	CHStyle: PropTypes.oneOf(['brand']),
	className: PropTypes.string,
	error: PropTypes.shape({
		isInvalid: PropTypes.bool.isRequired,
		message: PropTypes.string
	})
};

CHSelect.defaultProps = {
	value: null,
	allOption: null,
};

export default CHSelect;
