import React, { useState, useEffect, Fragment } from 'react';
import './CartButton.css';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import CHButtonDropdown from '../../components/CHComponents/CHButtonDropdown';
import CHButton from '../../components/CHComponents/CHButton';
import Icon from '../../components/Icon';
import SaleHistoryCartEditor from '../../components/SaleHistoryCartEditor';
import { fetchCart } from '../../redux/actions/sessionActions';
import { updateUrlwHistory } from '../../utils/urlManager';

const cartSelector = store => store.session.cart;

const view = isMobile?'mobile':'browser';

const CartButton = props => {
	const location = useLocation();
	const history = useHistory();
	const intl = useIntl();
	const dispatch = useDispatch();
	const cart = useSelector(cartSelector);
	const [isOpen, setIsOpen] = useState(false);
	const [isEmpty, setIsEmpty] = useState(true);
	const [isEditionDisabled, setIsEditionDisabled] = useState(false);

	useEffect(() => setIsEmpty(!cart || cart.length === 0), [cart]);

	const onEdition = () => {
		setIsEditionDisabled(false);
		dispatch(fetchCart());
	};

	const renderCartEntry = (saleHistory) => {
		return (
			<div className={`cartButton-entry`} key={`cartButton-entry-${saleHistory.id}`}>
				<div className={`cartButton-entry-left`}>
					<label className={`cartButton-entry-label cartButton-entry-cardName`}>
						{saleHistory.cardSale.cardName}
					</label>
					<label className={`cartButton-entry-label cartButton-entry-cardSet`}>
						{saleHistory.cardSale.cardSet}
					</label>
				</div>
				<SaleHistoryCartEditor
					className={`cartButton-entry-right`}
					saleHistoryId={saleHistory.id}
					saleHistoryQuantity={saleHistory.quantity}
					cardSaleQuantity={saleHistory.cardSale.quantity}
					isDisabled={isEditionDisabled}
					enableEdition={() => setIsEditionDisabled(false)}
					disableEdition={() => setIsEditionDisabled(true)}
					onEdition={onEdition}
				/>
			</div>
		);
	};

	return (
		<CHButtonDropdown
			id="cart-button"
			CHStyle="transparent"
			className={`${view}-cartButton-div`}
			buttonClassName={`cartButton-button`}
			dropdownClassName={`cartButton-dropdownMenu`}
			isOpen={isOpen}
			toggle={() => setIsOpen(!isOpen)}
			dropdownMenuRight
			label={
				<Icon name="mailbox" type="file" className={`${view}-cartButton-mailbox`}/>
			}
			content={(
				<Fragment>
					<div className={`cartButton-entries-section`}>
						{isEmpty
							? intl.formatMessage({id: 'message.emptyCart', defaultMessage: 'Purchase\'s mailbox is empty'})
							: cart.map(saleHistory => renderCartEntry(saleHistory))
						}
					</div>
					<CHButton
						id="cartButton-confirm-button"
						className={`cartButton-confirmButton`}
						label={intl.formatMessage({id: 'message.goToConfirmation', defaultMessage: 'Go to confirmation'})}
						onClick={() => updateUrlwHistory(location, history,'/cart')}
					/>
				</Fragment>
			)}
		/>
	);
};

export default CartButton;
