/*
* Scryfall API calls configuration.
*/

import { areCardNamesEqual } from "../utils/stringParser";

const encodeUrl = require('encodeurl');
const axios = require('axios');
const scryfall = axios.create({
	baseURL: 'https://api.scryfall.com',
	timeout: 15000
});


/* ---- INTERCEPTORS ---- */

const interceptResponse = (response) => {
	return response;
};

const interceptError = (error) => {
	if(axios.isCancel(error)) {
		console.log('Request was canceled');
	}else if(error.response && error.response.status === 429) {
		console.error('---- WARNING ----\nCODE 429: TOO MANY SCRYFALL REQUESTS');
	}
	return error;
};

scryfall.interceptors.response.use(interceptResponse, interceptError);


/* ---- ENDPOINTS ---- */

const getCardByName = async (name, cancelToken, searchAlternative = false) => {
	if(name.length === 0) return [];
	const response = await scryfall.get(`/cards/search?q=${encodeUrl(name)}&unique=prints&order=released&dir=desc`, {cancelToken: cancelToken});
	let prints = response.data.data.reduce((result, print) => {
		if (areCardNamesEqual(name, print.name) && print.games.includes('paper')) {
			result.push(print);
		}
		return result;
	}, []);
	if(searchAlternative && prints.length === 0 && response.data.data.length > 0) {
		prints = await getCardByName(response.data.data[0].name, cancelToken);
	}else if (searchAlternative && prints.length === 0 && response.data.data.length === 0) {
		const options = await autocomplete(name, cancelToken);
		if(options.length === 0) {
			prints = [];
		}else {
			prints = await getCardByName(options[0].value, cancelToken);
		}
	}
	return prints;
};

const getCardById = async (id, cancelToken) => {
	const response = await scryfall.get(`/cards/${id}`, {cancelToken});
	return response.data;
};

// imageType: small | normal | large | png | art_crop | border_crop
const getCardImageUrl = async (id, imageType) => {
	const print = await getCardById(id);
	return print.image_uris ? print.image_uris[imageType] : print.card_faces[0].image_uris[imageType];
};

const autocomplete = async (query, cancelToken) => {
	if(query.length <= 1) {
		return [];
	}else {
		const response = await scryfall.get(`/cards/autocomplete?q=${encodeUrl(query)}`, {cancelToken});
		return response.data.data.map(option => ({value: option, label: option})).splice(0, 5);
	}
};

export { getCardByName, getCardById, autocomplete, getCardImageUrl };
