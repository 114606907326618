import React, { useEffect, useState } from 'react';
import './WishlistFormModal.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';

import CHModal from "../../components/CHComponents/CHModal";
import CHModalBody from "../../components/CHComponents/CHModalBody";
import CHModalFooter from "../../components/CHComponents/CHModalFooter";
import CHModalHeader from "../../components/CHComponents/CHModalHeader";
import CHSelect from '../../components/CHComponents/CHSelect';
import {
	getLanguagesOptions,
	getCardConditionsOptions,
	getDeliveryMethods
} from '../../utils/enumsService';
import { createCardWish } from '../../api/mtgSalesServiceApi';
import { getUserId } from '../../utils/localStorageManager';
import {
	ALL_OPTION,
	NO_SHIPPING, NO_DELIVERY, DELIVERY
} from '../../utils/constants';
import CHError from "../../components/CHComponents/CHError";


const WishlistFormModal = props => {
	const intl = useIntl();
	const {isOpen, onClose, onAccept, card, cardOptions} = props;
	const allOptionF = {...ALL_OPTION, label: intl.formatMessage({id: 'general.allF', defaultMessage: 'All'})};
	const allOptionM = {...ALL_OPTION, label: intl.formatMessage({id: 'general.allM', defaultMessage: 'All'})};
	const [deliveryMethods, setDeliveryMethods] = useState([]);
	const [showActionError, setShowActionError] = useState(false);
	const [actionError, setActionError] = useState(null);
	const [filters, setFilters] = useState({
		set: allOptionF,
		type: allOptionM,
		condition: allOptionF,
		language: allOptionM,
		country: null,
		state: null,
		city: null,
		tradeOption: []
	});

	const view = isMobile?'mobile':'browser';

	const updateCardOptions = () => {
		setFilters({
			...filters,
			set: cardOptions.set[cardOptions.set.length - 1],
			type: cardOptions.type[cardOptions.type.length - 1]
		});
	};

	useEffect(updateCardOptions, [cardOptions]);

	const initializeDeliveryMethods = () => {
		setDeliveryMethods(getDeliveryMethods().map(method => ({
			...method,
			label: intl.formatMessage({id: method.label, defaultMessage: method.label})
		})));
	};

	useEffect(initializeDeliveryMethods, []);
	
	/* ---- ON ACTION ---- */
	
	const onSelectFilter = (filter, option) => {
		const newFilters = {...filters};
		if(filter === 'country') {
			newFilters.state = null;
			newFilters.city = null;
		}else if(filter === 'state') {
			newFilters.state = null;
		}
		if(filter === 'tradeOption' && option) {
			const selected = [];
			deliveryMethods.forEach(tradeOption => {
				const isSelected = option.find(o =>
					o.id === tradeOption.id
					|| (tradeOption.id !== DELIVERY && tradeOption.value < o.value)
				);
				if(isSelected) {
					selected.push(tradeOption);
				}
			});
			newFilters[filter] = selected;
		}else {
			newFilters[filter] = option;
		}
		setFilters(newFilters);
	};

	const resetValues = () => {
		setFilters({
			set: allOptionF,
			type: allOptionM,
			condition: allOptionF,
			language: allOptionM,
			country: null,
			state: null,
			city: null,
			tradeOption: []
		});
	};

	const getShipping = () => {
		let greatestShipping = null;
		filters.tradeOption.forEach(option => {
			if(option.id !== DELIVERY) {
				if(greatestShipping === null || greatestShipping.value < option.value) {
					greatestShipping = option;
				}
			}
		});
		return greatestShipping ? greatestShipping.id : NO_SHIPPING.id;
	};

	const onSave = () => {
		setShowActionError(false);
		createCardWish({
			userId: getUserId(),
			cardId: card.id,
			cardName: card.name,
			cardSet: filters.set.id,
			cardType: filters.type.id,
			cardLanguage: filters.language.id,
			cardCondition: filters.condition.id,
			countryid: filters.country ? filters.country.id : 0,
			stateid: filters.state ? filters.state.id : 0,
			cityid: filters.city ? filters.city.id : 0,
			userDelivery: filters.tradeOption.find(o => o.id === DELIVERY) ? DELIVERY : NO_DELIVERY,
			userShipping: getShipping()
		})
			.then(response => {
				resetValues();
				onAccept();
			})
			.catch((error) => {
				setActionError(error);
				setShowActionError(true);
			});
	};

	const closeModal = () => {
		resetValues();
		setShowActionError(false);
		onClose();
	};

	/* ---- SELECT OPTIONS ---- */

	const getConditionOptions = () => {
		const conditionOptions = getCardConditionsOptions();
		conditionOptions.unshift(allOptionF);
		return conditionOptions;
	};

	const getLanguageOptions = () => {
		const languageOptions = getLanguagesOptions().map(language => ({
			...language,
			label: intl.formatMessage({id: language.label, defaultMessage: language.label})
		}));
		languageOptions.unshift(allOptionM);
		return languageOptions;
	};

	/* ---- DOM ---- */
	
	return (
		<CHModal isOpen={isOpen} toggle={closeModal} className="WishlistFormModal-modal" >
			<CHModalHeader
				tittle={intl.formatMessage({id: 'tittle.addToWishlist', defaultMessage: 'Add to wishlist'})}
			/>
			<CHModalBody className="WishlistFormModal-body" CHStyle="brandNoScroll">
				<div className="wishlistFormModal-filters-div">
					<label className="wishlistFormModal-filters-label">
						{intl.formatMessage({id: 'game.set', defaultMessage: 'Set'})}
					</label>
					<CHSelect
						id="select-set"
						className={`${view}-wishlistFormModal-filters-select`}
						options={cardOptions.set}
						value={filters.set}
						onChange={option => onSelectFilter('set', option)}
					/>

					<label className="wishlistFormModal-filters-label">
						{intl.formatMessage({id: 'game.type', defaultMessage: 'Type'})}
					</label>
					<CHSelect
						id="select-type"
						className={`${view}-wishlistFormModal-filters-select`}
						options={cardOptions.type}
						value={filters.type}
						onChange={option => onSelectFilter('type', option)}
					/>

					<label className="wishlistFormModal-filters-label">
						{intl.formatMessage({id: 'game.condition', defaultMessage: 'Condition'})}
					</label>
					<CHSelect
						id="select-condition"
						className={`${view}-wishlistFormModal-filters-select`}
						options={getConditionOptions()}
						value={filters.condition}
						onChange={option => onSelectFilter('condition', option)}
					/>

					<label className="wishlistFormModal-filters-label">
						{intl.formatMessage({id: 'general.language', defaultMessage: 'Language'})}
					</label>
					<CHSelect
						id="select-language"
						className={`${view}-wishlistFormModal-filters-select`}
						options={getLanguageOptions()}
						value={filters.language}
						onChange={option => onSelectFilter('language', option)}
					/>
				</div>

				<div className="wishlistFormModal-filters-div">
					<label className="wishlistFormModal-filters-label">
						{intl.formatMessage({id: 'general.country', defaultMessage: 'Country'})}
					</label>
					<CHSelect
						id="select-countries"
						className={`${view}-wishlistFormModal-filters-select`}
						type="countries"
						allOption="any"
						value={filters.country}
						onChange={option => onSelectFilter('country', option)}
					/>

					<label className="wishlistFormModal-filters-label">
						{intl.formatMessage({id: 'general.state', defaultMessage: 'State'})}
					</label>
					<CHSelect
						id="select-states"
						className={`${view}-wishlistFormModal-filters-select`}
						type="states"
						allOption="any"
						countryId={filters.country? filters.country.id : -1}
						value={filters.state}
						onChange={option => onSelectFilter('state', option)}
					/>

					<label className="wishlistFormModal-filters-label">
						{intl.formatMessage({id: 'general.city', defaultMessage: 'City'})}
					</label>
					<CHSelect
						id="select-cities"
						className={`${view}-wishlistFormModal-filters-select`}
						type="cities"
						allOption="any"
						stateId={filters.state ? filters.state.id : -1}
						value={filters.city}
						onChange={option => onSelectFilter('city', option)}
					/>

					<label className="wishlistFormModal-filters-label">
						{intl.formatMessage({id: 'general.shippingOptions', defaultMessage: 'Delivery options'})}
					</label>
					<CHSelect
						id="select-deliveryMethods"
						className={`${view}-wishlistFormModal-filters-select`}
						isMulti
						isClearable={false}
						options={deliveryMethods}
						value={filters.tradeOption}
						onChange={option => onSelectFilter('tradeOption', option)}
					/>
				</div>
				<CHError
					id="WishlistFormModal"
					isShown={showActionError}
					error={actionError}
				/>
			</CHModalBody>
			<CHModalFooter className="WishlistFormModal-footer"
				type="save"
				acceptLabel={intl.formatMessage({id: 'general.save', defaultMessage: 'Save'})}
				onAccept={onSave}
				acceptDisabled={
					!filters.set ||
					!filters.type ||
					!filters.condition ||
					!filters.language
				}
			/>
		</CHModal>
	);
};

WishlistFormModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onAccept: PropTypes.func.isRequired,
	cardOptions: PropTypes.shape({
		set: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			value: PropTypes.string // accepts null
		})).isRequired,
		type: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			label: PropTypes.string.isRequired,
			value: PropTypes.string // accepts null
		})).isRequired
	}).isRequired,
	card: PropTypes.object,
	type: PropTypes.number
};

WishlistFormModal.defaultProps = {
	card: null
};

export default WishlistFormModal;
