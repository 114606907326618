import React, { useState, createRef } from 'react';
import './LoginModal.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import CHModal from "../../components/CHComponents/CHModal";
import CHModalBody from "../../components/CHComponents/CHModalBody";
import CHModalFooter from "../../components/CHComponents/CHModalFooter";
import CHInput from '../../components/CHComponents/CHInput';
import CHCheckbox from '../../components/CHComponents/CHCheckbox';
import CHError from '../../components/CHComponents/CHError';
import { login } from '../../api/identityServiceApi';
import { setUser } from '../../utils/localStorageManager';


const LoginModal = props => {
	const {isOpen, onClose, onLogin} = props;
	const intl = useIntl();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [remember, setRemeber] = useState(false);
	const [showActionError, setShowActionError] = useState(false);
	const [actionError, setActionError] = useState(null);
	const passwordInput = createRef();

	const resetValues = () => {
		setUsername('');
		setPassword('');
		setShowActionError(false);
		setRemeber(false);
	};


	const onAccept = () => {
		setShowActionError(false);
		login(username, password, remember)
			.then(userInfo => {
				setUser(userInfo);
				resetValues();
				onLogin();
			})
			.catch((error) => {
				setShowActionError(true);
				setActionError(error);
			});
	};

	const onUsernamePress = (key) => {
		if(key === 'Enter') {
			passwordInput.current.focus();
		}
	};

	const onPasswordPress = (key) => {
		if(key === 'Enter') {
			onAccept();
		}
	};

	const closeModal = () => {
		resetValues();
		onClose();
	};

	return (
		<CHModal isOpen={isOpen} toggle={closeModal} className="LoginModal-modal" headerless>
			<CHModalBody className="LoginModal-body">
				<CHInput
					className="LoginModal-input"
					id="login-username"
					type="text"
					placeholder={intl.formatMessage({id: 'general.userOrMail', defaultMessage: 'Username or email'})}
					value={username}
					onChange={value => setUsername(value)}
					onKeyPress={onUsernamePress}
				/>
				<CHInput
					ref={passwordInput}
					className="LoginModal-input"
					id="login-pass"
					type="password"
					placeholder={intl.formatMessage({id: 'general.password', defaultMessage: 'Password'})}
					value={password}
					onChange={value => setPassword(value)}
					onKeyPress={onPasswordPress}
				/>
				<CHCheckbox
					id="login-remember"
					checked={remember}
					onChange={() => setRemeber(!remember)}
					label={intl.formatMessage({id: 'general.rememberMe', defaultMessage: 'Remember me'})}
				/>

				<div className="LoginModal-links-div">
					<a href="/registerPage" className="LoginModal-forgottenPass">
						{intl.formatMessage({id: 'general.forgottenPassword', defaultMessage: 'Did you forget your password?'})}
					</a>
					<a href="/registerPage" className="LoginModal-registerPage">
						{intl.formatMessage({id: 'general.register', defaultMessage: 'Register'})}
					</a>
                </div>

				<CHError
					id="LoginModal-error"
					isShown={showActionError}
					error={actionError}
				/>
			</CHModalBody>
			<CHModalFooter className="LoginModal-footer"
				type="save"
				acceptLabel={intl.formatMessage({id: 'general.logIn', defaultMessage: 'Log in'})}
				onAccept={onAccept}
			/>
		</CHModal>
		
	);
};

LoginModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onLogin: PropTypes.func.isRequired
};

export default LoginModal;
