import React, {useEffect, useState} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import OwnProfile from './OwnProfile';
import OtherProfile from './OtherProfile';
import { getUsername } from '../../utils/localStorageManager';
import axios from "axios";
import { getUserByUsername, getFullUserByUsername } from "../../api/identityServiceApi";

const UserProfile = props => {
	const location = useLocation();
	const pathParams = useParams();
	const { username } = pathParams;
	const [isOwnProfile, setIsOwnProfile] = useState(false);
	const [user, setUser] = useState({
		id: -1,
		username: '',
		password: null,
		name: '',
		surname: '',
		email: '',
		country: null,
		state: null,
		city: null,
		userStatus: '',
		userShipping: '',
		userDelivery: '',
		reputation: -1,
		avatarId: null,
		avatarType: null,
		observation: ''
	});

	const updateUserInfo = () => {
		let mounted = true;
		const cancelSource = axios.CancelToken.source();
		setIsOwnProfile(username === getUsername());

		if(username === getUsername()) {
			getFullUserByUsername(username, cancelSource.token)
				.then(userData => {
					if(mounted) {
						setUser(userData);
					}
				});
		}else {
			getUserByUsername(username, cancelSource.token)
				.then(userData => {
					if(mounted) {
						setUser({
							...user,
							...userData
						});
					}
				});
		}

		return () => {
			mounted = false;
			cancelSource.cancel('Cancelling on unmount');
		};
	};

	useEffect(updateUserInfo, [location]);
	
	if(isOwnProfile) {
		return (
			<OwnProfile user={user} />
		);
	}else {
		return (
			<OtherProfile user={user} />
		);
	}
};

export default UserProfile;
