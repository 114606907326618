import {
	NO_DELIVERY,
	NO_SHIPPING,
	SALEHISTORY_PENDING,
	SALEHISTORY_CONFIRMEDF,
	SALEHISTORY_PENDING_CLOSURE,
	SALEHISTORY_CANCELEDF,
	SALEHISTORY_FINISHEDF
} from './constants';

const enums = require('../resources/enums.json');

/* ---- LANGUAGE ---- */

const getLanguagesOptions = () => {
	return enums.languages.map(language => ({
		id: language.id,
		value: language.id,
		label: language.id
	}));
};

const getLanguageByCode = (code) => {
	const language = enums.languages.find(language => language.code === code);
	return language ? language : {id: -1, value: code, code: code, label: code};
};

/* ---- CARD CONDITION ---- */

const getCardConditionsOptions = () => {
	return enums.conditions.map(condition => ({
		id: condition.id,
		value: condition.value,
		label: condition.label
	}));
};

const getCardConditionById = (id) => {
	const condition = enums.conditions.find(condition => condition.id === id);
	return condition ? condition : {id: -1, value: 'N/A', label: 'N/A'}
};

/* ---- CARD TYPE ---- */

const getCardTypesOptions = () => {
	return enums.cardTypes.map(type => ({
		id: type.id,
		value: type.value,
		label: type.label
	}));
};

const getCardTypeById = (id) => {
	const type = enums.cardTypes.find(type => type.id === id);
	return type ? type : {id: -1, value: 'N/A', code: 'N/A', label: 'N/A'}
};

const getCardTypeByCode = (code) => {
	return enums.cardTypes.find(type => type.code === code);
};

/* ---- CARD SALE STATUS ---- */

const getCardSaleStatusByCode = (code) => {
	const status = enums.cardSaleStatus.find(saleStatus => saleStatus.code === code);
	return status ? status : {id: -1, value: 'Not found', code: 'N/A', label: 'Not found'};
};

const getCardSaleStatusOptions = () => {
	const options = [];
	enums.cardSaleStatus.forEach(saleStatus => {
		saleStatus.code !== 'CLOSED' && options.push({
			id: saleStatus.id,
			value: saleStatus.id,
			label: saleStatus.id
		});
	});
	return options;
};

/* ---- USER DELIVERY AND SHIPPING ---- */

const getUserShippingOptions = (filterById) => {
	const options = [];
	let index = 0;
	for(let method of enums.userShipping) {
		options.push({
			id: method.id,
			value: index,
			label: method.id,
			icon: method.icon
		});
		index++;
		if(filterById && method.id === filterById) {
			break;
		}
	}
	return options;
};

const getUserShippingById = (id) => {
	const options = getUserShippingOptions();
	return options.find(o => o.id === id);
};

const getUserDeliveryOptions = () => {
	return enums.userDelivery.map(method => ({
		id: method,
		value: method,
		label: method
	}));
};

const getUserDeliveryById = (id) => {
	const options = getUserDeliveryOptions();
	return options.find(o => o.id === id);
};

const getDeliveryMethods = () => {
	const options = [];
	getUserDeliveryOptions().forEach(option => {
		if(option.id !== NO_DELIVERY) {
			options.push({...option});
		}
	});
	getUserShippingOptions().forEach(option => {
		if(option.id !== NO_SHIPPING.id) {
			options.push({...option});
		}
	});
	return options;
};

/* ---- SALEHISTORY STATUS ---- */

/* recieves:
* actualStatus: string (SaleHistoryStatus)
* isSeller: boolean
* didRate: boolean
*/
const getNextSaleHistoryStatuses = (actualStatus, isSeller, didRate) => {
	if(actualStatus === SALEHISTORY_PENDING) {
		return isSeller
			? [SALEHISTORY_CONFIRMEDF]
			: [];
	}else if(actualStatus === SALEHISTORY_CONFIRMEDF) {
		return [SALEHISTORY_PENDING_CLOSURE, SALEHISTORY_CANCELEDF];
	}else if(actualStatus === SALEHISTORY_PENDING_CLOSURE) {
		return didRate
			? []
			: [SALEHISTORY_FINISHEDF, SALEHISTORY_CANCELEDF];
	}
	return [];
};

export {
	getLanguageByCode, getLanguagesOptions,
	getCardConditionById, getCardConditionsOptions,
	getCardTypeById, getCardTypeByCode, getCardTypesOptions,
	getCardSaleStatusByCode, getCardSaleStatusOptions,
	getUserShippingOptions, getUserShippingById, getUserDeliveryOptions, getUserDeliveryById, getDeliveryMethods,
	getNextSaleHistoryStatuses
};
