import React, { useState, useEffect, Fragment } from 'react';
import './Table.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import CHTable from '../../../../components/CHComponents/CHTable';
import { DeleteCell } from '../../../../components/customColumns/DeleteColumn';
import { DeliveryMethodsCellContent } from '../../../../components/customColumns/DeliveryMethodsColumn';
import WishDeleteModal from './WishDeleteModal';
import { getCardWishes } from '../../../../api/mtgSalesServiceApi';
import {
	getCardTypeById,
	getCardConditionById
} from '../../../../utils/enumsService';
import CHSpinner from '../../../../components/CHComponents/CHSpinner';
import { ALL, ALL_F, ALL_M } from '../../../../utils/constants';


const Table = props => {
	const intl = useIntl();
	const { userId } = props;
	const columns = [
		{id: 0, label: intl.formatMessage({id: 'general.name', defaultMessage: 'Name'})},
		{id: 1, label: intl.formatMessage({id: 'game.set', defaultMessage: 'Set'})},
		{id: 2, label: intl.formatMessage({id: 'game.type', defaultMessage: 'Type'})},
		{id: 3, label: intl.formatMessage({id: 'general.language', defaultMessage: 'Language'})},
		{id: 4, label: intl.formatMessage({id: 'game.condition', defaultMessage: 'Condition'})},
		{id: 5, label: intl.formatMessage({id: 'general.tradeOption', defaultMessage: 'Trade options'})},
		{id: 6, label: intl.formatMessage({id: 'general.country', defaultMessage: 'Country'})},
		{id: 7, label: intl.formatMessage({id: 'general.state', defaultMessage: 'State'})},
		{id: 8, label: intl.formatMessage({id: 'general.city', defaultMessage: 'City'})},
		{id: 9, label: intl.formatMessage({id: 'general.delete', defaultMessage: 'Delete'})},
	];
	const [isFetchingWishes, setIsFetchingWishes] = useState(false);
	const [wishes, setWishes] = useState([]);
	const [rows, setRows] = useState([]);
	const [selectedWish, setSelectedWish] = useState(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const getWishlistRows = () => {
		setIsFetchingWishes(true);
		getCardWishes(userId)
			.then(cardWishes => {
				const newRows = cardWishes.map(wish => {
					return {
						id: wish.id,
						cells: [
							{content: wish.cardName},
							{content: wish.cardSet === ALL
									? intl.formatMessage({id: ALL_F, defaultMessage: ALL_F})
									: wish.cardSet
							},
							{content: wish.cardType === ALL
									? intl.formatMessage({id: ALL_M, defaultMessage: ALL_M})
									: getCardTypeById(wish.cardType).label
							},
							{content: wish.cardLanguage === ALL
									? intl.formatMessage({id: ALL_M, defaultMessage: ALL_M})
									: intl.formatMessage({id: wish.cardLanguage, defaultMessage: wish.cardLanguage})
							},
							{content: wish.cardCondition === ALL
									? intl.formatMessage({id: ALL_F, defaultMessage: ALL_F})
									: getCardConditionById(wish.cardCondition).label
							},
							{content: <DeliveryMethodsCellContent delivery={wish.userDelivery} shipping={wish.userShipping} />},
							{
								content: wish.country.name === ALL
									? intl.formatMessage({id: ALL_M, defaultMessage: ALL_M})
									: wish.country.name
							},
							{
								content: wish.state.name === ALL
									? intl.formatMessage({id: ALL_F, defaultMessage: ALL_F})
									: wish.state.name
							},
							{
								content: wish.city.name === ALL
									? intl.formatMessage({id: ALL_F, defaultMessage: ALL_F})
									: wish.city.name
							},
						]
					};
				});
				setIsFetchingWishes(false);
				setWishes(cardWishes);
				setRows(newRows);
			})
			.catch(error => setIsFetchingWishes(false));
	};

	useEffect(getWishlistRows, [userId]);

	const onDelete = (wishId) => {
		const cardWish = wishes.find(wish => wish.id === wishId);
		setSelectedWish(cardWish);
		setIsDeleteModalOpen(true);
	};

	const onWishDeleted = () => {
		getWishlistRows();
		setIsDeleteModalOpen(false);
	};

	return (
		<Fragment>
			<CHTable tableClassName="UserSalesTable-userWishlist"
				columns={columns}
				rows={rows}
				customColumns={[
					{cellComponent: DeleteCell, args: {onDelete}}
				]}
			/>
			<CHSpinner id="UserWishlistTable-spinner" isActive={isFetchingWishes} />
			<WishDeleteModal
				wish={selectedWish}
				onDelete={onWishDeleted}
				isOpen={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
			/>
		</Fragment>
	);
};

Table.propTypes = {
	userId: PropTypes.number.isRequired
};

export default Table;
