import { FETCHING_PRINTS, ADD_PRINTS } from '../actions/cardSearchActions';

const initialState = {
	fetching: false,
	cardName: '',
	prints: []
};

export default (state = initialState, action) => {
	switch(action.type) {
		case FETCHING_PRINTS:
			return {
				fetching: true,
				cardName: action.payload,
				prints: []
			};
		case ADD_PRINTS:
			return {
				...state,
				fetching: action.cardName === state.cardName ? false : state.fetching,
				prints: action.cardName === state.cardName ? action.prints : state.prints
			};
		default:
			return state;
	}
};
