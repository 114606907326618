import React, { useState } from 'react';
import './OrderColumn.css';
import PropTypes from 'prop-types';

import Icon from "../Icon";
import CHInput from '../CHComponents/CHInput';


const OrderColumnHead = () => (
	<Icon
		className="orderColumn-head-icon"
		type="file"
		name="mailbox"
		width="25"
		height="25"
	/>
);

const OrderCell = props => {
	const {tdClassName, row, args} = props;
	const [quantity, setQuantity] = useState(1);


	return (
		<td className={tdClassName} key={row.id + 'order'}>
			<CHInput
				className="orderColumn-cell-input"
				id={`orderInput-${row.id}`}
				type="int"
				min={1}
				max={row.customData.max}
				value={quantity}
				onChange={value => setQuantity(value)}
			/>
			<span
				className="orderColumn-cell-icon-span"
				onClick={() => args.onOrder(row.id, row.customData.max, quantity)}
			>
				<Icon
					className="orderColumn-cell-icon"
					type="other"
					name="add"
					width="30"
					height="30"
				/>
			</span>
		</td>
	);
};

OrderCell.propTypes = {
	tdClassName: PropTypes.string.isRequired,
	row: PropTypes.object.isRequired,
	args: PropTypes.shape({
		onOrder: PropTypes.func.isRequired
	}).isRequired
};

export {
	OrderColumnHead,
	OrderCell
};
