import React, { useState, useEffect, Fragment } from 'react';
import './UserInfo.css';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { isMobile } from 'react-device-detect';

import Icon from '../../components/Icon';
import CHButton from '../../components/CHComponents/CHButton';
import AvatarEditionModal from './AvatarEditionModal';
import { DeliveryMethodsCellContent } from '../../components/customColumns/DeliveryMethodsColumn';
import { USERSTATUS_SUSPENDED } from '../../utils/constants';
import { updateUrlwHistory, getUrlGame } from '../../utils/urlManager';
import {Tooltip} from 'reactstrap';
import defaultProfileAvatar from '../../assets/icons/defaultProfileAvatar.svg';

const scryfall = require('../../api/scryfallApi');

const view = isMobile?'mobile':'browser';

const UserInfo = props => {
	const intl = useIntl();
	const location = useLocation();
	const history = useHistory();
	const pathParams = useParams();
	const { username } = pathParams;
	const { isOwnProfile, user } = props;
	const [game, setGame] = useState('mtg');
	const [avatarPrint, setAvatarPrint] = useState(null);
	const [avatarImage, setAvatarImage] = useState(null);
	const [isAvatarEditionModalOpen, setIsAvatarEditionModalOpen] = useState(false);
	const [isInfoSectionHovered, setIsInfoSectionHovered] = useState(false);
	const [isSuspendedTooltipOpen, setIsuspendedTooltipOpen] = useState(false);
	const toggleSuspendedTooltip = () => setIsuspendedTooltipOpen(!isSuspendedTooltipOpen);

	const onAvatarChange = print => {
		setIsAvatarEditionModalOpen(false);
		setAvatarPrint(print);
		setAvatarImage(print.image_uris ? print.image_uris.art_crop : print.card_faces[0].image_uris.art_crop);
	};

	useEffect(() => {
		let mounted = true;
		const cancelSource = axios.CancelToken.source();

		if(user.avatarId && user.avatarType) {
			scryfall.getCardById(user.avatarId, cancelSource.token)
				.then(cardPrint => {
					if(mounted && cardPrint) {
						onAvatarChange(cardPrint);
					}else if(mounted) {
						setAvatarImage(defaultProfileAvatar);
						setAvatarPrint(null);
					}
				})
				.catch(error => {
					if(mounted) {
						setAvatarImage(defaultProfileAvatar);
						setAvatarPrint(null);
					}
				});
		}

		return () => {
			mounted = false;
			cancelSource.cancel('Cancelling on unmount');
		};
	}, [user]);

	useEffect(() => setGame(getUrlGame(location)), [location]);

	return (
		<Fragment>
			<div className={`${view}-userInfo-div`}>
				{user.userStatus === USERSTATUS_SUSPENDED && (
					<div className="ownProfile-suspendedUser">
						<p>
						<span  id="suspendUserTooltip">{intl.formatMessage({id: 'general.suspendUser', defaultMessage: 'Suspended user'})}
						</span>
						</p>
						<Tooltip placement="bottom" isOpen={isSuspendedTooltipOpen} target="suspendUserTooltip" toggle={toggleSuspendedTooltip}>
							{intl.formatMessage({id: 'message.suspendUserTooltip', defaultMessage: 'When the user is suspended, the account and all its sales are hidden and nobody can place purchase orders. The account can be reactivated at any time.'})}
						</Tooltip>
					</div>
				)}
				<div>
					<div className={`${view}-userInfo-imgSection-div`}>
						{avatarImage && (
							<img
								onClick={() => setIsAvatarEditionModalOpen(isOwnProfile)}
								className={view +`-userInfo-imgSection-img ${isOwnProfile ? view +'-userInfo-imgSection-img-hover' : ''}`}
								src={avatarImage}
								alt="Not found"
								onError={e => {e.target.onerror = null; e.target.src = require('../../assets/images/mtg-card-not-found.jpg')}}
							/>
						)}
					</div>
					<div
						className={`${view}-userInfo-infoSection-div`}
						onMouseEnter={() => setIsInfoSectionHovered(true)}
						onMouseLeave={() => setIsInfoSectionHovered(false)}
					>
							<div className="userInfo-infoUpSection-left">
							</div>
							<div className="userInfo-infoUpSection-center">
								<label className={`${view}-userInfo-username-label`}>{username}</label>
							</div>
							<div className="userInfo-infoUpSection-right">
								{((isInfoSectionHovered && isOwnProfile) || (isMobile && isOwnProfile) )&&
									<CHButton
										className={`${view}-userInfo-info-editBtn`}
										label={<Icon type="other" name="pencil" width="15" height="15"/>}
										onClick={() => updateUrlwHistory(location, history, `/${game}/userEdition/${username}`)}
									/>
								}
							</div>


						<strong className="userInfo-info-strong">
							{intl.formatMessage({id: 'general.country', defaultMessage: 'Country'})}:
						</strong>{user.country ? user.country.name : ''}<br/>
						<strong className="userInfo-info-strong">
							{intl.formatMessage({id: 'general.state', defaultMessage: 'State'})}:
						</strong>{user.state ? user.state.name : ''}<br/>
						<strong className="userInfo-info-strong">
							{intl.formatMessage({id: 'general.city', defaultMessage: 'City'})}:
						</strong>{user.city ? user.city.name : ''}<br/>
						<strong className="userInfo-info-strong">
							{intl.formatMessage({id: 'general.shippingOptions', defaultMessage: 'Delivery methods'})}:
						</strong>
						<DeliveryMethodsCellContent
							id={`user-${user.id}`}
							shipping={user.userShipping}
							delivery={user.userDelivery}
						/>
						<br/>
						<strong className="userInfo-info-strong">
							{intl.formatMessage({id: 'general.reputation', defaultMessage: 'Reputation'})}:
						</strong>
						{user.reputation}
						<br/>
						<strong className="userInfo-info-strong">
							{intl.formatMessage({id: 'general.observation', defaultMessage: 'Observation'})}:
						</strong>   {user.observation}
					</div>
					<AvatarEditionModal
						isOpen={isAvatarEditionModalOpen}
						toggle={() => setIsAvatarEditionModalOpen(false)}
						avatarPrint={avatarPrint}
						onAccept={print => onAvatarChange(print)}
						user={user}
					/>
				</div>
			</div>
		</Fragment>
	);
};

UserInfo.propTypes = {
	isOwnProfile: PropTypes.bool,
	user: PropTypes.shape({
		id: PropTypes.number.isRequired,
		username: PropTypes.string,
		password: PropTypes.string,
		name: PropTypes.string,
		surname: PropTypes.string,
		email: PropTypes.string,
		country: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		state: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		city: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		userStatus: PropTypes.string,
		userShipping: PropTypes.string,
		userDelivery: PropTypes.string,
		reputation: PropTypes.number,
		avatarId: PropTypes.string,
		avatarType: PropTypes.string,
		observation: PropTypes.string
	}).isRequired
};

export default UserInfo;
