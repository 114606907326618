import React from 'react';
import './CHError.css';
import PropTypes from 'prop-types';

import CHErrorMessage from './CHErrorMessage';


const CHError = (props) => {
	const { id, isShown, error, altMessage, className } = props;

	return isShown
		? (
			<div id={id} className={`CHError-div ${className ? className : ''}`}>
				<CHErrorMessage error={error} altMessage={altMessage} />
			</div>
		) : null;
};

CHError.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isShown: PropTypes.bool.isRequired,
	// axios error: {status: number, data: string | any, ...}
	error: PropTypes.any,
	altMessage: PropTypes.string,
	className: PropTypes.string
};

export default CHError;
