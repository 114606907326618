import React from 'react';
import './CHButton.css';
import PropTypes from 'prop-types';
import {isMobile} from 'react-device-detect';


const CHButton = props => {
	const {id, label, onClick, CHStyle, className, disabled} = props;

	function getStyles() {
		let styles = 'CHButton-base';
		styles += CHStyle ? ` CHButton-${CHStyle}` : ' CHButton-brand';
		if(className) {
			styles += ` ${className}`;
		}
		if(disabled) {
			styles += ` CHButton-disabled`;
		}
		if(isMobile) {
			styles += ` CHButton-mobile`;
		}
		return styles;
	}

	return (
		<button
			id={id}
			className={getStyles()}
			onClick={onClick}
			disabled={disabled}
		>
			{label}
		</button>
	);
};

CHButton.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	label: PropTypes.node.isRequired,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	CHStyle: PropTypes.oneOf(['brand', 'accept', 'transparent', 'warning','suspend']),
	className: PropTypes.string
};

export default CHButton;
