import React from 'react';
import '../../assets/styles/ThemeVariables.css';
import './Home.css';
import {useLocation, useHistory} from 'react-router-dom';
import {isMobile} from 'react-device-detect';

import { updateUrlwHistory } from '../../utils/urlManager';

const view = isMobile?'mobile':'browser';

const Home = props => {
	const location = useLocation();
	const history = useHistory();

	return (
		<div className={`${view}-home-div`}>
			<div className={`${view}-home-game-buttons-div`}>
				<img
					id="img-game-pkm"
					alt=""
					className={`${view}-home-Image-Temp`}
					src={  require('../../assets/images/PKMimageTemp.jpg')} 
				/>
				<img
					id="img-game-mtg"
					alt=""
					className={`${view}-home-Image`} 
					src={  require('../../assets/images/MTGimage.jpg')} 
					onClick={() => updateUrlwHistory(location, history, '/mtg')}
				 />
				<img
					id="img-game-ygo"
					alt=""
					className={`${view}-home-Image-Temp`} 
					src={  require('../../assets/images/YGOimageTemp.jpg')} 
				 />
			</div>

		</div>
	);
};

export default Home;
