import React, {useEffect, useRef, useState} from 'react';
import './CardSales.css';
import { useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import CardInfo from './CardInfo';
import CHTable from '../../components/CHComponents/CHTable';
import { OrderColumnHead, OrderCell } from '../../components/customColumns/OrderColumn';
import { ObservationCellContent } from '../../components/customColumns/ObservationColumn';
import { AcceptsTradeCellContent } from '../../components/customColumns/AcceptsTradeColumn';
import { DeliveryMethodsCellContent } from '../../components/customColumns/DeliveryMethodsColumn';
import { onCheckboxFilter, onLocationFilter, filterRows } from '../../utils/TableFilterService';
import { changeSorting, sortRows } from '../../utils/TableSortService';
import {
	getCardConditionById,
	getCardConditionsOptions, getDeliveryMethods,
	getLanguagesOptions,
	getUserShippingOptions
} from '../../utils/enumsService';
import { fetchCart } from '../../redux/actions/sessionActions';
import { getUrlParams } from '../../utils/urlManager';
import { floatToString } from '../../utils/stringParser';
import { getCardCardSales, createSaleHistory } from '../../api/mtgSalesServiceApi';
import { getUserId } from '../../utils/localStorageManager';
import CHSpinner from '../../components/CHComponents/CHSpinner';
import { CHAlert, showAlert } from '../../components/CHComponents/CHAlert';

const scryfall = require('../../api/scryfallApi');
const locationColumns = {country: 4, state: 5, city: 6};
const multiValueColumns = [1];
const sortByContent = [4, 5, 6];
const loggedInSelector = store => store.session.loggedIn;

const view = isMobile?'mobile':'browser';

const CardSales = props => {
	const intl = useIntl();
	const location = useLocation();
	const pathParams = useParams();
	const dispatch = useDispatch();
	const loggedIn = useSelector(loggedInSelector);
	const [card, setCard] = useState(null);
	const [isFetchingCard, setIsFetchingCard] = useState(false);
	const [isFetchingSales, setIsFetchingSales] = useState(false);
	const [cardType, setCardType] = useState('');
	const [sales, setSales] = useState([]);
	const [filteredSales, setFilteredSales] = useState([]);
	const [filtersSelected, setFiltersSelected] = useState({});
	const filtersRef = useRef({});
	filtersRef.current = filtersSelected;
	const [sorting, setSorting] = useState({by: 2, asc: true});
	const [orderAlertIsOpen, setOrderAlertIsOpen] = useState(false);
	const [orderAlertIsError, setOrderAlertIsError] = useState(false);
	const [orderAlertError, setOrderAlertError] = useState(false);
	const [orderAlertAltErrorMsg, setOrderAlertAltErrorMsg] = useState(null);

	/* ---- COLUMNS ---- */

	const onCheckboxChange = (event, columnId, columnLabel) => {
		const tableFilter = onCheckboxFilter({...filtersRef.current}, [...columns], event, columnId, columnLabel);
		setColumns(tableFilter.columns);
		setFiltersSelected(tableFilter.filters);
	};

	const filterLocation = (locationOption, columnId, columnLabel) => {
		const tableFilter = onLocationFilter(
			{...filtersRef.current}, [...columns],
			locationColumns, locationOption, columnId, columnLabel);
		setColumns(tableFilter.columns);
		setFiltersSelected(tableFilter.filters);
	};

	const [columns, setColumns] = useState([
		{id: 0, label: intl.formatMessage({id: 'general.seller', defaultMessage: 'Seller'})},
		{id: 1, label: intl.formatMessage({id: 'general.tradeOption', defaultMessage: 'Trade option'}), filter: {
			type: 'checkbox',
			maxMenuHeight: 'small',
			options: getDeliveryMethods().map(method => ({
				...method,
				label: intl.formatMessage({id: method.label, defaultMessage: method.label}),
				value: method.id,
				checked: false
			})),
			onChange: onCheckboxChange
		}},
		{id: 2, label: intl.formatMessage({id: 'game.condition', defaultMessage: 'Condition'}), sortable: true, filter: {
			type: 'checkbox',
			maxMenuHeight: 'small',
			options: getCardConditionsOptions().map(condition => ({...condition, checked: false})),
			onChange: onCheckboxChange
		}},
		{id: 3, label: intl.formatMessage({id: 'general.language', defaultMessage: 'Language'}), sortable: true, filter: {
			type: 'checkbox',
			maxMenuHeight: 'small',
			options: getLanguagesOptions().map(language => ({
				...language,
				label: intl.formatMessage({id: language.label, defaultMessage: language.label}),
				checked: false
			})),
			onChange: onCheckboxChange
		}},
		{id: 4, label: intl.formatMessage({id: 'general.country', defaultMessage: 'Country'}), sortable: true, filter: {
			type: 'countries',
			selectValue: null,
			selectAllOption: 'any',
			onChange: filterLocation
		}},
		{id: 5, label: intl.formatMessage({id: 'general.state', defaultMessage: 'Province/State'}), sortable: true, filter: {
			type: 'states',
			selectValue: null,
			selectAllOption: 'any',
			countryId: 0,
			onChange: filterLocation
		}},
		{id: 6, label: intl.formatMessage({id: 'general.city', defaultMessage: 'City'}), sortable: true, filter: {
			type: 'cities',
			selectValue: null,
			selectAllOption: 'any',
			stateId: 0,
			onChange: filterLocation
		}},
		{id: 7, label: intl.formatMessage({id: 'general.acceptsTrade', defaultMessage: 'Accepts trade'})},
		{id: 8, label: intl.formatMessage({id: 'general.observation', defaultMessage: 'Observation'})},
		{id: 9, label: intl.formatMessage({id: 'general.quantity', defaultMessage: 'Quantity'}), sortable: true},
		{id: 10, label: intl.formatMessage({id: 'general.price', defaultMessage: 'Price'}) + ' (U$S)', sortable: true}
	]);

	/* ---- LIFE CYCLE ---- */

	const updateColumns = () => {
		const newColumns = [...columns];
		if(loggedIn) {
			newColumns.push({id: 11, label: 'Order', content: <OrderColumnHead />});
		}else if(newColumns.length === 12) {
			newColumns.pop();
		}
		setColumns(newColumns);
	};

	useEffect(updateColumns, [loggedIn]);

	/* ---- FETCH ---- */

	const getDeliveryMethodsValue = (userDelivery, userShipping) => {
		const value = [userDelivery];
		const shippingOptions = getUserShippingOptions(userShipping);
		for(let shippingOption of shippingOptions) {
			value.push(shippingOption.id);
		}
		return value;
	};

	const buildSaleRow = (sale) => {
		return {
			id: sale.id,
			cells: [
				{value: sale.user.username, content: sale.user.username},
				{
					value: getDeliveryMethodsValue(sale.user.userDelivery, sale.user.userShipping),
					className: 'cardSales-deliveryMethod-column',
					content: <DeliveryMethodsCellContent
						id={`sale-${sale.id}`}
						delivery={sale.user.userDelivery}
						shipping={sale.user.userShipping}
					/>
				},
				{value: getCardConditionById(sale.cardCondition).value, content: getCardConditionById(sale.cardCondition).label},
				{
					value: intl.formatMessage({id: sale.cardLanguage, defaultMessage: sale.cardLanguage}),
					content: intl.formatMessage({id: sale.cardLanguage, defaultMessage: sale.cardLanguage})
				},
				{value: sale.user.country.id, content: sale.user.country.name},
				{value: sale.user.state.id, content: sale.user.state.name},
				{value: sale.user.city.id, content: sale.user.city.name},
				{content: <AcceptsTradeCellContent acceptsTrade={sale.cardTradeable} />},
				{content: <ObservationCellContent observation={sale.observation} userObservation={sale.user.observation} />},
				{value: sale.quantity, content: sale.quantity},
				{value: sale.price, content: floatToString(sale.price)}
			],
			customData: {
				max: sale.quantity
			}
		};
	};

	const fetchCardSalesData = () => {
		setIsFetchingCard(true);
		const { cardId } = pathParams;
		const { type } = getUrlParams(location.search);
		setCardType(type);

		scryfall.getCardById(cardId)
			.then(response => {
				setCard(response);
				setIsFetchingCard(false);
			})
			.catch(error => console.error(error));

		setIsFetchingSales(true);
		getCardCardSales(cardId, type)
			.then(sales => {
				let newSales = sales.map(sale => buildSaleRow(sale));
				setIsFetchingSales(false);
				setSales(newSales);
				setFilteredSales(newSales);
			})
			.catch(error => setIsFetchingSales(false));
	};

	useEffect(fetchCardSalesData, [location.pathname, location.search]);


	/* ---- FILTER ---- */

	const applyFilters = () => {
		const filteredRows = filterRows([...sales], {...filtersSelected}, multiValueColumns);
		setFilteredSales(filteredRows);
	};

	useEffect(applyFilters, [filtersSelected]);


	/* ---- SORT ---- */

	const onSort = (columnId) => {
		const newSorting = changeSorting({...sorting}, columnId);
		setSorting(newSorting);
	};

	const onSorting = () => {
		const sortedRows = sortRows([...filteredSales], sorting.by, sorting.asc ? 'asc' : 'desc', sortByContent);
		setFilteredSales(sortedRows);
	};

	useEffect(onSorting, [sorting]);


	/* ---- ORDER ---- */

	const showOrderAlert = () => {
		setOrderAlertIsOpen(true);
		showAlert(() => setOrderAlertIsOpen(false));
	};

	const addOrder = (cardSaleId, max, quantity) => {
		setOrderAlertIsError(false);
		setOrderAlertError(null);
		if(0 < quantity && quantity <= max) {
			createSaleHistory(cardSaleId, getUserId(), quantity)
				.then(saleHistory => {
					dispatch(fetchCart());
					showOrderAlert();
				})
				.catch(error => {
					setOrderAlertIsError(true);
					setOrderAlertError(error);
					showOrderAlert();
				});
		}else {
			setOrderAlertIsError(true);
			setOrderAlertAltErrorMsg(intl.formatMessage({id: "error_outOfStock", defaultMessage: "Out of stock"}));
			showOrderAlert();
		}
	};
	
	return (
		<div className={`${view}-cardSales-Table`}>
			<CardInfo
				isFetching={isFetchingCard}
				card={card}
				type={cardType}
			/>
			<CHTable
				columns={columns}
				rows={filteredSales}
				onSort={onSort}
				sortedBy={sorting.by}
				sortDirection={sorting.asc ? 'asc' : 'desc'}
				customColumns={loggedIn
					? [{cellComponent: OrderCell, args: {onOrder: addOrder}}]
					: []
				}
			/>
			<CHSpinner id="cardSales-spinner" isActive={isFetchingSales} />
			<CHAlert
				isOpen={orderAlertIsOpen}
				label={intl.formatMessage({id: "message.addedToCart", defaultMessage: "Order added to cart"})}
				isError={orderAlertIsError}
				error={orderAlertError}
				altErrorMessage={orderAlertAltErrorMsg}
				CHStyle="accept"
			/>
		</div>
	);
};

export default CardSales;
