import React, { Fragment } from 'react';
import './CHSpinner.css';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

const sizes = {
	small: "5rem",
	medium: "10rem",
	large: "15rem"
};
const CHSpinner = props => {
	const {id, isActive, size} = props;

	if(isActive) {
		return (
			<div id={id} className="CHSpinner-div-container">
				<Spinner
					type="grow"
					color="primary"
					style={{
						width: size ? sizes[size] : sizes.medium,
						height: size ? sizes[size] : sizes.medium
					}}
				/>
			</div>
		);
	}
	return <Fragment/>
};

CHSpinner.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isActive: PropTypes.bool.isRequired,
	sizes: PropTypes.oneOf(['small', 'medium', 'large'])
};

export default CHSpinner;
