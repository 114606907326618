import { LOGIN_USER, LOGOUT_USER, SET_SESSION_THEME, ADD_AVATAR_URL, ADD_CART, CLEAR_CART } from '../actions/sessionActions';

const initialState = {
	loggedIn: false,
	avatarUrl: '',
	cart: [],
	theme: 'dark'
};

export default (state = initialState, action) => {
	switch(action.type) {
		case LOGIN_USER:
			return {
				...state,
				loggedIn: true
			};
		case LOGOUT_USER:
			return {
				...state,
				loggedIn: false,
				cart: [],
				avatarUrl: ''
			};
		case SET_SESSION_THEME:
			return {
				...state,
				theme: action.payload
			};
		case ADD_AVATAR_URL:
			return {
				...state,
				avatarUrl: action.payload
			};
		case ADD_CART:
			return {
				...state,
				cart: action.payload
			};
		case CLEAR_CART:
			return {
				...state,
				cart: []
			};
		default:
			return state;
	}
};
