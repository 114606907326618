import React, {useState, useEffect} from 'react';
import './CHTable.css';
import PropTypes from 'prop-types';
import {Table} from 'reactstrap';
import Icon from '../Icon';
import CHButtonDropdown from './CHButtonDropdown';
import {Link} from "react-router-dom";
import {isMobile} from 'react-device-detect';


const CHTable = props => {
	const {
		columns, rows, linkedRows, onSort, sortedBy, sortDirection, customColumns, CHStyle,
		tableClassName, theadClassName, tbodyClassName
	} = props;
	const [filterStates, setFilterStates] = useState({});

	const initializeFilterStates = () => {
		let states = {};
		columns.forEach(column => {
			if(column.filter) {
				states[column.id] = {
					isOpen: false
				};
			}
		});
		setFilterStates(states);
	};

	useEffect(initializeFilterStates, []);

	const changeFilterState = (filter) => {
		setFilterStates({
			...filterStates,
			[filter]: {
				isOpen: !filterStates[filter].isOpen
			}
		});
	};

	/* ---- STYLES ---- */

	const getElementStyles = (element, className) => {
		let styles = isMobile ? `CHTable-${element}-base-mobile` : `CHTable-${element}-base`;
		styles += CHStyle ? ` CHTable-${element}-${CHStyle}` : ` CHTable-${element}-brand`;
		if(className) {
			styles += ` ${className}`;
		}
		if(isMobile && CHStyle) {
			styles += ` CHTable-${element}-${CHStyle}-mobile`;
		} 
		return styles;
	};

	/* ---- RENDER COLUMNS ---- */
	
	const renderColumns = (columns) => {
		let head = columns.map(column => {
			let isFilterable = column.filter;
			return (
				<th 
					scope="col"
					className={getElementStyles('th', column.className)}
					key={column.label}
				>
					{renderLabel(column)}
					{isFilterable && (
						<CHButtonDropdown
							id={`CHTable-${column.id}-filter`}
							buttonClassName="CHTable-filter-button"
							CHStyle="transparent"
							isOpen={filterStates[column.id] ? filterStates[column.id].isOpen : false}
							toggle={() => changeFilterState(column.id)}
							label={
								<Icon className="CHTable-filterIcon-base" name="filter" type="other" width="14" height="14"/>
							}
							type={column.filter.type}
							countryId={column.filter.countryId}
							stateId={column.filter.stateId}
							selectValue={column.filter.selectValue}
							selectAllOption={column.filter.selectAllOption}
							selectIsMulti={column.filter.selectIsMulti}
							selectIsClearable={column.filter.selectIsClearable}
							content={column.filter.options}
							onChange={event => column.filter.onChange(event, column.id, column.label)}
							maxMenuHeight={column.filter.maxMenuHeight}
						/>
					)}
					{(sortedBy === column.id) && (
						<Icon className="CHTable-sortIcon-base" name={sortDirection} type="other" width="25" height="25"/>
					)}
				</th>
			);
		});
		return (<tr key="head">{head}</tr>);
	};
	
	const renderLabel = (column) => {
		if(column.content) {
			return column.content;
		}else if(!column.sortable) {
			return (<label className="CHTable-th-label-base">{column.label}</label>);
		}else {
			return (
				<span className="CHTable-th-span-base" onClick={() => onSort(column.id)}>
					<label className="CHTable-th-label-base">{column.label}</label>
				</span>
			);
		}
	};

	/* ---- RENDER ROWS ---- */

	const renderRows = (rows) => {
		return rows.map((row) => (
			<tr className="CHTable-tr-base" key={row.id}>
				{row.cells.map((cell, index) => {
					const content = linkedRows ? <Link className="CHTable-link" to={row.link}>{cell.content}</Link> : cell.content;
					return (
						<td className={`${getElementStyles('td', cell.className)} ${cell.limitWidth ? 'CHTable-td-limit' : ''}`} key={row.id + index}>
							{content}
						</td>
					)
				})}
				{customColumns && renderCustomColumns(row)}
			</tr>
		));
	};

	const renderCustomColumns = (row) => {
		return customColumns.map(customColumn => {
			const Cell = customColumn.cellComponent;
			return (
				<Cell
					key={`${row.id}-customCell`}
					tdClassName={getElementStyles('td', null)}
					row={row}
					args={customColumn.args}
				/>
			);
		});
	};
	
	return (
		<div>
			<Table hover className={(CHStyle !== 'products' ? ' table-responsive ' : '') + 'table-light ' + getElementStyles('table', tableClassName)}>
				<thead className={getElementStyles('thead', theadClassName)}>
					{renderColumns(columns)}
				</thead>
				<tbody className={getElementStyles('tbody', tbodyClassName)}>
					{renderRows(rows)}
				</tbody>
			</Table>
		</div>
	);
};

CHTable.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		label: PropTypes.node.isRequired,
		filter: PropTypes.shape({
			type: PropTypes.oneOf(['checkbox', 'select', 'countries', 'states', 'cities']).isRequired,
			maxMenuHeight: PropTypes.oneOf(['small', 'medium', 'large']),
			// type = checkbox/select
			options: PropTypes.arrayOf(PropTypes.shape({
				id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
				value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
				label: PropTypes.node.isRequired,
				// type = checkbox
				checked: PropTypes.bool
			})),
			// onChange(event, column.id, column.label)
			onChange: PropTypes.func.isRequired,
			// type = select/countries/states/cities
			selectValue: PropTypes.shape({
				id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
				value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
				label: PropTypes.node.isRequired
			}),
			selectAllOption: PropTypes.oneOf(['M', 'F', 'any']),
			selectIsMulti: PropTypes.bool,
			selectIsClearable: PropTypes.bool,
			// type = states
			countryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			// type = cities
			stateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		sortable: PropTypes.bool
	})).isRequired,
	rows: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		link: PropTypes.string,
		customData: PropTypes.any,
		cells: PropTypes.arrayOf(PropTypes.shape({
			content: PropTypes.node.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
				PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
			]),
			className: PropTypes.string,
			limitWidth: PropTypes.bool
		})).isRequired
	})).isRequired,
	linkedRows: PropTypes.bool,
	// onSort(column.id)
	onSort: PropTypes.func,
	sortedBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	sortDirection: PropTypes.oneOf(['asc', 'desc']),
	customColumns: PropTypes.arrayOf(PropTypes.shape({
		// CellComponent with tdClassName row args props
		cellComponent: PropTypes.func.isRequired,
		// can pass parent component callbacks here
		args: PropTypes.object.isRequired
	})),
	CHStyle: PropTypes.oneOf(['brand', 'products']),
	tableClassName: PropTypes.string,
	theadClassName: PropTypes.string,
	tbodyClassName: PropTypes.string
};

export default CHTable;