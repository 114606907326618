import React from 'react';
import './CHModalBody.css';
import PropTypes from 'prop-types';
import {isMobile} from 'react-device-detect';


const CHModalBody = props => {
	const {className, CHStyle} = props;

	function getStyles() {
		let styles = 'CHModalBody-base';
		styles += CHStyle ? ` CHModalBody-${CHStyle}` : ' CHModalBody-brandScroll';
		if(className) {
			styles += ` ${className}`;
		}
		if(isMobile) {
			styles += ` CHModalBody-mobile`;
		}
		return styles;
	}

	return (
		<div className={getStyles()}>
			{props.children}
		</div>
	);
};

CHModalBody.propTypes = {
	CHStyle: PropTypes.oneOf(['brandScroll', 'brandNoScroll']),
	className: PropTypes.string
};

export default CHModalBody;