import React, {useState, useEffect} from 'react';
import './SaleCreationModal.css';
import PropTypes from "prop-types";
import axios from 'axios';
import { useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';

import CHModalHeader from '../../../../components/CHComponents/CHModalHeader';
import CHModal from '../../../../components/CHComponents/CHModal';
import CHModalBody from '../../../../components/CHComponents/CHModalBody';
import CHModalFooter from '../../../../components/CHComponents/CHModalFooter';
import CardSearcher from '../../../../components/CardSearcher';
import CHSelect from '../../../../components/CHComponents/CHSelect';
import CHInput from "../../../../components/CHComponents/CHInput";
import CHCheckbox from '../../../../components/CHComponents/CHCheckbox';
import CHTextarea from '../../../../components/CHComponents/CHTextarea';
import CHError from '../../../../components/CHComponents/CHError';
import { getLanguagesOptions, getCardConditionsOptions } from "../../../../utils/enumsService";
import { createCardSale } from '../../../../api/mtgSalesServiceApi';
import {
	CARD_TRADEABLE, CARD_NONTRADEABLE,
	CARD_TYPE_PROMO, CARD_TYPE_NONFOIL, CARD_TYPE_FOIL
} from '../../../../utils/constants';

const scryfall = require('../../../../api/scryfallApi');
const defaultMsg = {
	invalidPrice: 'Greater than 0.1',
	invalidQuantity: 'Greater than 0',
	invalidObservation: 'Max of 150 characters'
};

const SaleCreationModal = props => {
	const intl = useIntl();
	const { isOpen, onClose, onAccept, userId } = props;
	const [cancelSource, setCancelSource] = useState(axios.CancelToken.source());
	const [cardName, setCardName] = useState('');
	const [cardImage, setCardImage] = useState(null);
	const [prints, setPrints] = useState([]);
	const [setSelected, setSetSelected] = useState(null);
	const [print, setPrint] = useState(null);
	const [type, setType] = useState(null);
	const [language, setLanguage] = useState(null);
	const [condition, setCondition] = useState(null);
	const [quantity, setQuantity] = useState(1);
	const [price, setPrice] = useState(0.1);
	const [acceptsTrade, setAcceptsTrade] = useState(false);
	const [observation, setObservation] = useState('');
	const [showActionError, setShowActionError] = useState(false);
	const [actionError, setActionError] = useState(null);
	const [errors, setErrors] = useState({
		print: {isInvalid: false, message: ''},
		cardName: {isInvalid: false, message: ''},
		language: {isInvalid: false, message: ''},
		condition: {isInvalid: false, message: ''},
		quantity: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidQuantity', defaultMessage: defaultMsg.invalidQuantity})},
		price: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidPrice', defaultMessage: defaultMsg.invalidPrice})},
		observation: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidObservation', defaultMessage: defaultMsg.invalidObservation})}
	});

	const getPrints = () => {
		let mounted = true;
		const newCancelSource = axios.CancelToken.source();
		cancelSource.cancel();
		setCancelSource(newCancelSource);
		resetValues();
		scryfall.getCardByName(cardName, newCancelSource.token)
			.then(prints => {
				if(mounted) {
					if(prints.length === 0) {
						// TODO show that card was not found
					}else {
						setPrints(prints);
					}
				}
			});
		return () => {
			mounted = false;
			cancelSource.cancel('Cancelling on unmount');
		};
	};

	useEffect(getPrints, [cardName]);

	useEffect(() => {
		if(print) {
			setCardImage(print.image_uris ? print.image_uris.normal : print.card_faces[0].image_uris.normal);
		}else if(prints.length > 0) {
			setCardImage(prints[0].image_uris ? prints[0].image_uris.normal : prints[0].card_faces[0].image_uris.normal);
			setSetSelected({
				id: prints[0].id,
				label: prints[0].set_name,
				value: 0
			});
			setPrint(prints[0]);
		}else {
			setCardImage(null);
		}
	}, [prints, print]);

	const resetValues = () => {
		setPrints([]);
		setPrint(null);
		setSetSelected(null);
		setType(null);
		setLanguage(null);
		setCondition(null);
		setQuantity(1);
		setPrice(0.1);
		setObservation('');
		setShowActionError(false);
	};

	const getSets = () => {
		return prints.map((print, index) => ({
			id: print.id,
			label: print.set_name,
			value: index
		}));
	};

	const getTypes = () => {
		const types = [];
		if(print) {
			if(print.promo) {
				types.push({id: CARD_TYPE_PROMO, label: intl.formatMessage({id: 'mtg.promo', defaultMessage: 'Promo'}), value: 2});
			}else {
				print.nonfoil && types.push({id: CARD_TYPE_NONFOIL, label: intl.formatMessage({id: 'mtg.nonfoil', defaultMessage: 'Non foil'}), value: 0});
				print.foil && types.push({id: CARD_TYPE_FOIL, label: intl.formatMessage({id: 'mtg.foil', defaultMessage: 'Foil'}), value: 1});
			}
		}
		return types;
	};

	const onSetSelected = (set) => {
		setSetSelected(set);
		setType(null);
		if(!set) {
			setPrint(null);
		}else {
			setPrint(prints[set.value]);
		}
	};

	const changeObservation = (value) => {
		setObservation(value);
		setErrors({
			...errors,
			observation: {...errors.observation, isInvalid: value.length > 150}
		});
	};

	const validateInput = (input, isValid, newErrors) => {
		newErrors[input].isInvalid = !isValid;
		return isValid;
	};

	const validateSale = () => {
		const newErrors = {...errors};
		let isValid = true;
		isValid = isValid && validateInput('print', print, newErrors);
		isValid = isValid && validateInput('cardName', cardName.length > 0, newErrors);
		isValid = isValid && validateInput('language', language, newErrors);
		isValid = isValid && validateInput('condition', condition, newErrors);
		isValid = isValid && validateInput('quantity', quantity > 0, newErrors);
		isValid = isValid && validateInput('price', price > 0, newErrors);
		isValid = isValid && validateInput('observation', observation.length <= 150, newErrors);
		setErrors(newErrors);
		return isValid;
	};

	const onSave = () => {
		setShowActionError(false);
		if(validateSale()) {
			createCardSale({
				userId,
				cardId: print.id,
				cardName: print.name,
				cardSet: setSelected.label,
				cardType: type.id,
				cardLanguage: language.id,
				cardCondition: condition.id,
				quantity,
				price,
				cardTradeable: acceptsTrade ? CARD_TRADEABLE : CARD_NONTRADEABLE,
				observation
			})
				.then(sale => {
					setCardName('');
					resetValues();
					onAccept(sale.id);
				})
				.catch((error) => {
					setActionError(error);
					setShowActionError(true);
				});
		}
	};

	const closeModal = () => {
		setCardName('');
		resetValues();
		onClose();
	};

	return (
		<CHModal isOpen={isOpen} toggle={closeModal} className="SaleCreationModal">
			<CHModalHeader
				tittle={intl.formatMessage({id: 'tittle.createSale', defaultMessage: 'Create sale'})}
			/>
			<CHModalBody>
				<div className={(isMobile?"mobile-":"browser-")+"SaleCreationModal-cardImageDiv"}>
				{cardImage && (
					<img
						className={(isMobile?"mobile-":"browser-")+"SaleCreationModal-cardImage"}
						src={cardImage}
						alt="Not found"
						onError={e => {e.target.onerror = null; e.target.src = require('../../../../assets/images/mtg-card-not-found.jpg')}}
					/>
				)}
				</div>
				<div className="SaleCreationModal-form">
					<CardSearcher
						className="SaleCreationModal-cardSearcher"
						onCardSelected={name => setCardName(name)}
					/>
					<CHSelect
						id="saleCreationModal-set"
						className="SaleCreationModal-CHSelect"
						disabled={prints.length === 0}
						value={setSelected}
						options={getSets()}
						placeholder={intl.formatMessage({id: 'select.set', defaultMessage: 'Set...'})}
						onChange={option => onSetSelected(option)}
					/>
					<CHSelect
						id="saleCreationModal-type"
						className="SaleCreationModal-CHSelect"
						disabled={print == null}
						value={type}
						options={getTypes()}
						placeholder={intl.formatMessage({id: 'select.type', defaultMessage: 'Tipo...'})}
						onChange={option => setType(option)}
					/>
					<CHSelect
						id="saleCreationModal-language"
						className="SaleCreationModal-CHSelect"
						disabled={prints.length === 0}
						value={language}
						options={getLanguagesOptions().map(option => ({
							...option,
							label: intl.formatMessage({id: option.label, defaultMessage: option.label})
						}))}
						placeholder={intl.formatMessage({id: 'select.language', defaultMessage: 'Idioma...'})}
						onChange={option => setLanguage(option)}
					/>
					<CHSelect
						id="saleCreationModal-condition"
						className="SaleCreationModal-CHSelect"
						disabled={prints.length === 0}
						value={condition}
						options={getCardConditionsOptions()}
						placeholder={intl.formatMessage({id: 'select.condition', defaultMessage: 'Condition...'})}
						onChange={option => setCondition(option)}
					/>
					<div className="SaleCreationModal-CHInput-div">
						<label className="SaleCreationModal-label">
							{intl.formatMessage({id: 'general.quantity', defaultMessage: 'Quantity'})}
						</label>
						<CHInput
							id="saleCreationModal-quantity"
							className="SaleCreationModal-CHInput"
							disabled={prints.length === 0}
							type="int"
							min={1}
							value={quantity}
							onChange={value => setQuantity(value)}
							error={errors.quantity}
						/>
					</div>
					<div className="SaleCreationModal-CHInput-div">
						<label className="SaleCreationModal-label">
							{intl.formatMessage({id: 'general.price', defaultMessage: 'Price'})}(U$S)
						</label>
						<CHInput
							id="saleCreationModal-price"
							className="SaleCreationModal-CHInput"
							disabled={prints.length === 0}
							type="float"
							min={0.1}
							value={price}
							onChange={value => setPrice(value)}
							error={errors.price}
						/>
					</div>
					<CHCheckbox
						id="CHCheckboxAcceptsTrade"
						label={intl.formatMessage({id: 'general.acceptsTrade', defaultMessage: 'Accepts trade'})}
						checked={acceptsTrade}
						onChange={() => setAcceptsTrade(!acceptsTrade)}
					/>
					<CHTextarea
						id="saleCreationModal-observation"
						className="SaleCreationModal-CHTextarea"
						placeholder={intl.formatMessage({id: 'input.observation', defaultMessage: 'Observation...'})}
						value={observation}
						onChange={value => changeObservation(value)}
						error={errors.observation}
						disabled={prints.length === 0}
					/>
					<CHError
						id="saleCreationModal-error"
						isShown={showActionError}
						error={actionError}
					/>
				</div>
			</CHModalBody>
			<CHModalFooter
				type="save"
				acceptLabel={intl.formatMessage({id: 'general.create', defaultMessage: 'Create'})}
				onAccept={onSave}
				acceptDisabled={
					!print ||
					cardName.length === 0 ||
					!language ||
					!condition ||
					quantity <= 0 ||
					price < 0 ||
					observation.length > 150
				}
			/>
		</CHModal>
	);
};

SaleCreationModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onAccept: PropTypes.func.isRequired,
	userId: PropTypes.number.isRequired
};

export default SaleCreationModal;