import React, { useState } from 'react';
import './CardInfo.css';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import { isMobile } from 'react-device-detect';

import Icon from '../../components/Icon';
import CHButton from '../../components/CHComponents/CHButton';
import WishlistFormModal from './WishlistFormModal';
import { CHAlert, showAlert } from '../../components/CHComponents/CHAlert';
import { renderManaSymbols, renderOracleText } from '../../utils/stringParser';
import {getCardTypeById} from '../../utils/enumsService';
import { ALL_OPTION } from '../../utils/constants';

const CardInfo = props => {
	const intl = useIntl();
	const {card, type, isFetching} = props;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isWishlistAlertOpen, setIsWhishlistAlertOpen] = useState(false);

	const view = isMobile?'mobile':'browser';
	
	/* ---- FORMAT ---- */
	
	function getImage() {
		if(!card) {
			return '';
		}
		return card.image_uris ? card.image_uris.small : card.card_faces[0].image_uris.small;
	}
	
	function getArtist() {
		if(!card) {
			return '';
		}
		return card.artist ? card.artist : card.card_faces[0].artist;
	}
	
	function getMana() {
		if(!card) {
			return '';
		}
		let manaCost;
		if(card.card_faces) {
			manaCost = `${card.card_faces[0].mana_cost} // ${card.card_faces[1].mana_cost}`;
		}else {
			manaCost = card.mana_cost;
		}
		return renderManaSymbols(card.id, manaCost, 15, 15, "cardInfo-info-mana-icon");
	}
	
	function getOracleText() {
		if(!card) {
			return '';
		}
		const oracleText = card.card_faces ? card.card_faces[0].oracle_text : card.oracle_text;
		return renderOracleText(oracleText, 'cardInfo-info-mana-icon');
	}
	
	/* ---- WISHLIST ---- */
	
	const addToWishlist = (filters) => {
		setIsModalOpen(false);
		setIsWhishlistAlertOpen(true);
		showAlert(() => setIsWhishlistAlertOpen(false));
	};
	
	/* ---- DOM ---- */

	return (
		<div className="cardInfo-div">
			{!isFetching && (
				<img
					src={getImage()}
					alt="Not found"
					onError={e => {e.target.onerror = null; e.target.src = require('../../assets/images/mtg-card-not-found.jpg')}}
				/>
			)}
			<div 
				className={`${view}-cardInfo`}
			>
				<div className={`${view}-cardInfo-info-div`} >
					<strong className="cardInfo-info-strong">
						{intl.formatMessage({id: 'game.card', defaultMessage: 'Card'})}:
					</strong>{card ? card.name : ''}<br />
					<strong className="cardInfo-info-strong">
						{intl.formatMessage({id: 'mtg.mana', defaultMessage: 'Mana'})}:
					</strong>{getMana()}<br />
					<strong className="cardInfo-info-strong">
						{intl.formatMessage({id: 'game.set', defaultMessage: 'Set'})}:
					</strong>{card ? card.set_name : ''}<br/>
					<strong className="cardInfo-info-strong">
						{intl.formatMessage({id: 'game.rarity', defaultMessage: 'Rarity'})}:
					</strong>{card ? card.rarity : ''}<br/>
					<strong className="cardInfo-info-strong">
						{intl.formatMessage({id: 'game.type', defaultMessage: 'Type'})}:
					</strong>{type && getCardTypeById(type).label}<br/>
					<strong className="cardInfo-info-strong">
						{intl.formatMessage({id: 'game.artist', defaultMessage: 'Artist'})}:
					</strong>{getArtist()}
				</div>
				<div className={`${view}-cardInfo-info-div-2`}>
					<strong className="cardInfo-info-strong">
						{intl.formatMessage({id: 'game.type', defaultMessage: 'Type'})}:
					</strong>{card ? card.type_line : ''}<br/>
					<strong className="cardInfo-info-strong">
						{intl.formatMessage({id: 'general.text', defaultMessage: 'Text'})}:
					</strong>{getOracleText()}<br/>
				</div>
				<div className={`${view}-cardInfo-info-div-3`}>
						<CHButton
						className="cardInfo-wishlist-button"
						onClick={() => setIsModalOpen(!isModalOpen)}
						label={(<Icon type="other" name="star" width="18" height="18" color="white"/>)}
						disabled={!card}
						/>
				</div>
			</div>
			<WishlistFormModal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(!isModalOpen)}
				onAccept={addToWishlist}
				card={card}
				cardOptions={{
					set: [
						{...ALL_OPTION, label: intl.formatMessage({id: 'general.allF', defaultMessage: 'All'})},
						{id: card ? card.set_name : '', label: card ? card.set_name : '', value: card ? card.set_name : ''}
					],
					type: [
						{...ALL_OPTION, label: intl.formatMessage({id: 'general.allM', defaultMessage: 'All'})},
						{id: type, label: type && getCardTypeById(type).label, value: type}
					]
				}}
			/>
			<CHAlert
				isOpen={isWishlistAlertOpen}
				CHStyle="accept"
				label={intl.formatMessage({id: 'message.cardAddedToWishlist', defaultMessage: 'Card added to wishlist'})}
			/>
		</div>
	);
};

CardInfo.propTypes = {
	// scryfall card object
	card: PropTypes.object,
	type: PropTypes.oneOf(['nonFoil', 'foil', 'promo', '']).isRequired,
	isFetching: PropTypes.bool.isRequired
};

CardInfo.defaultProps = {
	card: null
};

export default CardInfo;
