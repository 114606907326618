import React, {useEffect, useState} from 'react';
import './OwnProfile.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { MobileView, BrowserView } from 'react-device-detect';

import UserInfo from './UserInfo';
import SalesTable from './userTables/userSales/Table';
import SalesHistoryTable from './userTables/userSalesHistory/Table';
import PurchasesHistoryTable from './userTables/userPurchasesHistory/Table';
import SalesInProgressTable from './userTables/userSalesInProgress/Table';
import PurchasesInProgressTable from './userTables/userPurchasesInProgress/Table';
import RatingsTable from './userTables/userRatings/Table';
import WishlistTable from './userTables/userWishlist/Table';
import NotificationsTable from './userTables/userNotifications/Table';
import CHCheckbox from '../../components/CHComponents/CHCheckbox';
import CHSelect from '../../components/CHComponents/CHSelect';


const OwnProfile = props => {
	const intl = useIntl();
	const { user } = props;
	const [selectedTable, setSelectedTable] = useState('sales');
	const [table, setTable] = useState(<SalesTable isOwnProfile={true} userId={user.id}/>);


	const [value, setValue] = useState({id: 'sales', label:intl.formatMessage({id: 'general.sales', defaultMessage: 'Sales'}), value: 'sales'});
	const selectOptions = [
		{id: 'sales', label:intl.formatMessage({id: 'general.sales', defaultMessage: 'Sales'}), value: 'sales'},
		{id: 'salesHistory', label:intl.formatMessage({id: 'general.salesHistory', defaultMessage: 'Sales history'}), value: 'salesHistory'},
		{id: 'purchasesHistory', label:intl.formatMessage({id: 'general.purchasesHistory', defaultMessage: 'Purchases history'}), value: 'purchasesHistory'},
		{id: 'salesInProgress', label:intl.formatMessage({id: 'general.salesInProgress', defaultMessage: 'Sales in progress'}), value: 'salesInProgress'},
		{id: 'purchasesInProgress', label:intl.formatMessage({id: 'general.purchasesInProgress', defaultMessage: 'Purchases in progress'}), value: 'purchasesInProgress'},
		{id: 'ratings', label:intl.formatMessage({id: 'general.ratingsComments', defaultMessage: 'Ratings/Comments'}), value: 'ratings'},
		{id: 'wishlist', label:intl.formatMessage({id: 'general.wishlist', defaultMessage: 'Wishlist'}), value: 'wishlist'},
		{id: 'notifications', label:intl.formatMessage({id: 'general.notifications', defaultMessage: 'Notifications'}), value: 'notifications'}
	];
	const setSelectedOption = (option)=> {
	setValue(option)
	setSelectedTable(option.value)
	}
	/* ---- RENDER ---- */
	
	const renderTable = () => {
		if(selectedTable === 'sales') {
			setTable(<SalesTable isOwnProfile={true} userId={user.id} />);
		}else if(selectedTable === 'salesHistory') {
			setTable(<SalesHistoryTable userId={user.id} />);
		}else if(selectedTable === 'purchasesHistory') {
			setTable(<PurchasesHistoryTable userId={user.id} />);
		}else if(selectedTable === 'salesInProgress') {
			setTable(<SalesInProgressTable userId={user.id} />);
		}else if(selectedTable === 'purchasesInProgress') {
			setTable(<PurchasesInProgressTable userId={user.id} />);
		}else if(selectedTable === 'ratings') {
			setTable(<RatingsTable/>);
		}else if(selectedTable === 'wishlist') {
			setTable(<WishlistTable userId={user.id} />);
		}else if(selectedTable === 'notifications') {
			setTable(<NotificationsTable/>);
		}
	};

	useEffect(renderTable, [selectedTable, user]);


	/* ---- DOM ---- */
	
	return (
		<div>
			<UserInfo
				isOwnProfile
				user={user}
			/>
			<div className="ownProfile-tableButtons-div">
				<BrowserView>
					<CHCheckbox
						className="ownProfile-tableCheckbox"
						boxClassName="ownProfile-tableCheckbox-box"
						CHStyle="box"
						id="salesCheckbox"
						value="salesCheckbox"
						label={intl.formatMessage({id: 'general.sales', defaultMessage: 'Sales'})}
						checked={selectedTable === 'sales'}
						onChange={() => setSelectedTable('sales')}
					/>
					<CHCheckbox
						className="ownProfile-tableCheckbox"
						boxClassName="ownProfile-tableCheckbox-box"
						CHStyle="box"
						id="salesHistoryCheckbox"
						value="salesHistoryCheckbox"
						label={intl.formatMessage({id: 'general.salesHistory', defaultMessage: 'Sales history'})}
						checked={selectedTable === 'salesHistory'}
						onChange={() => setSelectedTable('salesHistory')}
					/>
					<CHCheckbox
						className="ownProfile-tableCheckbox"
						boxClassName="ownProfile-tableCheckbox-box"
						CHStyle="box"
						id="purchasesHistoryCheckbox"
						value="purchasesHistoryCheckbox"
						label={intl.formatMessage({id: 'general.purchasesHistory', defaultMessage: 'Purchases history'})}
						checked={selectedTable === 'purchasesHistory'}
						onChange={() => setSelectedTable('purchasesHistory')}
					/>
					<CHCheckbox
						className="ownProfile-tableCheckbox"
						boxClassName="ownProfile-tableCheckbox-box"
						CHStyle="box"
						id="salesInProgressCheckbox"
						value="salesInProgressCheckbox"
						label={intl.formatMessage({id: 'general.salesInProgress', defaultMessage: 'Sales in progress'})}
						checked={selectedTable === 'salesInProgress'}
						onChange={() => setSelectedTable('salesInProgress')}
					/>
					<CHCheckbox
						className="ownProfile-tableCheckbox"
						boxClassName="ownProfile-tableCheckbox-box"
						CHStyle="box"
						id="purchasesInProgressCheckbox"
						value="purchasesInProgressCheckbox"
						label={intl.formatMessage({id: 'general.purchasesInProgress', defaultMessage: 'Purchases in progress'})}
						checked={selectedTable === 'purchasesInProgress'}
						onChange={() => setSelectedTable('purchasesInProgress')}
					/>
					<CHCheckbox
						className="ownProfile-tableCheckbox"
						boxClassName="ownProfile-tableCheckbox-box"
						CHStyle="box"
						id="ratingsCheckbox"
						value="ratingsCheckbox"
						label={intl.formatMessage({id: 'general.ratingsComments', defaultMessage: 'Ratings/Comments'})}
						checked={selectedTable === 'ratings'}
						onChange={() => setSelectedTable('ratings')}
					/>
					<CHCheckbox
						className="ownProfile-tableCheckbox"
						boxClassName="ownProfile-tableCheckbox-box"
						CHStyle="box"
						id="wishlistCheckbox"
						value="wishlistCheckbox"
						label={intl.formatMessage({id: 'general.wishlist', defaultMessage: 'Wishlist'})}
						checked={selectedTable === 'wishlist'}
						onChange={() => setSelectedTable('wishlist')}
					/>
					<CHCheckbox
						className="ownProfile-tableCheckbox"
						boxClassName="ownProfile-tableCheckbox-box"
						CHStyle="box"
						id="notificationsCheckbox"
						value="notificationsCheckbox"
						label={intl.formatMessage({id: 'general.notifications', defaultMessage: 'Notifications'})}
						checked={selectedTable === 'notifications'}
						onChange={() => setSelectedTable('notifications')}
					/>
				</BrowserView>
				<MobileView>
					<CHSelect
						className="otherProfile-CHSelect"
						id="otherProfile-CHSelect"
						value={value}
						options={selectOptions}
						onChange={option => setSelectedOption(option) }
					/>
				</MobileView>
			</div>
			<div className="ownProfile-tableArea-div">
				{table}
			</div>
		</div>
	);
};

OwnProfile.propTypes = {
	user: PropTypes.shape({
		id: PropTypes.number.isRequired,
		username: PropTypes.string,
		password: PropTypes.string,
		name: PropTypes.string,
		surname: PropTypes.string,
		email: PropTypes.string,
		country: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		state: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		city: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		userStatus: PropTypes.string,
		userShipping: PropTypes.string,
		userDelivery: PropTypes.string,
		reputation: PropTypes.number,
		avatarId: PropTypes.string,
		avatarType: PropTypes.string,
		observation: PropTypes.string
	}).isRequired
};

export default OwnProfile;
