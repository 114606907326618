import React, { useEffect, useState } from 'react';
import './Table.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import axios from 'axios';

import { getSaleHistories } from '../../../../api/mtgSalesServiceApi';
import { SALEHISTORY_CANCELEDF, SALEHISTORY_FINISHEDF } from '../../../../utils/constants';
import { getCardTypeById, getCardConditionById } from '../../../../utils/enumsService';
import CHTable from '../../../../components/CHComponents/CHTable';
import CHSpinner from '../../../../components/CHComponents/CHSpinner';


const Table = props => {
	const intl = useIntl();
	const { userId } = props;
	const [isFetching, setIsFetching] = useState(false);
	const [rows, setRows] = useState([]);
	const [filteredRows, setFilteredRows] = useState([]);

	/* ---- COLUMS ---- */

	const [columns, setColumns] = useState([
		{id: 0, label: intl.formatMessage({id: 'general.name', defaultMessage: 'Name'})},
		{id: 1, label: intl.formatMessage({id: 'game.set', defaultMessage: 'Set'})},
		{id: 2, label: intl.formatMessage({id: 'game.type', defaultMessage: 'Type'})},
		{id: 3, label: intl.formatMessage({id: 'general.language', defaultMessage: 'Language'})},
		{id: 4, label: intl.formatMessage({id: 'game.condition', defaultMessage: 'Condition'})},
		{id: 5, label: intl.formatMessage({id: 'general.quantity', defaultMessage: 'Quantity'})},
		{id: 6, label: intl.formatMessage({id: 'general.price', defaultMessage: 'Price'})},
		{id: 7, label: intl.formatMessage({id: 'general.buyer', defaultMessage: 'Buyer'})},
		{id: 8, label: intl.formatMessage({id: 'general.date', defaultMessage: 'Date'})},
		{id: 9, label: intl.formatMessage({id: 'general.ratingGivenRecieved', defaultMessage: 'Rating given/recieved'})},
		{id: 10, label: intl.formatMessage({id: 'general.status', defaultMessage: 'Status'})}
	]);

	/* ---- LIFE CYCLE ---- */

	const fetchSaleHistories = () => {
		let mounted = true;
		const cancelSource = axios.CancelToken.source();
		if(userId > 0) {
			if(mounted) setIsFetching(true);
			getSaleHistories(
				userId,
				[SALEHISTORY_CANCELEDF, SALEHISTORY_FINISHEDF],
				true,
				cancelSource.token
			)
				.then(saleHistories => {
					if(mounted) {
						const newRows = saleHistories.map(saleHistory => ({
							id: saleHistory.id,
							cells: [
								{content: saleHistory.cardSale.cardName, value: saleHistory.cardSale.cardName},
								{content: saleHistory.cardSale.cardSet, value: saleHistory.cardSale.cardSet},
								{content: getCardTypeById(saleHistory.cardSale.cardType).label, value: getCardTypeById(saleHistory.cardSale.cardType).value},
								{
									content: intl.formatMessage({id: saleHistory.cardSale.cardLanguage, defaultMessage: saleHistory.cardSale.cardLanguage}),
									value: intl.formatMessage({id: saleHistory.cardSale.cardLanguage, defaultMessage: saleHistory.cardSale.cardLanguage})
								},
								{content: getCardConditionById(saleHistory.cardSale.cardCondition).label, value: getCardConditionById(saleHistory.cardSale.cardCondition).label},
								{content: saleHistory.quantity, value: saleHistory.quantity},
								{content: saleHistory.price, value: saleHistory.price},
								{content: saleHistory.userUsername, value: saleHistory.userUsername},
								{content: saleHistory.dateFinish, value: saleHistory.dateFinish, limitWidth: true},
								{content: `${saleHistory.buyerRating} / ${saleHistory.sellerRating}`, value: saleHistory.sellerRating},
								{
									content: intl.formatMessage({id: saleHistory.saleHistoryStatus, defaultMessage: saleHistory.saleHistoryStatus}),
									value: intl.formatMessage({id: saleHistory.saleHistoryStatus, defaultMessage: saleHistory.saleHistoryStatus})
								}
							]
						}));
						setIsFetching(false);
						setRows(newRows);
						setFilteredRows(newRows);
					}
				})
				.catch(error => mounted && setIsFetching(false));
		}

		return () => {
			mounted = false;
			cancelSource.cancel('Cancelling on unmount');
		};
	};

	useEffect(fetchSaleHistories, [userId]);

	return (
		<div>
			<CHTable tableClassName="userSalesHistory-table"
				columns={columns}
				rows={filteredRows}
			/>
			<CHSpinner id="userSalesHistory-table-spinner" isActive={isFetching} />
		</div>
	);
};

Table.propTypes = {
	userId: PropTypes.number.isRequired
};

export default Table;
