import React from 'react';
import './CHCheckbox.css';
import PropTypes from 'prop-types';
import {isMobile} from 'react-device-detect';


const CHCheckbox = props => {
	const {id, value, checked, onChange, CHStyle, className, boxClassName, label, disabled} = props;

	const getStyles = () => {
		let styles = ' CHCheckbox-base';
		styles += CHStyle ? ` CHCheckbox-${CHStyle}` : ' CHCheckbox-brand';
		if(className) {
			styles += ` ${className}`;
		}
		if(disabled) {
			styles += ` CHCheckbox-disabled`;
		}
		if(isMobile) {
			styles += ` CHCheckbox-mobile`;
		}
		return styles;
	};

	const getLabelStyles = () => {
		let styles = ' CHCheckbox-label-base';
		styles += CHStyle ? ` CHCheckbox-label-${CHStyle}` : ' CHCheckbox-label-brand';

		return styles;
	};

	return (
		<div className={getStyles()}>
			<input
				className={CHStyle === 'box'? (isMobile?'CHCheckbox-checkbox-mobileBox':'CHCheckbox-checkbox-box'):isMobile?'CHCheckbox-checkbox-mobileBase':'CHCheckbox-checkbox-base'}
				type="checkbox"
				key={id}
				id={id}
				checked={checked}
				value={value}
				onChange={event => onChange({
					...event,
					target: {
						...event.target,
						value
					}
				})}
			/>
			{CHStyle !== 'box' ?
				(<label htmlFor={id} className={getLabelStyles()}>{label}</label>)
				:
				(<label htmlFor={id} className={boxClassName}>{label}</label>)
			}
		</div>

	);

};

CHCheckbox.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	checked: PropTypes.bool.isRequired,
	// onChange(event)
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	CHStyle: PropTypes.oneOf(['brand', 'box']),
	className: PropTypes.string,
	boxClassName: PropTypes.string
};

export default CHCheckbox;