import React from 'react';
import PropTypes from 'prop-types';
import './SaleEditionColumn.css';

import Icon from '../Icon';
import {getCardSaleStatusByCode} from '../../utils/enumsService';


const SaleEditionCell = props => {
	const {tdClassName, row, args} = props;

	return (
		<td className={tdClassName + ' SaleEditionColumn-cell-td'} key={row.id + 'saleEdition'}>
			<span
				className="SaleEditionColumn-action-span"
				onClick={() => args.onChangeStatus(row.id)}
			>
				<Icon
					type="other"
					name={row.customData.cardSaleStatus === getCardSaleStatusByCode('OPEN').id ? 'pause' : 'play'}
					width="18"
					height="18"
				/>
			</span>
			<span
				className="SaleEditionColumn-action-span"
				onClick={() => args.onEdit(row.id)}
			>
				<Icon
					type="other"
					name="pencil"
					width="18"
					height="18"
				/>
			</span>
			<span
				className="SaleEditionColumn-action-span"
				onClick={() => args.onDelete(row.id)}
			>
				<Icon
					type="other"
					name="trash"
					width="18"
					height="18"
				/>
			</span>
		</td>
	);
};

SaleEditionCell.propTypes = {
	tdClassName: PropTypes.string.isRequired,
	row: PropTypes.object.isRequired,
	args: PropTypes.shape({
		onChangeStatus: PropTypes.func.isRequired,
		onEdit: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
	}).isRequired
};

export {
	SaleEditionCell
};