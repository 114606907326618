import { getCardByName } from '../../api/scryfallApi';
import { GAME_MTG } from '../../utils/constants';

const FETCHING_PRINTS = 'FETCHING_PRINTS';
const FETCH_PRINTS = 'FETCH_PRINTS';
const ADD_PRINTS = 'ADD_PRINTS';

const fetchingPrints = (cardName) => ({
	type: FETCHING_PRINTS,
	payload: cardName
});

const addPrints = (prints, cardName) => ({
	type: ADD_PRINTS,
	prints,
	cardName
});

const fetchPrints = (game, cardName, cancelToken, searchAlternative = false) => {
	return (dispatch) => {
		dispatch(fetchingPrints(cardName));
		if(game === GAME_MTG) {
			return getCardByName(cardName, cancelToken, searchAlternative)
				.then(prints => dispatch(addPrints(prints, cardName)))
				.catch(error => dispatch(addPrints([], cardName)));
		}
	};
};

export {
	FETCHING_PRINTS, fetchingPrints,
	FETCH_PRINTS, fetchPrints,
	ADD_PRINTS, addPrints
};
