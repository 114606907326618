import React, { useState, useEffect } from 'react';
import './Cart.css';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { getCardImageUrl } from '../../api/scryfallApi';
import mtgCardNotFound from '../../assets/images/mtg-card-not-found.jpg';
import { fetchCart } from '../../redux/actions/sessionActions';
import { getCardTypeById, getLanguageByCode, getCardConditionById } from '../../utils/enumsService';
import { updateSaleHistories } from '../../api/mtgSalesServiceApi';
import { floatToString } from '../../utils/stringParser';
import { SALEHISTORY_PENDING } from '../../utils/constants';
import SaleHistoryCartEditor from '../../components/SaleHistoryCartEditor';
import CHButton from '../../components/CHComponents/CHButton';
import CHError from '../../components/CHComponents/CHError';
import CHSpinner from '../../components/CHComponents/CHSpinner';
import { DeliveryMethodsCellContent } from '../../components/customColumns/DeliveryMethodsColumn';
import { isMobile } from 'react-device-detect';

const view = isMobile?'mobile':'browser';
const cartSelector = store => store.session.cart;
const TIMEOUT_MS = 150;

const Cart = props => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const cart = useSelector(cartSelector);
	const [entries, setEntries] = useState([]);
	const [isEditionDisabled, setIsEditionDisabled] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [isCartValid, setIsCartValid] = useState(true);
	const [totalPrice, setTotalPrice] = useState(0);
	const [showConfirmationError, setShowConfirmationError] = useState(false);
	const [confirmationError, setConfirmationError] = useState(null);

	/* ---- LIFE CYCLE ---- */

	const setValues = (newEntries, validCart, total) => {
		setEntries(newEntries);
		setIsCartValid(validCart);
		setTotalPrice(total);
	};

	const processSaleHistories = () => {
		const shouldFetchImages = entries.length !== cart.length;
		const newEntries = [];
		const imageApiCalls = [];
		let validCart = true;
		let total = 0;

		cart.forEach((saleHistory, index) => {
			newEntries.push({
				id: saleHistory.id,
				seller: saleHistory.cardSale.user.username,
				sellerCountry: saleHistory.cardSale.user.country.name,
				sellerState: saleHistory.cardSale.user.state.name,
				sellerCity: saleHistory.cardSale.user.city.name,
				sellerShipping: saleHistory.cardSale.user.userShipping,
				sellerDelivery: saleHistory.cardSale.user.userDelivery,
				sellerObservation: saleHistory.cardSale.user.observation,
				cardName: saleHistory.cardSale.cardName,
				cardSet: saleHistory.cardSale.cardSet,
				cardType: saleHistory.cardSale.cardType,
				cardLanguage: saleHistory.cardSale.cardLanguage,
				cardCondition: saleHistory.cardSale.cardCondition,
				cardSaleQuantity: saleHistory.cardSale.quantity,
				cardObservation: saleHistory.cardSale.observation,
				price: saleHistory.cardSale.price,
				quantity: saleHistory.quantity,
				hasStock: saleHistory.cardSale.quantity >= saleHistory.quantity,
				hadError: false,
				error: null,
				imageUrl: shouldFetchImages ? null : entries[index].imageUrl
			});
			const subTotal = parseFloat((saleHistory.cardSale.price * saleHistory.quantity).toFixed(2));
			total = parseFloat((total + subTotal).toFixed(2));
			validCart = validCart && (saleHistory.cardSale.quantity >= saleHistory.quantity);
			if(shouldFetchImages) {
				imageApiCalls.push(new Promise((resolve, reject) => {
					setTimeout(() => {
						getCardImageUrl(saleHistory.cardSale.cardId, 'small')
							.then(url => resolve(url))
							.catch(error => resolve(''));
					}, index * TIMEOUT_MS);
				}));
			}
		});

		if(shouldFetchImages) {
			setIsFetching(true);
			Promise.all(imageApiCalls)
				.then(results => {
					results.forEach((url, index) => newEntries[index].imageUrl = url);
					setIsFetching(false);
					setValues(newEntries, validCart, total);
				})
				.catch(error => {
					newEntries.forEach(entry => entry.imageUrl = '');
					setIsFetching(false);
					setValues(newEntries, validCart, total);
				});
		}else {
			setValues(newEntries, validCart, total);
		}
	};

	useEffect(processSaleHistories, [cart]);

	/* ---- RENDER ---- */

	const onEdition = () => {
		setIsEditionDisabled(false);
		dispatch(fetchCart());
	};

	const onError = (saleHistoryId, error) => {
		const newEntries = [...entries];
		for(let entry of newEntries) {
			if(entry.id === saleHistoryId) {
				entry.hadError = true;
				entry.error = error;
				break;
			}
		}
		setEntries(newEntries);
	};

	const onConfirmation = () => {
		const saleHistories = entries.map(entry => ({
			id: entry.id,
			price: entry.price,
			saleHistoryStatus: SALEHISTORY_PENDING
		}));
		setShowConfirmationError(false);
		setConfirmationError(null);
		updateSaleHistories(saleHistories)
			.then(result => dispatch(fetchCart()))
			.catch(error => {
				dispatch(fetchCart());
				setShowConfirmationError(true);
				setConfirmationError(error);
			});
	};

	const renderEntry = entry => {
		return (
			<div className={`${view}-cart-entry-section`} key={`cart-entry-${entry.id}`}>
				<CHError
					className={`cart-entry-error`}
					id={`entry-${entry.id}-error`}
					isShown={!entry.hasStock || entry.hadError}
					error={entry.error}
					altMessage={!entry.hasStock ? intl.formatMessage({id: 'error_outOfStock', defaultMessage: 'Out of stock'}) : null}
				/>
				<img
					className={`${view}-cart-entry-img`}
					src={entry.imageUrl}
					alt="Not found"
					onError={e => {e.target.onerror = null; e.target.src = mtgCardNotFound}}
				/>
				<div className={`${view}-cart-div-info`}>
					<div className={`${view}-cart-entry-info`}>
						<label className={`cart-entry-info-label`}>
							<strong className="cart-entry-info-strong">
								{intl.formatMessage({id: 'game.card', defaultMessage: 'Card'})} :
							</strong>
							{entry.cardName}
						</label>
						<label className={`cart-entry-info-label`}>
							<strong className="cart-entry-info-strong">
								{intl.formatMessage({id: 'game.set', defaultMessage: 'Set'})} :
							</strong>
							{entry.cardSet}
						</label>
						<label className={`cart-entry-info-label`}>
							<strong className="cart-entry-info-strong">
								{intl.formatMessage({id: 'game.type', defaultMessage: 'Type'})} :
							</strong>
							{getCardTypeById(entry.cardType).label}
						</label>
						<label className={`cart-entry-info-label`}>
							<strong className="cart-entry-info-strong">
								{intl.formatMessage({id: 'general.language', defaultMessage: 'Language'})} :
							</strong>
							{intl.formatMessage({id: getLanguageByCode(entry.cardLanguage).label, defaultMessage: 'Condition'})}
						</label>
						<label className={`cart-entry-info-label`}>
							<strong className="cart-entry-info-strong">
								{intl.formatMessage({id: 'game.condition', defaultMessage: 'Condition'})} :
							</strong>{getCardConditionById(entry.cardCondition).label}
						</label>
						<label className={`cart-entry-info-label-observation`}>
							<strong className="cart-entry-info-strong">
								{intl.formatMessage({id: 'general.observation', defaultMessage: 'Observation'})} :
							</strong>
							{` ${entry.cardObservation}`}
						</label>
					</div>
					<div className={`${view}-cart-entry-info`}>
						<label className={`cart-entry-info-label`}>
							<strong className="cart-entry-info-strong">
								{intl.formatMessage({id: 'general.seller', defaultMessage: 'Seller'})} :
							</strong>
							{entry.seller}
						</label>
						<label className={`cart-entry-info-label`}>
							<strong className="cart-entry-info-strong">
								{intl.formatMessage({id: 'general.country', defaultMessage: 'Country'})} :
							</strong>
							{entry.sellerCountry}
						</label>
						<label className={`cart-entry-info-label`}>
							<strong className="cart-entry-info-strong">
								{intl.formatMessage({id: 'general.state', defaultMessage: 'State'})} :
							</strong>
							{entry.sellerState}
						</label>
						<label className={`cart-entry-info-label`}>
							<strong className="cart-entry-info-strong">
								{intl.formatMessage({id: 'general.city', defaultMessage: 'City'})} :
							</strong>
							{entry.sellerCity}
						</label>
						<strong className="cart-entry-info-strong">
							{intl.formatMessage({id: 'general.shippingOptions', defaultMessage: 'Delivery methods'})} :
						</strong> <br/>
						<DeliveryMethodsCellContent
							id={`cart-entry-${entry.id}`}
							shipping={entry.sellerShipping}
							delivery={entry.sellerDelivery}
						/>
						<label className={`cart-entry-info-label-observation`}>
							<strong className="cart-entry-info-strong">
								{intl.formatMessage({id: 'general.observation', defaultMessage: 'Observation'})} :
							</strong>
							{` ${entry.sellerObservation}`}
						</label>
					</div>
				</div>	
				<div className={`${view}-cart-entry-saleInfo`}>
					<div className={`${view}-cart-entry-info-price`}>
						<label className={`cart-entry-info-label`}>
							<strong className="cart-entry-info-strong">
								{intl.formatMessage({id: 'general.price', defaultMessage: 'Price'})} :
							</strong>
							{(entry.price).toFixed(2)}
						</label>
						<label className={`cart-entry-info-label`}>
							<strong className="cart-entry-info-strong">
								{intl.formatMessage({id: 'general.total', defaultMessage: 'Total'})} :
							</strong>
							{(entry.quantity * entry.price).toFixed(2)}
						</label>
					</div>
					<SaleHistoryCartEditor
						className={`${view}-cart-entry-cartEditor`}
						saleHistoryId={entry.id}
						saleHistoryQuantity={entry.quantity}
						cardSaleQuantity={entry.cardSaleQuantity}
						onEdition={onEdition}
						onError={onError}
						isDisabled={isEditionDisabled}
						disableEdition={() => setIsEditionDisabled(true)}
						enableEdition={() => setIsEditionDisabled(false)}
					/>
				</div>
			</div>
		);
	};

	return (
		<div className={`${view}-cart-main-div`}>
			<CHSpinner
				id="cart-entries-spinner"
				isActive={isFetching}
			/>
			{!isFetching && (
				<div className={`cart-entries-section`}>
					{cart.length > 0
						? entries.map(entry => renderEntry(entry))
						: (
							<label className={`cart-entries-empty-label`}>
								{intl.formatMessage({id: 'message.emptyCart', defaultMessage: 'Purchase\'s mailbox is empty'})}
							</label>
						)
					}
				</div>
			)}
			<strong className={`${view}-cart-totalPrice-strong`}>
				{intl.formatMessage(
					{id: 'general.totalPriceIs', defaultMessage: 'Total price is $ {price}'},
					{price: floatToString(totalPrice)})
				}
			</strong>
			<CHError
				id="cart-confirmation-error"
				className={`cart-confirmation-error`}
				isShown={showConfirmationError}
				error={confirmationError}
			/>
			<CHButton
				className={`${view}-cart-confirmation-btn`}
				CHStyle="accept"
				id="cart-confirmation-btn"
				label={intl.formatMessage({id: 'general.confirmPurchase', defaultMessage: 'Confirm purchase'})}
				onClick={onConfirmation}
				disabled={!isCartValid || entries.length === 0}
			/>
		</div>
	);
};

export default Cart;
