import React, {Fragment} from 'react';
import './CHModalFooter.css';
import PropTypes from 'prop-types';
import CHButton from "./CHButton";

const CHModalFooter = props => {
	const {
		type, cancelLabel, acceptLabel, cancelStyle, acceptStyle,
		onCancel, onAccept, acceptDisabled, className, CHStyle
	} = props;

	const getStyles = () => {
		let styles = 'CHModalFooter-base';
		styles += CHStyle ? ` CHModalFooter-${CHStyle}` : ' CHModalFooter-brand';
		if(className) {
			styles += ` ${className}`;
		}
		return styles;
	};

	function renderButtons() {
		if(type === 'confirmation') {
			return (
				<Fragment>
					<CHButton
						className="CHModalFooter-button-base CHModalFooter-button-cancel"
						CHStyle={cancelStyle ? cancelStyle : 'brand'}
						label={cancelLabel}
						onClick={onCancel}
					/>
					<CHButton
						className="CHModalFooter-button-base CHModalFooter-button-accept"
						CHStyle={acceptStyle ? acceptStyle : 'accept'}
						label={acceptLabel}
						onClick={onAccept}
						disabled={acceptDisabled}
					/>
				</Fragment>
			);
		}
		if(type === 'save') {
			return (
				<CHButton
					className="CHModalFooter-button-base CHModalFooter-button-accept"
					CHStyle={acceptStyle ? acceptStyle : 'accept'}
					label={acceptLabel}
					onClick={onAccept}
					disabled={acceptDisabled}
				/>
			);
		}
	}

	return (
		<div className={getStyles()}>
			{type ?
				renderButtons()
			:
				props.children
			}
		</div>
	);
};

CHModalFooter.propTypes = {
	type: PropTypes.oneOf(['confirmation', 'save']),
	// type = confirmation/save
	acceptLabel: PropTypes.node,
	acceptStyle: PropTypes.oneOf(['brand', 'accept', 'transparent', 'warning']), // CHButton styles
	onAccept: PropTypes.func,
	// type = confirmation
	cancelLabel: PropTypes.node,
	cancelStyle: PropTypes.oneOf(['brand', 'accept', 'transparent', 'warning']), // CHButton styles
	onCancel: PropTypes.func,
	acceptDisabled: PropTypes.bool,
	CHStyle: PropTypes.oneOf(['brand']),
	className: PropTypes.string
};

export default CHModalFooter;