import React, { useState, useEffect } from 'react';
import './Table.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import axios from 'axios';

import { getSaleHistoriesUser } from '../../../../api/mtgSalesServiceApi';
import { SALEHISTORY_PENDING, SALEHISTORY_CONFIRMEDF, SALEHISTORY_PENDING_CLOSURE } from '../../../../utils/constants';
import {getCardConditionById, getCardTypeById} from "../../../../utils/enumsService";
import { SaleHistoryStatusCell } from '../../../../components/customColumns/SaleHistoryStatusColumn';
import CHTable from '../../../../components/CHComponents/CHTable';
import CHSpinner from '../../../../components/CHComponents/CHSpinner';
import {SaleHistoryStatusEditor} from '../common/SaleHistoryStatusEditor';


const Table = props => {
	const intl = useIntl();
	const { userId } = props;
	const [isFetching, setIsFetching] = useState(false);
	const [rows, setRows] = useState([]);
	const [filteredRows, setFilteredRows] = useState([]);
	const [saleHistoriesToChange, setSaleHistoriesToChange] = useState([]);

	/* ---- COLUMS ---- */

	const [columns, setColumns] = useState([
		{id: 0, label: intl.formatMessage({id: 'general.name', defaultMessage: 'Name'})},
		{id: 1, label: intl.formatMessage({id: 'game.set', defaultMessage: 'Set'})},
		{id: 2, label: intl.formatMessage({id: 'game.type', defaultMessage: 'Type'})},
		{id: 3, label: intl.formatMessage({id: 'general.language', defaultMessage: 'Language'})},
		{id: 4, label: intl.formatMessage({id: 'game.condition', defaultMessage: 'Condition'})},
		{id: 5, label: intl.formatMessage({id: 'general.quantity', defaultMessage: 'Quantity'})},
		{id: 6, label: intl.formatMessage({id: 'general.price', defaultMessage: 'Price'})},
		{id: 7, label: intl.formatMessage({id: 'general.seller', defaultMessage: 'Seller'})},
		{id: 8, label: intl.formatMessage({id: 'general.startDate', defaultMessage: 'Start date'})},
		{id: 9, label: intl.formatMessage({id: 'general.status', defaultMessage: 'Status'})}
	]);

	/* ---- LIFE CYCLE ---- */

	const fetchSaleHistories = () => {
		let mounted = true;
		const cancelSource = axios.CancelToken.source();
		if(userId > 0) {
			if(mounted) setIsFetching(true);
			getSaleHistoriesUser(
				userId,
				[SALEHISTORY_PENDING, SALEHISTORY_CONFIRMEDF, SALEHISTORY_PENDING_CLOSURE],
				false,
				cancelSource.token
			)
				.then(saleHistories => {
					if(mounted) {
						const newRows = saleHistories.map(saleHistory => ({
							id: saleHistory.id,
							cells: [
								{content: saleHistory.cardSale.cardName, value: saleHistory.cardSale.cardName},
								{content: saleHistory.cardSale.cardSet, value: saleHistory.cardSale.cardSet},
								{content: getCardTypeById(saleHistory.cardSale.cardType).label, value: getCardTypeById(saleHistory.cardSale.cardType).value},
								{
									content: intl.formatMessage({id: saleHistory.cardSale.cardLanguage, defaultMessage: saleHistory.cardSale.cardLanguage}),
									value: intl.formatMessage({id: saleHistory.cardSale.cardLanguage, defaultMessage: saleHistory.cardSale.cardLanguage})
								},
								{content: getCardConditionById(saleHistory.cardSale.cardCondition).label, value: getCardConditionById(saleHistory.cardSale.cardCondition).label},
								{content: saleHistory.quantity, value: saleHistory.quantity},
								{content: saleHistory.price, value: saleHistory.price},
								{content: saleHistory.cardSale.user.username, value: saleHistory.cardSale.user.username},
								{content: saleHistory.dateInit, value: saleHistory.dateInit}
							],
							customData: {
								isSeller: false,
								actualStatus: saleHistory.saleHistoryStatus,
								buyerRating: saleHistory.buyerRating,
								buyerComment: saleHistory.buyerComment,
								sellerRating: saleHistory.sellerRating,
								sellerComment: saleHistory.sellerComment,
								username: saleHistory.userUsername
							}
						}));
						setIsFetching(false);
						setRows(newRows);
						setFilteredRows(newRows);
					}
				})
				.catch(error => mounted && setIsFetching(false));
		}

		return () => {
			mounted = false;
			cancelSource.cancel('Cancelling on unmount');
		};
	};

	useEffect(fetchSaleHistories, [userId]);

	/* ---- ON ACTION ---- */

	// TODO mover logica a common
	const onStatusChange = (saleHistory) => {
		const newFilteredRows = [...filteredRows];
		for(let i = 0; i < newFilteredRows.length; i++) {
			if(newFilteredRows[i].id === saleHistory.id) {
				newFilteredRows[i].customData.actualStatus = saleHistory.newStatus;
				break;
			}
		}
		const newSaleHistoriesToChange = [...saleHistoriesToChange];
		const saleHistoryIndex = newSaleHistoriesToChange.findIndex(element => element.id === saleHistory.id);
		if(saleHistoryIndex >= 0) {
			newSaleHistoriesToChange[saleHistoryIndex] = saleHistory;
		}else {
			newSaleHistoriesToChange.push(saleHistory);
		}
		setFilteredRows(newFilteredRows);
		setSaleHistoriesToChange(newSaleHistoriesToChange);
	};

	const onSaleHistoriesStatusSave = () => {
		setSaleHistoriesToChange([]);
		fetchSaleHistories();
	};

	return (
		<div className={`purchasesInProgressTable-div`}>
			<div className={`purchasesInProgressTable-actions-div`}>
				<SaleHistoryStatusEditor
					saleHistories={saleHistoriesToChange}
					isSeller={false}
					onSaleHistoryStatusSave={onSaleHistoriesStatusSave}
				/>
			</div>
			<CHTable
				tableClassName="purchasesInProgressTable-table"
				columns={columns}
				rows={filteredRows}
				customColumns={[
					{cellComponent: SaleHistoryStatusCell, args: {onStatusChange}}
				]}
			/>
			<CHSpinner id="userPurchasesInProgress-table-spinner" isActive={isFetching} />
		</div>
	);
};

Table.propTypes = {
	userId: PropTypes.number.isRequired
};

export default Table;