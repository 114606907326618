import React, { useState, useEffect } from 'react';
import './AvatarEditionModal.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { isMobile } from 'react-device-detect';

import CHModal from '../../components/CHComponents/CHModal';
import CHModalBody from '../../components/CHComponents/CHModalBody';
import CHModalFooter from '../../components/CHComponents/CHModalFooter';
import CardSearcher from '../../components/CardSearcher';
import CHSelect from '../../components/CHComponents/CHSelect';
import { fetchAvatar } from '../../redux/actions/sessionActions';
import { editUser } from '../../api/identityServiceApi';
import { MTG_CODE } from '../../utils/constants';
import defaultProfileAvatar from '../../assets/icons/defaultProfileAvatar.svg';

const scryfall = require('../../api/scryfallApi');

const view = isMobile?'mobile':'browser';
const gameCodes = [	{id:'mtg',label: 'Magic the gathering',value: 'mtg'},{id:'ygo',label: 'Yu-gi-oh',value: 'ygo'},{id:'pkm',label: 'Pokémon',value: 'pkm'}];

const AvatarEditionModal = props => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const {isOpen, toggle, onAccept, avatarPrint, user} = props;
	const [prints, setPrints] = useState([]);
	const [print, setPrint] = useState(null);
	const [game, setGame] = useState({id:'mtg',label: 'Magic the gathering',value: 'mtg'});
	const [cardName, setCardName] = useState('');
	const [cardSet, setCardSet] = useState(null);
	const [cancelSource, setCancelSource] = useState(axios.CancelToken.source());
	const [showActionError, setShowActionError] = useState(false);

	const setAvatarPrintValues = () => {
		setPrint(avatarPrint);
		setCardName(avatarPrint ? avatarPrint.name : '');
		setCardSet(avatarPrint ? {id: avatarPrint.id, label: avatarPrint.set_name, value: 0} : null);
		setPrints(avatarPrint ? [avatarPrint] : []);
	};

	const updateAvatarValues = () => {
		if(avatarPrint) {
			setAvatarPrintValues();
		}else {
			setPrint(null);
			setCardName('');
			setCardSet(null);
			setPrints([]);
		}
	};

	useEffect(updateAvatarValues, [avatarPrint]);

	const getImage = () => {
		if(print) {
			return print.image_uris ? print.image_uris.art_crop : print.card_faces[0].image_uris.art_crop;
		}else if(avatarPrint) {
			return avatarPrint.image_uris ? avatarPrint.image_uris.art_crop : avatarPrint.card_faces[0].image_uris.art_crop;
		}else {
			return defaultProfileAvatar;
		}
	};

	const getPrints = (name) => {
		const newCancelSource = axios.CancelToken.source();
		cancelSource.cancel();
		setCancelSource(newCancelSource);
		setPrint(null);
		setPrints([]);
		setCardSet(null);
		scryfall.getCardByName(name, newCancelSource.token)
			.then(prints => setPrints(prints))
			.catch(error => console.error(error.message));
	};

	useEffect(() => {
		if(prints.length > 0) {
			setPrint(prints[0]);
			setCardSet({
				id: prints[0].id,
				label: prints[0].set_name,
				value: 0
			});
		}else {
			setPrint(null);
		}
	}, [prints]);

	const getSets = () => {
		return prints.map((print, index) => ({
			id: print.id,
			label: print.set_name,
			value: index
		}));
	};

	const onSetSelected = setOption => {
		setCardSet(setOption);
		if(!setOption) {
			setPrint(null);
		}else {
			setPrint(prints[setOption.value]);
		}
	};

	const onGameSelected = setOption => {
		setGame(setOption);
		if(!setOption) {
			setPrint(null);
		}else {
			setPrint(prints['mtg']);
		}
	};

	const closeModal = () => {
		setShowActionError(false);
		setAvatarPrintValues();
		toggle();
	};

	const onSave = () => {
		setShowActionError(false);
		editUser({
			...user,
			avatarId: print.id,
			avatarType: MTG_CODE // TODO dynamic per game
		})
			.then(() => {
				dispatch(fetchAvatar(print.id, MTG_CODE));
				onAccept(print);
			})
			.catch(() => setShowActionError(true));
	};

	return (
		<CHModal isOpen={isOpen} toggle={closeModal} headerless>
			<CHModalBody className="avatarEditionModal-body" 	CHStyle="brandNoScroll">
					<div className={`${view}-avatarEditionModal-imgDiv`}>
					<img
						className={`${view}-avatarEditionModal-img`}
						src={getImage()}
						alt="Not found"
						onError={e => {e.target.onerror = null; e.target.src = require('../../assets/images/mtg-card-not-found.jpg')}}
					/>
					</div>
				<CHSelect
					className={`${view}-avatarEditionModal-CHSelect`}
					id="avatarEditionModal-CHSelect"
					value={game}
					options={gameCodes}
					placeholder={intl.formatMessage({id: 'select.game', defaultMessage: 'Game...'})}
					onChange={option => onGameSelected(option)}
					isClearable
					disabled
				/>
				<CardSearcher
					className={`${view}-avatarEditionModal-cardSearcher`}
					inputValue={cardName}
					onInputChange={name => setCardName(name)}
					onCardSelected={name => getPrints(name)}
					isClearable
				/>
				<CHSelect
					className={`${view}-avatarEditionModal-CHSelect`}
					id="avatarEditionModal-CHSelect"
					disabled={prints.length === 0}
					value={cardSet}
					options={getSets()}
					placeholder={intl.formatMessage({id: 'select.set', defaultMessage: 'Set...'})}
					onChange={option => onSetSelected(option)}
					isClearable
				/>
				{showActionError && (
					<label className="avatarEditionModal-error-label">
						{intl.formatMessage({id: 'message.actionError', defaultMessage: 'An error has ocurred while doing the action'})}
					</label>
				)}
			</CHModalBody>
			<CHModalFooter
				type="save"
				acceptLabel={intl.formatMessage({id: 'general.save', defaultMessage: 'Save'})}
				onAccept={onSave}
				acceptDisabled={!print}
			/>
		</CHModal>
	);

};

AvatarEditionModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	onAccept: PropTypes.func.isRequired,
	avatarPrint: PropTypes.object,
	user: PropTypes.shape({
		id: PropTypes.number.isRequired,
		username: PropTypes.string,
		password: PropTypes.string,
		name: PropTypes.string,
		surname: PropTypes.string,
		email: PropTypes.string,
		country: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		state: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		city: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		userStatus: PropTypes.string,
		userShipping: PropTypes.string,
		userDelivery: PropTypes.string,
		reputation: PropTypes.number,
		avatarId: PropTypes.string,
		avatarType: PropTypes.string,
		observation: PropTypes.string
	}).isRequired
};

AvatarEditionModal.defaultProps = {
	avatarPrint: null
};

export default AvatarEditionModal;
