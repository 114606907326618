/*
* LocationsService API calls configuration.
*/

import { getData } from './apiGatewayService';

/* ---- LOCATIONS ---- */

/* returns array of:
* id: number
* name: string
*/
const getCountries = async (cancelToken) => {
	return getData('locations/countries', null, cancelToken);
};

/* returns array of:
* id: number
* name: string
*/
const getStates = async (countryid, cancelToken) => {
	return getData('locations/states', {countryid}, cancelToken);
};

/* returns array of:
* id: number
* name: string
*/
const getCities = async (stateid, cancelToken) => {
	return getData('locations/cities', {stateid}, cancelToken);
};

export { getCountries, getStates, getCities };
