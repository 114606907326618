import React from 'react';
import './CHModalHeader.css';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

const CHModalHeader = props => {
	const {tittle, className, CHStyle} = props;

	function getHeaderStyles() {
		let styles = 'CHModalHeader-base';
		styles += CHStyle ? ` CHModalHeader-${CHStyle}` : ' CHModalHeader-brand';
		if(className) {
			styles += ` ${className}`;
		}
		if(isMobile) {
			styles +=CHStyle ? ` ${className}-mobile`:' CHModalHeader-brand-mobile';
		}
		return styles;
	}

	function getTittleStyles() {
		let styles = 'CHModalHeader-tittle-base';
		styles += CHStyle ? ` CHModalHeader-tittle-${CHStyle}` : ' CHModalHeader-tittle-brand';
		return styles;
	}

	return (
		<div className={getHeaderStyles()}>
			{tittle ?
				(<label className={getTittleStyles()}>{tittle}</label>)
			:
				props.children
			}
		</div>
	);
};

CHModalHeader.propTypes = {
	tittle: PropTypes.string,
	CHStyle: PropTypes.oneOf(['brand']),
	className: PropTypes.string
};

export default CHModalHeader;
