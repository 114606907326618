import React from 'react';
import { useIntl } from 'react-intl';
import CHTable from '../../../../components/CHComponents/CHTable';
import './Table.css';



const Table = props => {
	const intl = useIntl();
	const columns = [
		{id: 0, label: intl.formatMessage({id: 'general.iWas', defaultMessage: 'I was'})},
		{id: 1, label: intl.formatMessage({id: 'general.user', defaultMessage: 'User'})},
		{id: 2, label: intl.formatMessage({id: 'general.myRating', defaultMessage: 'My rating'})},
		{id: 3, label: intl.formatMessage({id: 'general.theirRating', defaultMessage: 'Their rating'})},
		{id: 4, label: intl.formatMessage({id: 'general.myComment', defaultMessage: 'My comment'})},
		{id: 5, label: intl.formatMessage({id: 'general.theirComment', defaultMessage: 'Their comment'})}
	];

	return (
		<CHTable tableClassName="UserSalesTable-userRatings"
			columns={columns}
			rows={[]}
		/>
	);
};

export default Table;
