/*
* MTGSalesService API calls configuration.
*/

import { getData, postData, putData, deleteData } from './apiGatewayService';


/* ---- CARDSALES ---- */
const BASE_SALES = 'sales/mtgcardsales';

/* recieves
* userId: number
* cardId: string
* cardName: string
* cardSet: string
* cardType: string
* cardLanguage: string
* cardCondition: string
* quantity: number
* price: float
* cardTradeable: string
* observation: string | null
*
* returns cardSale
*/
const createCardSale = async (cardSale) => {
	return postData(BASE_SALES, null, cardSale);
};

/* recieves:
* userid: number
*
* returns array of:
* id: number
* userId: number
* userUsername: string
* cardId: string
* cardName: string
* cardSet: string
* cardType: string
* cardLanguage: string
* cardCondition: string
* quantity: number
* price: float
* cardTradeable: string
* observation: string | null
* cardSaleStatus: string
* editedDate: date
*/
const getUserCardSales = async (userid, cancelToken) => {
	return getData(`${BASE_SALES}/user`, {userid}, cancelToken);
};

/* recieves:
* cardid: string
* cardtype: string
*
* returns array of:
* id: number
* cardLanguage: string
* cardCondition: string
* cardTradeable: string
* observation: string | null
* quantity: number
* price: float
* editedDate: date
* user:
	"id": number,
	"username": string,
	"city":{
		"id": number,
		"name": string
	},
	"state":{
		"id": number,
		"name": string
	},
	"country":{
		"id": number,
		"name": string
	},
	"userStatus": string,
	"userShipping": string,
	"userDelivery": string,
	"reputation": number,
	"avatarId": string,
	"avatarType": string,
	"observation": string
*
*/
const getCardCardSales = async (cardid, cardtype) => {
	return getData(`${BASE_SALES}/card`, {cardid, cardtype});
};

/* recieves cardSale, maps it to:
* id: number
* quantity: number
* price: float
* cardTradeable: string
* observation: string | null
* cardSaleStatus: string
*
* returns cardSale
*/
const updateCardSale = async (editedCardSale) => {
	return putData(BASE_SALES, null, {
		id: editedCardSale.id,
		quantity: editedCardSale.quantity,
		price: editedCardSale.price,
		cardTradeable: editedCardSale.cardTradeable,
		observation: editedCardSale.observation,
		cardSaleStatus: editedCardSale.cardSaleStatus
	});
};

const deleteCardSale = async (cardSaleId) => {
	return deleteData(`${BASE_SALES}/${cardSaleId}`);
};

/* ---- CARD WISHES ---- */
const BASE_WISHES = 'sales/mtgcardwishes';

/* recieves
* userId: number
* cardId: string
* cardName: string
* cardSet: string
* cardType: string
* cardLanguage: string
* cardCondition: string
* countryid: number
* stateid: number
* cityid: number
*
* returns cardWish
*/
const createCardWish = async (cardWish) => {
	return postData(BASE_WISHES, null, cardWish);
};

/* returns array of
* id: number
* userId: number
* cardId: string
* cardName: string
* cardSet: string
* cardType: number
* cardLanguage: number
* cardCondition: number
* country: {id: number, name: string}
* state: {id: number, name: string}
* city: {id: number, name: string}
* userDelivery: string
* userShipping: string
*/
const getCardWishes = async (userid) => {
	return getData(BASE_WISHES, {userid});
};

/* recieves:
* wishId: number
*/
const deleteCardWish = async (wishId) => {
	return deleteData(`${BASE_WISHES}/${wishId}`);
};


/* ---- SALE HISTORY ---- */
const BASE_SALE_HISTORY = 'sales/mtgsalehistories';

/* SaleHistory
* id: number
* cardSale:
* 	id: number
* 	-- if SaleHistory
* 		userId: number
* 		userUsername: string
* 	-- if SaleHistoryUser
* 		user:
 			"id": number,
			"username": string,
			"city":{
				"id": number,
				"name": string
			},
			"state":{
				"id": number,
				"name": string
			},
			"country":{
				"id": number,
				"name": string
			},
			"userStatus": string,
			"userShipping": string,
			"userDelivery": string,
			"reputation": number,
			"avatarId": string,
			"avatarType": string,
			"observation": string
* 	cardId: string
* 	cardName: string
* 	cardSet: string
* 	cardType: string (CardTypeEnum)
* 	cardLanguage: string (CardLanguageEnum)
* 	cardCondition: string (CardConditionEnum)
* 	quantity: number
* 	price: number
* 	cardTradeable: string (TradeableEnum)
* 	observation: string
* 	cardSaleStatus: string (CardSaleStatusEnum)
* userId: number
* userUsername: string
* buyerRating: number
* sellerRating: number
* quantity: number
* price: number
* dateInit: string
* dateFinish: string
* saleHistoryStatus: string (SaleHistoryStatus)
*/


/* recieves object:
* cardSale: {id: number}
* userId: number
* quantity: number
*
* returns SaleHistory
*/
const createSaleHistory = async (cardSaleId, userId, quantity) => {
	return postData(BASE_SALE_HISTORY, null, {
		cardSale: {id: cardSaleId},
		userId,
		quantity
	});
};

/* recieves:
* userId: number
* statuses: SaleHistoryStatus[]
* isSeller: boolean
*
* returns array of SaleHistory
*/
const getSaleHistories = async (userId, statuses, isSeller, cancelToken) => {
	return getData(BASE_SALE_HISTORY, {userId, status: statuses, isSeller}, cancelToken);
};

/* recieves:
* userId: number
* statuses: SaleHistoryStatus[]
* isSeller: boolean
*
* returns array of SaleHistoryUser
*/
const getSaleHistoriesUser = async (userId, statuses, isSeller) => {
	return getData(`${BASE_SALE_HISTORY}/user`, {userId, status: statuses, isSeller});
};

/* recieves array of:
* id: number
* quantity: number
* saleHistoryStatus: string (SaleHistoryStatusEnum)
* buyerRating: number
* sellerRating: number
*/
const updateSaleHistories = async (saleHistories) => {
	return putData(BASE_SALE_HISTORY, null, saleHistories);
};

/* recieves:
* saleHistoryId: number
*/
const deleteSaleHistory = async (saleHistoryId) => {
	return deleteData(`${BASE_SALE_HISTORY}/${saleHistoryId}`);
};

export {
	createCardSale, getUserCardSales, getCardCardSales, updateCardSale, deleteCardSale,
	createCardWish, getCardWishes, deleteCardWish,
	createSaleHistory, getSaleHistories, getSaleHistoriesUser, updateSaleHistories, deleteSaleHistory
};
