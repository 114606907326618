import React from 'react';
import './Icon.css';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const icons = require('../assets/icons/icons.json');
const globals = icons.manaSymbol.globals;

const themeSelector = store => store.session.theme;

const Icon = props => {
	const theme = useSelector(themeSelector);
	const {name, type, width, height, className, color} = props;
	
	const getPath = (path) => {
		const fill = type === 'manaSymbol' ? path.fill : '';
		const colorClass = type === 'other' ? (color ? `Icon-${color}` : 'Icon-primary') : '';
		if(path.d instanceof Array) {
			return path.d.map(d => (
				<path d={d} fill={fill} className={colorClass} transform={path.transform ? path.transform : ""}/>
			));
		}
		return (<path d={path.d} fill={fill} className={colorClass} transform={path.transform ? path.transform : ""}/>);
	};
	
	const renderIcon = (icon, width, height) => {
		const circle = icon.circle ? (<circle cx={globals.cx} cy={globals.cy} r={globals.r} fill={icon.circle.fill}/>) : false;
		return (
			<svg 
				className={className}
				width={width} 
				height={height} 
				viewBox={type === 'manaSymbol' ? globals.viewBox : icon.viewBox} 
			>
				{circle ? circle : null}
				{getPath(icon.path)}
			</svg>
		);
	};

	if(type === 'file') {
		return (
			<img
				className={className}
				style={height && width ? {width: `${width}px`, height: `${height}px`} : {}}
				src={require(`../assets/icons/${name}-${theme}.svg`)}
				alt={`{${name}}`}
			/>
		);
	}
	return icons[type][name] ? renderIcon(icons[type][name], width, height) : (name === "" ? "" : `{${name}}`);
};

Icon.propTypes = {
	type: PropTypes.oneOf(['manaSymbol', 'other', 'file']).isRequired,
	name: PropTypes.string.isRequired,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.oneOf(['primary', 'white', 'black']),
	className: PropTypes.string
};

export default Icon;