import React, { useState, useEffect } from 'react';
import './SaleHistoryCartEditor.css';
import PropTypes from 'prop-types';

import CHButton from './CHComponents/CHButton';
import Icon from "./Icon";
import { updateSaleHistories, deleteSaleHistory } from '../api/mtgSalesServiceApi';
import { SALEHISTORY_IN_CART } from '../utils/constants';

const TIMEOUT_MS = 1500;
const SaleHistoryCartEditor = props => {
	const {
		className, saleHistoryId, saleHistoryQuantity, cardSaleQuantity,
		onEdition, onError, isDisabled, disableEdition, enableEdition
	} = props;
	const [quantity, setQuantity] = useState(1);
	const [timeoutCallback, setTimeoutCallback] = useState(null);

	useEffect(() => setQuantity(saleHistoryQuantity), [saleHistoryQuantity]);

	const onChangeQuantity = newQuantity => {
		if(newQuantity <= 0 || newQuantity > cardSaleQuantity) return;
		timeoutCallback && clearTimeout(timeoutCallback);
		setQuantity(newQuantity);
		const callback = setTimeout(() => {
			disableEdition();
			updateSaleHistories([{
				id: saleHistoryId,
				quantity: newQuantity,
				saleHistoryStatus: SALEHISTORY_IN_CART
			}])
				.then(result => onEdition())
				.catch(error => {
					setQuantity(saleHistoryQuantity);
					enableEdition();
					onError && onError(saleHistoryId, error);
				});
		}, TIMEOUT_MS);
		setTimeoutCallback(callback);
	};

	const onDelete = () => {
		disableEdition();
		deleteSaleHistory(saleHistoryId)
			.then(result => onEdition())
			.catch(error => enableEdition());
	};

	return (
		<div className={className}>
			<label className={`saleHistoryCartEditor-quantity`}>{quantity}</label>
			<div className={`saleHistoryCartEditor-upDownButtons-div`}>
				<CHButton
					id={`saleHistory-${saleHistoryId}-upQuantity`}
					className={`saleHistoryCartEditor-up-btn`}
					CHStyle="transparent"
					disabled={isDisabled}
					label={<Icon className={`saleHistoryCartEditor-upDown-icon`} type="other" name="asc" width="18" height="16" />}
					onClick={() => onChangeQuantity(quantity + 1)}
				/>
				<CHButton
					id={`saleHistory-${saleHistoryId}-downQuantity`}
					className={`saleHistoryCartEditor-down-btn`}
					CHStyle="transparent"
					disabled={isDisabled}
					label={<Icon className={`saleHistoryCartEditor-upDown-icon`} type="other" name="desc" width="18" height="16" />}
					onClick={() => onChangeQuantity(quantity - 1)}
				/>
			</div>
			<CHButton
				id={`saleHistory-${saleHistoryId}-delete`}
				className={`saleHistoryCartEditor-delete-btn`}
				CHStyle="transparent"
				disabled={isDisabled}
				label={<Icon type="other" name="trash" width="18" height="18" />}
				onClick={onDelete}
			/>
		</div>
	);
};

SaleHistoryCartEditor.propTypes = {
	saleHistoryId: PropTypes.number.isRequired,
	saleHistoryQuantity: PropTypes.number.isRequired,
	cardSaleQuantity: PropTypes.number.isRequired,
	onEdition: PropTypes.func.isRequired,
	// onError(saleHistoryId, error)
	onError: PropTypes.func,
	isDisabled: PropTypes.bool.isRequired,
	disableEdition: PropTypes.func.isRequired,
	enableEdition: PropTypes.func.isRequired,
	className: PropTypes.string
};

export default SaleHistoryCartEditor;
