import React from 'react';
import './DeleteColumn.css';
import PropTypes from 'prop-types';

import Icon from "../Icon";

const DeleteCell = props => {
	const {tdClassName, row, args} = props;

	return (
		<td className={tdClassName + ' WishlistDeleteColumn-cell-td'} key={row.id + 'wishlistDelete'}>
			<span
				className="WishlistDeleteColumn-cell-span"
				onClick={() => args.onDelete(row.id)}
			>
				<Icon
					type="other"
					name="trash"
					width="18"
					height="18"
				/>
			</span>
		</td>
	);
};

DeleteCell.propTypes = {
	tdClassName: PropTypes.string.isRequired,
	row: PropTypes.object.isRequired,
	args: PropTypes.shape({
		// onDelete(id)
		onDelete: PropTypes.func.isRequired
	}).isRequired
};

export {
	DeleteCell
};