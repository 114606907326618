import React, { useState, Fragment } from 'react';
import './CHTextarea.css';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import {isMobile} from 'react-device-detect';


const CHTextarea = props => {
	const { id, value, onChange, className, CHStyle, disabled, placeholder, error } = props;
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	const getStyles = () => {
		let styles =  'CHTextarea-base';
		styles += CHStyle ? ` CHTextarea-${CHStyle}` : ' CHTextarea-brand';
		if(className) {
			styles += ` ${className}`;
		}
		if(error && error.isInvalid) {
			styles += ' CHTextarea-error';
		}
		if(disabled) {
			styles += ` CHTextarea-disabled`;
		}
		if(isMobile) {
			styles += ` CHTextarea-mobile`;
		}
		return styles;
	};

	const toggleTooltip = () => {
		setIsTooltipOpen(
			!isTooltipOpen
			&& error.isInvalid
			&& error.message
			&& error.message.length > 0
		);
	};

	return (
		<Fragment>
			<textarea
				id={id}
				disabled={disabled}
				className={getStyles()}
				value={value}
				onChange={event => onChange(event.target.value)}
				placeholder={placeholder}
			/>
			{error && (
				<Tooltip
					innerClassName="CHTextarea-error-tooltip"
					hideArrow
					placement="top"
					isOpen={isTooltipOpen}
					target={id}
					toggle={toggleTooltip}
				>
					{error ? error.message : ''}
				</Tooltip>
			)}
		</Fragment>
	);
};

CHTextarea.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	value: PropTypes.string.isRequired,
	// onChange(value: string)
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	CHStyle: PropTypes.oneOf(['brand','error', 'disable']),
	className: PropTypes.string,
	error: PropTypes.shape({
		isInvalid: PropTypes.bool.isRequired,
		message: PropTypes.string
	})
};

export default CHTextarea;
