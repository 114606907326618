import React, { Fragment, useState, useEffect } from 'react';
import './DeliveryMethodsColumn.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Tooltip } from 'reactstrap';

import Icon from '../Icon';
import { getUserShippingById } from '../../utils/enumsService';
import { DELIVERY } from '../../utils/constants';

const DeliveryMethodsCellContent = props => {
	const { id, delivery, shipping } = props;
	const intl = useIntl();
	const [activeIcons, setActiveIcons] = useState({
		delivery: false,
		localShipping: false,
		nationalShipping: false,
		internationalShipping: false
	});
	const [isDeliveryHovered, setIsDeliveryHovered] = useState(false);
	const [isLocalShippingHovered, setIsLocalShippingHovered] = useState(false);
	const [isNationalShippingHovered, setIsNationalShippingHovered] = useState(false);
	const [isInternationalShippingHovered, setIsInternationalShippingHovered] = useState(false);

	useEffect(() => {
		const shippingOption = getUserShippingById(shipping);
		if(shippingOption) {
			setActiveIcons({
				delivery: delivery === DELIVERY,
				localShipping: shippingOption.value >= 1,
				nationalShipping: shippingOption.value >= 2,
				internationalShipping: shippingOption.value >= 3
			});
		}
	}, [delivery, shipping]);

	const renderIcon = (isHovered, onHover, icon, tooltip, isPersonal) => (
		<Fragment>
			<span id={`${id}-${icon}`} className={isPersonal ? 'deliveryMethodsColumn-span-personal' : 'deliveryMethodsColumn-span'}>
				<Icon
					type="other"
					name={icon}
					width="20"
					height="20"
				/>
			</span>
			<Tooltip
				placement="top"
				isOpen={isHovered}
				target={`${id}-${icon}`}
				toggle={() => onHover(!isHovered)}
			>
				{tooltip}
			</Tooltip>
		</Fragment>
	);

	return (
		<Fragment>
			{activeIcons.delivery && renderIcon(
				isDeliveryHovered,
				(value) => setIsDeliveryHovered(value),
				'personalDelivery',
				intl.formatMessage({id: 'general_delivery', defaultMessage: 'Personal'}),
				true
			)}
			{activeIcons.localShipping && renderIcon(
				isLocalShippingHovered,
				(value) => setIsLocalShippingHovered(value),
				'localShipping',
				intl.formatMessage({id: 'general_local', defaultMessage: 'Local'})
			)}
			{activeIcons.nationalShipping && renderIcon(
				isNationalShippingHovered,
				(value) => setIsNationalShippingHovered(value),
				'nationalShipping',
				intl.formatMessage({id: 'general_national', defaultMessage: 'National'})
			)}
			{activeIcons.internationalShipping && renderIcon(
				isInternationalShippingHovered,
				(value) => setIsInternationalShippingHovered(value),
				'internationalShipping',
				intl.formatMessage({id: 'general_international', defaultMessage: 'International'})
			)}
		</Fragment>
	);
};

DeliveryMethodsCellContent.propTypes = {
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	delivery: PropTypes.string,
	shipping: PropTypes.string
};

export {
	DeliveryMethodsCellContent
};