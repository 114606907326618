import React from 'react';
import '../../assets/styles/ThemeVariables.css';
import './Footer.css';
import { Link, withRouter, useLocation, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Icon from '../../components/Icon';
import {isMobile} from 'react-device-detect';

import { updateUrlwHistory } from '../../utils/urlManager';

const view = isMobile?'mobile':'browser';

const Footer = props => {
	const location = useLocation();
	const history = useHistory();
	const intl = useIntl();

	return (
		<footer className={`${view}-footer-div`}>
			<div className="footer-leftSection-div">
				<Link to={'/termsAndConditions'} className={`${view}-footer-link`}>
					 {intl.formatMessage({id: 'general.termsAndConditions', defaultMessage: 'Terms and conditions'})}
				</Link>
			</div>
			<div className="footer-centerSection-div">
				<Link to={'/donate'} className={`${view}-footer-link`}>
					2020 TCG Cardhub
				</Link>
			</div>
			<div className="footer-rightSection-div">
					<a to={''} className={`${view}-footer-icon-link`}>
						<Icon name="youtube" type="other" color="primary"/>
					</a>
					<a href="https://trello.com/b/eUpRkxlb/cardhub" target="_BLANK" className={`${view}-footer-icon-link`}>
						<Icon name="facebook" type="other" color="primary"/>
					</a>
					<a to={''} className={`${view}-footer-icon-link`}>
						<Icon name="instagram" type="other" color="primary"/>
					</a>	
					<a to={''} className={`${view}-footer-icon-link`}>
						<Icon name="twitch" type="other" color="primary"/>
					</a>			
					<a to={''} className={`${view}-footer-icon-link`}>
						<Icon name="twitter" type="other" color="primary"/>
					</a>
			</div>
		</footer>
	);
};

export default withRouter(Footer);
