import React, { useState, useEffect, Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/styles/ThemeVariables.css';
import './TopBar-Browser.css';
import './TopBar-Mobile.css';
import PropTypes from 'prop-types';
import { Link, withRouter, useLocation, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { MobileView, BrowserView, isMobile } from 'react-device-detect';

import CHButtonDropdown from '../../components/CHComponents/CHButtonDropdown';
import CHButton from '../../components/CHComponents/CHButton';
import CardSearcher from '../../components/CardSearcher';
import LoginModal from './LoginModal';
import CartButton from './CartButton';
import { isLoggedIn, getUsername, clearUser, isTokenExpired, getTheme } from '../../utils/localStorageManager';
import { updateUrlwHistory, getUrlGame } from '../../utils/urlManager';
import { fetchPrints } from '../../redux/actions/cardSearchActions';
import { fetchAvatar, fetchCart, loginUser, logoutUser, setSessionTheme } from '../../redux/actions/sessionActions';
import { getUserByUsername } from '../../api/identityServiceApi';
import defaultProfileAvatar from '../../assets/icons/defaultProfileAvatar.svg';
import cardhubLogo from '../../assets/icons/cardhub-logo.svg';
import Icon from '../../components/Icon';
import { GAME_MTG } from '../../utils/constants';


const loggedInSelector = store => store.session.loggedIn;
const avatarUrlSelector = store => store.session.avatarUrl;
const themeSelector = store => store.session.theme;
const printsSelector = store => store.cardSearch.prints;
const cardNameSelector = store => store.cardSearch.cardName;

const encodeUrl = require('encodeurl');
const gameCodes = ['mtg', 'ygo', 'pkm'];
const TopBar = props => {
	const location = useLocation();
	const history = useHistory();
	const intl = useIntl();
	const dispatch = useDispatch();
	const loggedIn = useSelector(loggedInSelector);
	const avatarUrl = useSelector(avatarUrlSelector);
	const theme = useSelector(themeSelector);
	const prints = useSelector(printsSelector);
	const cardName = useSelector(cardNameSelector);
	const { switchTheme, localeOptions, changeLanguage, selectedLanguage} = props;
	const [game, setGame] = useState(null);
	const [mainMenuOptions, setMainMenuOptions] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isGameMenuOpen, setIsGameMenuOpen] = useState(false);
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
	const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
	const [isLanguagesOpen, setIsLanguagesOpen] = useState(false);
	const [actualView, setActualView] = useState('browser');

	/* ---- CONSTANTS ---- */

	const changePage = (path) => {
		setIsMenuOpen(false);
		setIsUserMenuOpen(false);
		updateUrlwHistory(location, history, path);
	};

	const logout = () => {
		const username = getUsername();
		clearUser();
		dispatch(logoutUser());
		setIsUserMenuOpen(false);
		const actualPath = location.pathname.split('/');
		if(actualPath.length > 3 && (actualPath[3] === username || actualPath[2] === 'userEdition')) {
			changePage('/');
		}
	};

	const userMenuOptions = [
		{
			id: 'user-option-profile',
			label: intl.formatMessage({id: 'general.profile', defaultMessage: 'Profile'}),
			onClick: () => changePage(`/${game ? game : GAME_MTG}/users/${getUsername()}`)
		},
		{
			id: 'user-option-logout',
			label: intl.formatMessage({id: 'general.logout', defaultMessage: 'Logout'}),
			onClick: logout
		}
	];

	/* ---- LIFE CYCLE ---- */

	const setMenuOptions = (game) => {
		let options = [];
		if(gameCodes.includes(game)) {
			options = [
				{id: 'scrapSearch',
					label: intl.formatMessage({id: 'game.searchScrap', defaultMessage: 'Search scrap'}),
					onClick: () => changePage(`/${game}/scrapSearch`)
				},
				{id: 'sealedSearch',
					label: intl.formatMessage({id: 'game.searchSealed', defaultMessage: 'Search sealed'}),
					onClick: () => changePage(`/${game}/sealedSearch`)
				},
				{id: 'accesorySearch',
					label: intl.formatMessage({id: 'general.accesories', defaultMessage: 'Accesories'}),
					onClick: () => changePage(`/${game}/accesorySearch`)
				}
			];
		}
		options = options.concat([
			{id: 'news',
				label: intl.formatMessage({id: 'general.news', defaultMessage: 'News'}),
				onClick: () => changePage('/news')
			},
			{id: 'donate',
				label: intl.formatMessage({id: 'general.donate', defaultMessage: 'Donate'}),
				onClick: () => changePage('/donate')
			}
		]);
		setMainMenuOptions(options);
	};

	const updateGame = () => {
		const game = getUrlGame(location);
		setMenuOptions(game);
		setGame(gameCodes.includes(game) ? game : null);
	};

	useEffect(updateGame, [location.pathname]);

	const loginUserState = () => {
		dispatch(fetchCart());
		getUserByUsername(getUsername())
			.then(userInfo => dispatch(fetchAvatar(userInfo.avatarId, userInfo.avatarType)));
		dispatch(loginUser());
	};

	const checkUserSession = () => {
		if(!loggedIn && isLoggedIn()) {
			if(isTokenExpired()) {
				clearUser();
			}else {
				loginUserState();
			}
		}
	};

	const init = () => {
		let mounted = true;
		checkUserSession();
		dispatch(setSessionTheme(getTheme() === 'light' ? 'light' : 'dark'));
		if(mounted) {
			setActualView(isMobile ? 'mobile' : 'browser');
		}

		return () => {
			mounted = false;
		};
	};

	useEffect(init, []);

	/* ---- ON ACTION ---- */

	const changeGamePage = (path) => {
		const actualPath = location.pathname.split('/');
		setIsGameMenuOpen(false);
		updateUrlwHistory(
			location,
			history,
			actualPath.length > 3 && actualPath[2] === 'users'
				? `${path}/users/${actualPath[3]}`
				: path
		);
	};

	const onCardSearch = (query) => {
		dispatch(fetchPrints(game, query, null, true));
		const url = `/${game}/cardSearch?q=${encodeUrl(query)}`;
		const isDifferentUrl = url !== (location.pathname + location.search);
		if(isDifferentUrl && cardName !== '' && prints.length === 0){
			history.replace(url);
		}else if(isDifferentUrl) {
			updateUrlwHistory(location, history, url);
		}
	};

	const onChangeTheme = () => {
		switchTheme(theme === 'dark');
		dispatch(setSessionTheme(theme === 'dark' ? 'light' : 'dark'));
	};
	
	const onLogin = () => {
		loginUserState();
		setIsLoginModalOpen(false);
	};

	const styles = {
		width: "100%"
	};
	
	return (
		<nav className={`navbar navbar-expand-lg navbar-light bg-dark ${actualView}-topBar-nav`}>
			<div className={`${actualView}-topBar-leftSection-div`}>
				<CHButtonDropdown
					id="topBar-mainMenu"
					buttonClassName={`${actualView}-topBar-mainMenu-button-dropdownToggle`}
					CHStyle="transparent"
					isOpen={isMenuOpen}
					toggle={() => setIsMenuOpen(!isMenuOpen)}
					label={
						<Icon name="menu" type="other" width="25" height="25" />
					}
					type="menu"
					content={mainMenuOptions}
				/>
				{ game ? (
					<Link to={''} className={`${actualView}-topBar-account-link`}>
						<img className={`${actualView}-topBar-logo-img`} src={cardhubLogo} alt="Not found"/>
					</Link>
				) : (
					<Link to={''} className={`${actualView}-topBar-account-link`}>
						<Icon className={`${actualView}-topBar-isoLogoCardhub-img`} name="isoLogo" type="file" alt="Not found"/>
					</Link>
				)}
				{game && (
					<CHButtonDropdown
						id="topBar-game-menu"
						className={`${actualView}-topBar-game-buttonDropdown`}
						CHStyle="transparent"
						isOpen={isGameMenuOpen}
						toggle={() => setIsGameMenuOpen(!isGameMenuOpen)}
						label={game ? game.toUpperCase() : ''}
						onClick={() => changePage(`/${game}`)}
						type="split"
						content={[
							{id: 'mtg', label: 'Magic the Gathering', onClick: () => changeGamePage('/mtg')},
							{id: 'pkm', label: 'Pokémon', onClick: () => changeGamePage('/pkm')},
							{id: 'ygo', label: 'Yu-Gi-Oh!', onClick: () => changeGamePage('/ygo')}
						]}
					/>
				)}
			</div>

			<div className={`${actualView}-topBar-centerSection-div`}>
				<BrowserView>
					{game && (
						<CardSearcher
							className={`${actualView}-topBar-cardSearcher`}
							onCardSelected={query => onCardSearch(query)}
						/>
					)}
				</BrowserView>
			</div>

			<div className={`${actualView}-topBar-rightSection-div`}>
				<CHButtonDropdown
					id="topBar-languages-menu"
					buttonClassName={`${actualView}-topBar-languages-button`}
					isOpen={isLanguagesOpen}
					toggle={() => setIsLanguagesOpen(!isLanguagesOpen)}
					label={selectedLanguage.content}
					type="menu"
					CHStyle="transparent"
					content={localeOptions.map(option => ({
						id: option.id,
						label: option.content,
						onClick: () => changeLanguage(option)
					}))}
				/>
				<span onClick={onChangeTheme} className={`${actualView}-topBar-themeSwitch-switch-span`}>
					<Icon
						name={theme === 'dark' ? "toggleOn" : "toggleOff"}
						type="other"
						width="30"
						height="30"
					/>
				</span>
				<Icon
					className={`${actualView}-topBar-themeSwitch-themeIcon`}
					name={theme === 'dark' ? "moon" : "sun"}
					type="other"
					width="20"
					height="20"
				/>
				{loggedIn ? (
					<Fragment>
						<CartButton/>
						<CHButtonDropdown
							id="topBar-avatar"
							className={`${actualView}-topBar-avatar-button-div`}
							buttonClassName={`${actualView}-topBar-avatar-button`}
							isOpen={isUserMenuOpen}
							toggle={() => setIsUserMenuOpen(!isUserMenuOpen)}
							type="menu"
							dropdownMenuRight
							CHStyle="transparent"
							content={userMenuOptions}
							label={(
								<div className={`${actualView}-topBar-avatar-div`}>
									<img
										className={`${actualView}-topBar-avatar-img`}
										src={avatarUrl !== '' ? avatarUrl : defaultProfileAvatar}
										alt="Not found"
										onError={e => {e.target.onerror = null; e.target.src = require('../../assets/icons/defaultProfileAvatar.svg')}}
									/>
								</div>
							)}
						/>
					</Fragment>
				) : (
					<CHButton
						id="topBar-account-login-button"
						className={`${actualView}-topBar-account-button`}
						label={intl.formatMessage({id: 'general.login', defaultMessage: 'Login'})}
						onClick={() => setIsLoginModalOpen(true)}
					/>
				)}
			</div>

			{/* ---- MOBILE VIEW ---- */}
			<MobileView style={styles}>
				{game && (
					<div className={`${actualView}-topBar-search-div`}>
						<CardSearcher
							className={`${actualView}-topBar-cardSearcher`}
							onCardSelected={query => onCardSearch(query)}
						/>
					</div>
				)}
			</MobileView>

			{/* ---- MODALS ---- */}
			<LoginModal
				isOpen={isLoginModalOpen}
				onClose={() => setIsLoginModalOpen(false)}
				onLogin={onLogin}
			/>
		</nav>
	);
};

TopBar.propTypes = {
	// searchCard(cardName: string)
	searchCard: PropTypes.func,
	switchTheme: PropTypes.func.isRequired,
	localeOptions: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
		content: PropTypes.node.isRequired,
	})).isRequired,
	selectedLanguage: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
		content: PropTypes.node.isRequired,
	}).isRequired,
	changeLanguage: PropTypes.func.isRequired

};

export default withRouter(TopBar);
