import React, { useState } from 'react';
import './SwitchStatusModal.css';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import CHModal from '../../components/CHComponents/CHModal';
import CHModalHeader from '../../components/CHComponents/CHModalHeader';
import CHModalBody from '../../components/CHComponents/CHModalBody';
import CHModalFooter from '../../components/CHComponents/CHModalFooter';
import { USERSTATUS_ACTIVE, USERSTATUS_SUSPENDED } from '../../utils/constants';
import { editUser } from '../../api/identityServiceApi';
import CHError from '../../components/CHComponents/CHError';

const defaultSuspendMsg = 'By suspending the account all your sales won\'t be visible and no one will be able to make purchase orders. '
+ 'The account can be reactivated anytime.';
const defaultActivateMsg = 'By activiting the account all your sales will be visible again and people will be able to make purchase orders.';
const SwitchStatusModal = props => {
	const intl = useIntl();
	const { user, isOpen, onAccept, onClose } = props;
	const [showActionError, setShowActionError] = useState(false);
	const [actionError, setActionError] = useState(null);

	const onSave = () => {
		setShowActionError(false);
		editUser({
			...user,
			userShipping: user.userShipping.id,
			userStatus: user.userStatus === USERSTATUS_ACTIVE ? USERSTATUS_SUSPENDED : USERSTATUS_ACTIVE
		})
			.then(() => {
				onAccept();
			})
			.catch((error) => {
				setActionError(error);
				setShowActionError(true);
			});
	};

	return (
		<CHModal isOpen={isOpen} toggle={onClose}>
			{user.userStatus === USERSTATUS_ACTIVE
				? <CHModalHeader tittle={intl.formatMessage({id: 'general.suspendAccount', defaultMessage: 'Suspend Account'})}/>
				: <CHModalHeader tittle={intl.formatMessage({id: 'general.activateAccount', defaultMessage: 'Activate Account'})}/>
			}
			<CHModalBody>
				<p>
					{user.userStatus === USERSTATUS_ACTIVE
						? intl.formatMessage({id: 'message.suspendAccount', defaultMessage: defaultSuspendMsg})
						: intl.formatMessage({id: 'message.activateAccount', defaultMessage: defaultActivateMsg})
					}
				</p>

				<CHError
					id="switchStatusModal-error"
					isShown={showActionError}
					error={actionError}
				/>
			</CHModalBody>
			<CHModalFooter
				type="save"
				acceptLabel={user.userStatus === USERSTATUS_ACTIVE
					? intl.formatMessage({id: 'general.suspend', defaultMessage: 'Suspend'})
					: intl.formatMessage({id: 'general.activate', defaultMessage: 'Activate'})
				}
				onAccept={onSave}
			/>
		</CHModal>
	);
};

SwitchStatusModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onAccept: PropTypes.func.isRequired,
	user: PropTypes.shape({
		id: PropTypes.number.isRequired,
		username: PropTypes.string,
		password: PropTypes.string,
		name: PropTypes.string,
		surname: PropTypes.string,
		email: PropTypes.string,
		country: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		state: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		city: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		userStatus: PropTypes.string,
		userShipping: PropTypes.string,
		userDelivery: PropTypes.string,
		reputation: PropTypes.number,
		avatarId: PropTypes.string,
		avatarType: PropTypes.string,
		observation: PropTypes.string
	}).isRequired
};

export default SwitchStatusModal;