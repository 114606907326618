import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'reactstrap';
import Icon from '../Icon';
import './CHModal.css';

const CHModal = props => {
	const {isOpen, toggle, className, CHStyle, headerless} = props;

	function getSyles() {
		let styles = 'CHModal-base';
		styles += CHStyle ? ` CHModal-${CHStyle}` : ' CHModal-brand';
		if(className) {
			styles += ` ${className}`;
		}
		return styles;
	}

	return (
		<Modal isOpen={isOpen} toggle={toggle} className={getSyles()} centered={true}>
			<span onClick={toggle} className="CHModal-Icon-span">
				<Icon
					className={headerless ? 'CHModal-Icon-headerless' : 'CHModal-Icon'}
					type="other"
					name="cross"
					width="20"
					height="20"
				/>
			</span>
			{props.children}
		</Modal>
	);
};

CHModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	CHStyle: PropTypes.oneOf(['brand']),
	headerless: PropTypes.bool,
	className: PropTypes.string
};

export default CHModal;
