import React, { useState, useEffect } from 'react';
import './UserEdition.css';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import axios from "axios";

import CHInput from '../../components/CHComponents/CHInput';
import CHButton from '../../components/CHComponents/CHButton';
import CHCheckbox from '../../components/CHComponents/CHCheckbox';
import CHTextarea from '../../components/CHComponents/CHTextarea';
import CHSelect from '../../components/CHComponents/CHSelect';
import { NO_SHIPPING, SHIPPING_LOCAL, DELIVERY, NO_DELIVERY, USERSTATUS_ACTIVE } from '../../utils/constants';
import { getUsername, clearUser } from '../../utils/localStorageManager';
import { getUserShippingOptions, getUserShippingById } from '../../utils/enumsService';
import { editUser, getFullUserByUsername } from '../../api/identityServiceApi';
import { updateUrlwHistory, getUrlGame } from '../../utils/urlManager';
import { getLocationOption } from '../../utils/locationService';
import ChangePasswordModal from './ChangePasswordModal';
import DeleteAccountModal from './DeleteAccountModal';
import SwitchStatusModal from './SwitchStatusModal';
import CHError from '../../components/CHComponents/CHError';
import { logoutUser } from '../../redux/actions/sessionActions';
import { isMobile } from 'react-device-detect';

const defaultMsg = {
	invalidName: '4-32 characters long',
	invalidSurname: '4-32 characters long',
	invalidLocation: 'You have to choose your location',
	invalidObservation: 'Max of 150 characters'
};

const view = isMobile?'mobile':'browser';

const UserEdition = props => {
	const location = useLocation();
	const history = useHistory();
	const pathParams = useParams();
	const dispatch = useDispatch();
	const [username, setUsername] = useState(null);
	const intl = useIntl();
	const [user, setUser] = useState({
		id: -1,
		username: '',
		password: null,
		name: '',
		surname: '',
		email: '',
		country: null,
		state: null,
		city: null,
		userStatus: '',
		userShipping: NO_SHIPPING,
		userDelivery: DELIVERY,
		reputation: -1,
		avatarId: null,
		avatarType: null,
		observation: ''
	});
	const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
	const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);
	const [isSwitchStatusModalOpen, setIsSwitchStatusModalOpen] = useState(false);
	const [showActionError, setShowActionError] = useState(false);
	const [actionError, setActionError] = useState(null);
	const [errors, setErrors] = useState({
		username: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidUsername', defaultMessage: defaultMsg.invalidUsername})},
		name: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidName', defaultMessage: defaultMsg.invalidName})},
		surname: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidSurname', defaultMessage: defaultMsg.invalidSurname})},
		location: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidLocation', defaultMessage: defaultMsg.invalidLocation})},
		observation: {isInvalid: false, message: intl.formatMessage({id: 'message.invalidObservation', defaultMessage: defaultMsg.invalidObservation})}
	});

	const updateUser = (userInfo) => {
		setUser({
			...userInfo,
			userShipping: getUserShippingById(userInfo.userShipping),
			country: getLocationOption(userInfo.country.id, userInfo.country.name),
			state: getLocationOption(userInfo.state.id, userInfo.state.name),
			city: getLocationOption(userInfo.city.id, userInfo.city.name)
		});
	};

	const init = () => {
		const {username} = pathParams;
		setUsername(username);
		let mounted = true;
		const cancelSource = axios.CancelToken.source();
		getFullUserByUsername(username, cancelSource.token)
			.then(userInfo => {
				if(mounted) {
					updateUser(userInfo);
				}
			});

		return () => {
			mounted = false;
			cancelSource.cancel('Cancelling on unmount');
		};
	};

	useEffect(init, []);

	/* ---- CHANGE INPUTS ---- */

	const changeLocation = (option, type) => {
		if(type === 'country') {
			setUser({...user, country: option, state: null, city: null});
		}else if(type === 'state') {
			setUser({...user, state: option, city: null});
		}else {
			setUser({...user, city: option});
		}
	};

	const changeShipping = (value) => {
		const newUser = {...user};
		newUser.userShipping = value;
		if(value.id === NO_SHIPPING.id && user.userDelivery === NO_DELIVERY) {
			newUser.userDelivery = DELIVERY;
		}
		setUser(newUser);
	};

	const changeDelivery = (value) => {
		const newUser = {...user};
		newUser.userDelivery = value;
		if(value === NO_DELIVERY && user.userShipping.id === NO_SHIPPING.id) {
			newUser.userShipping = SHIPPING_LOCAL;
		}
		setUser(newUser);
	};

	const changeObservation = (value) => {
		setUser({...user, observation: value});
		setErrors({
			...errors,
			observation: {...errors.observation, isInvalid: value.length > 150}
		});
	};

	/* ---- VALIDATION ---- */

	const validateInput = (input, isValid, newErrors) => {
		newErrors[input].isInvalid = !isValid;
		return isValid;
	};

	const validateUser = () => {
		const newErrors = {...errors};
		let isValid = true;
		isValid = validateInput(
			'name',
			user.name.length >= 4
			&& user.name.length <= 32,
			newErrors) && isValid;
		isValid = validateInput(
			'surname',
			user.surname.length >= 4
			&& user.surname.length <= 32,
			newErrors) && isValid;
		isValid = validateInput(
			'location',
			user.country !== null
			&& user.state !== null
			&& user.city !== null,
			newErrors) && isValid;
		setErrors(newErrors);
		return isValid;
	};

	/* ---- ON ACTION ---- */

	const onEdit = () => {
		setShowActionError(false);
		if(validateUser()) {
			editUser({
				...user,
				userShipping: user.userShipping.id
			})
				.then(() => updateUrlwHistory(location, history, `/${getUrlGame(location)}/users/${username}`))
				.catch((error) => {
					setActionError(error);
					setShowActionError(true);
				});
		}else {
			setShowActionError(true);
		}
	};

	const onDelete = () => {
		clearUser();
		dispatch(logoutUser());
		history.replace('/');
	};

	const onSwitchStatus = () => {
		setIsSwitchStatusModalOpen(false);
		getFullUserByUsername(username)
			.then((userInfo) => {
				updateUser(userInfo);
				updateUrlwHistory(location, history, `/${getUrlGame(location)}/users/${username}`)
			});
	};

	const onPasswordSaved = () => {
		setIsChangePasswordModalOpen(false);
		updateUrlwHistory(location, history, `/${getUrlGame(location)}/users/${username}`)
	};

	if(username && (username !== getUsername())) {
		updateUrlwHistory(location, history, `/${getUrlGame()}/users/${username}`);
	}

	return (
		<div className={`${view}-userEdition-div`}>
			<h2 className={`${view}-userEdition-h2`}>{username}</h2>
			<div className={`${view}-userEdition-publicInfo-div`}>

				{errors.location.isInvalid && (
					<label className="userEdition-error-label">
						{intl.formatMessage({id: 'message.invalidLocation', defaultMessage: defaultMsg.invalidLocation})}
					</label>
				)}
				<CHSelect
					type="countries"
					className="userEdition-location"
					placeholder={intl.formatMessage({id: 'general.country', defaultMessage: 'Country'})}
					value={user.country}
					onChange={option => changeLocation(option, 'country')}
					id={"countries"}
				/>
				<CHSelect
					type="states"
					className="userEdition-location"
					placeholder={intl.formatMessage({id: 'general.state', defaultMessage: 'Province/State'})}
					countryId={user.country ? user.country.id : -1}
					value={user.state}
					onChange={option => changeLocation(option, 'state')}
					id={"states"}
				/>
				<CHSelect
					type="cities"
					className="userEdition-location"
					placeholder={intl.formatMessage({id: 'general.city', defaultMessage: 'City'})}
					stateId={user.state ? user.state.id : -1}
					value={user.city}
					onChange={option => changeLocation(option, 'city')}
					id={"cities"}
				/>

				<div className="userEdition-deliveryOptions-div">
					<h3 className={`${view}-userEdition-h4`}>
						{intl.formatMessage({id: 'general.shippingOptions', defaultMessage: 'Delivery options'})}
					</h3>
					{getUserShippingOptions().map(option => (
						<CHCheckbox
							className={`${view}-userEdition-deliveryOptions-checkbox`}
							id={option.id}
							CHStyle="box"
							label={intl.formatMessage({id: option.label, defaultMessage: option.label})}
							checked={user.userShipping.id === option.id || (user.userShipping.value > option.value && option.value !== 0)}
							onChange={() => changeShipping({id: option.id, value: option.value})}
						/>
					))}

					<h3 className={`${view}-userEdition-h4`}>
						{intl.formatMessage({id: 'general.personalDelivery', defaultMessage: 'Personal delivery'})}
					</h3>
					<CHCheckbox
						className={`${view}-userEdition-deliveryOptions-checkbox`}
						id={"personalNo"}
						CHStyle="box"
						label={intl.formatMessage({id: 'general.no', defaultMessage: 'NO'}).toUpperCase()}
						checked={user.userDelivery === NO_DELIVERY}
						onChange={() => changeDelivery(NO_DELIVERY)}
					/>
					<CHCheckbox
						className={`${view}-userEdition-deliveryOptions-checkbox`}
						id={"personalYes"}
						CHStyle="box"
						label={intl.formatMessage({id: 'general.yes', defaultMessage: 'YES'}).toUpperCase()}
						checked={user.userDelivery === DELIVERY}
						onChange={() => changeDelivery(DELIVERY)}
					/>
				</div>

				<CHInput
					className="userEdition-name"
					placeholder={intl.formatMessage({id: 'general.name', defaultMessage: 'Name'})}
					type="text"
					value={user.name}
					onChange={value => setUser({...user, name: value})}
					id={"userName"}
					error={errors.name}
				/>
				<CHInput
					className="userEdition-surname"
					placeholder={intl.formatMessage({id: 'general.surname', defaultMessage: 'Surname'})}
					type="text"
					value={user.surname}
					onChange={value => setUser({...user, surname: value})}
					id={"userSurname"}
					error={errors.surname}
				/>
				<CHInput
					className="userEdition-email"
					placeholder={intl.formatMessage({id: 'general.emailAddress', defaultMessage: 'Username'})}
					type="text"
					value={user.email}
					id={"userEmail"}
					disabled
				/>

				<CHTextarea
					className="userEdition-textarea"
					placeholder={intl.formatMessage({id: 'input.observation', defaultMessage: 'Observation...'})}
					value={user.observation}
					onChange={value => changeObservation(value)}
					error={errors.observation}
					id={"userObservations"}
				/>

				<CHButton
					className="userEdition-submitButton"
					label={intl.formatMessage({id: 'general.save', defaultMessage: 'Save'})}
					CHStyle="accept"
					onClick={onEdit}
					id={"userSubmit"}
					disabled={errors.observation.isInvalid}
				/>
			</div>

			<CHError
				id="userEdition-error"
				isShown={showActionError}
				error={actionError}
			/>
			<div className={`${view}-userEdition-buttonDiv`} >
				<CHButton
					className="userEdition-deleteAccountButton"
					label={intl.formatMessage({id: 'general.deleteAccount', defaultMessage: 'Delete account'})}
					CHStyle="warning"
					onClick={() => setIsDeleteAccountModalOpen(true)}
					id={"userDeleteAccount"}
				/>
				{user.userStatus === USERSTATUS_ACTIVE ?
					(
						<CHButton
							className="userEdition-switchStatusButton"
							label={intl.formatMessage({id: 'general.suspendAccount', defaultMessage: 'Suspend account'})}
							CHStyle="suspend"
							onClick={() => setIsSwitchStatusModalOpen(true)}
							id={"userSuspendButton"}
						/>
					) :
					(
						<CHButton
							className="userEdition-switchStatusButton"
							label={intl.formatMessage({id: 'general.activateAccount', defaultMessage: 'Activate account'})}
							CHStyle="suspend"
							onClick={() => setIsSwitchStatusModalOpen(true)}
							id={"userActivateButton"}
						/>
					)
				}

				<CHButton
					className="userEdition-editPassButton"
					label={intl.formatMessage({id: 'general.editPassword', defaultMessage: 'Edit Password'})}
					CHStyle="brand"
					onClick={() => setIsChangePasswordModalOpen(true)}
					id={"userEditPassButton"}
				/>
			</div>

			{/* ---- MODALS ---- */}

			<ChangePasswordModal
				isOpen={isChangePasswordModalOpen}
				userId={user.id}
				onAccept={onPasswordSaved}
				onClose={() => setIsChangePasswordModalOpen(false)}
			/>
			<DeleteAccountModal
				userId={user.id}
				isOpen={isDeleteAccountModalOpen}
				onClose={() => setIsDeleteAccountModalOpen(false)}
				onAccept={onDelete}
			/>
			<SwitchStatusModal
				isOpen={isSwitchStatusModalOpen}
				onClose={() => setIsSwitchStatusModalOpen(false)}
				onAccept={onSwitchStatus}
				user={user}
			/>
		</div>
	);
};

export default UserEdition;
