import React from 'react';
import { Route } from 'react-router-dom';
import Layout from './modules/layout/Layout';


const App = props => {
	
	return (
			<Route path='/' component={Layout} />
	);
};

export default App;