const compareByColumn = (row1, row2, rows, columnId, sortDirection, sortByContent = []) => {
	const row1Cells = row1.cells;
	const row2Cells = row2.cells;
	const field = sortByContent.includes(columnId) ? 'content' : 'value';
	if(sortDirection === 'asc') {
		if(row1Cells[columnId][field] > row2Cells[columnId][field]) {
			return 1;
		}else if(row1Cells[columnId][field] < row2Cells[columnId][field]) {
			return -1;
		}
	}else {
		if(row1Cells[columnId][field] > row2Cells[columnId][field]) {
			return -1;
		}else if(row1Cells[columnId][field] < row2Cells[columnId][field]) {
			return 1;
		}
	}
	const row1Index = rows.findIndex(r => r.id === row1.id);
	const row2Index = rows.findIndex(r => r.id === row2.id);
	return row1Index < row2Index ? -1 : 1;
};

// sortByContent: arrayOf columnId: number
const sortRows = (rows, sortByColumnId, sortDirection, sortByContent) => {
	return rows.sort((row1, row2) => compareByColumn(row1, row2, rows, sortByColumnId, sortDirection, sortByContent));
};

// sorting: {by: columnId, asc: boolean}
const changeSorting = (sorting, columnId) => {
	if(sorting.by === columnId) {
		return {by: columnId, asc: !sorting.asc};
	}else {
		return {by: columnId, asc: true};
	}
};

export {
	sortRows,
	changeSorting
};
